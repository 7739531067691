import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import { Button, DropDown, CustomRadio, Input } from '../../Components/HtmlElements';
import CustomCard from '../../Components/Layout/CustomCard';
import { basicInfoSchema } from '../../FormikSchema/IncomeInfo';
import {
	getSourceOfIncomeList,
	getProfessionList,
	GetPrefillValues,
	storeIncomeInfo,
} from './IncomeInfoSlice';
import CustomLoader from '../../Components/Loader/CustomLoader';
// Styling:
import Styles from './IncomeInfo.module.scss';
// import Styles from '../BasicInfo/BasicInfo.module.scss';
import getRoute from '../../Utils/GetRoute';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

// RTK query
import { useUpdateDemoUserStatusMutation } from '../../Services/apiSlice';

import callRefreshToken from '../../Utils/RefreshToken';
import { validateUserInput } from '../../Utils/utilities';

// Enum
import { ROUTE, USER_STATUS } from '../../Utils/Enum';

const cardStyle = { textAlign: 'left' };

function IncomeInfo(props) {
	const { theme, navigate, check_internet_connection, handle_android_back_button, show_toast } = props;
	const dispatch = useDispatch();

	const { white, greyShade2, darkBlack } = theme.palette.textColor;
	const { secondary } = theme.palette.background;

	const isDemoEnabled = GetSavedDemoStatus();

	const isLoading = useSelector(state => state.incomeInfoDetails.isLoading);
	const SourceOfIncome = useSelector(state => state.incomeInfoDetails.dropDownData.incomeList);
	const Profession = useSelector(state => state.incomeInfoDetails.dropDownData.professionList);
	const prefillData = useSelector(state => state.incomeInfoDetails.prefillData);
	const storeIncomeInfoData = useSelector(state => state.incomeInfoDetails.incomeInfoData);

	const storeIncomeInfoApiStatus = useSelector(state => state?.incomeInfoDetails?.incomeInfoData?.error);
	const prefillDataApiStatus = useSelector(state => state.incomeInfoDetails.prefillData.error);

	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();

	const selectedSrcOfIncomeFromBasicInfo = +prefillData?.sourceOfIncome;

	const [incomeList, setIncomeList] = useState([]);
	const [professionList, setprofessionList] = useState([]);

	const formik = useFormik({
		initialValues: {
			sourceOfIncome: '',
			profession: '',
			annualIncome: '',
			companyName: '',
			uanNumber: '',
			salarySource: '',
			bankAccountNumber: '',
			ifscCode: '',
			upiId: '',
		},
		validateOnChange: true,
		validateOnBlur: false,
		validationSchema: isDemoEnabled === 'true' ? null : basicInfoSchema,
		onSubmit: values => {
			if (check_internet_connection()) {
				dispatch(
					storeIncomeInfo({
						source_of_income_code: values.sourceOfIncome,
						profession_code: values.profession,
						annual_income: values.annualIncome ? values.annualIncome : '0',
						company_name: values.companyName,
						uan_number: values.uanNumber,
						salary_mode: values.salarySource,
						account_number: values.bankAccountNumber,
						ifsc_code: values.ifscCode,
						upi_id: values.upiId,
					}),
				);
			}
		},
	});

	if (formik.values.salarySource !== 'Bank Transfer') {
		if (
			formik.values.bankAccountNumber ||
			formik.values.ifscCode ||
			formik.values.upiId ||
			formik.errors.bankAccountNumber ||
			formik.errors.ifscCode ||
			formik.errors.upiId
		) {
			formik.setFieldValue('bankAccountNumber', '');
			formik.setFieldValue('ifscCode', '');
			formik.setFieldValue('upiId', '');
			formik.errors.bankAccountNumber && delete formik.errors.bankAccountNumber;
			formik.errors.ifscCode && delete formik.errors.ifscCode;
			formik.errors.upiId && delete formik.errors.upiId;
		}
	}

	const { annualIncome, companyName, uanNumber, bankAccountNumber, ifscCode, upiId } = formik.errors;
	let didUserProvidedAllInputs;
	if (formik.values) {
		let val;

		// cloning original formik values object
		let formValues = { ...formik.values };

		// deleting uanNumber since it is an optional input
		delete formValues.uanNumber;

		let { sourceOfIncome, profession, salarySource, annualIncome, companyName } = formValues;

		if (sourceOfIncome === 10) {
			val =
				(sourceOfIncome && profession && salarySource && annualIncome) || (profession && +profession === 37);
		} else {
			val =
				(sourceOfIncome && profession && salarySource && annualIncome && companyName) ||
				(profession && +profession === 37);
		}
		didUserProvidedAllInputs = val && Object.keys(formik.errors).length === 0;
	}
	const validatePrefillData = () => {
		// Store the data;
		formik.setFieldValue('sourceOfIncome', prefillData.sourceOfIncome);
		formik.setFieldValue('profession', prefillData.profession);
	};

	const handleAndroidBackButton = () => {
		if (isDemoEnabled === 'true') {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.BASIC_INFO_PENDING,
						}).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('closeApp');
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			navigate('/' + ROUTE.HOME, true);
		}
	};

	const validateIncomeInfoApiData = () => {
		if (storeIncomeInfoData?.statusCode === 401) {
			show_toast('Something went wrong. Please try again.', 'error');
			(async function refreshToken() {
				try {
					await callRefreshToken();
				} catch {
					show_toast('Something went wrong. Please try again.', 'error');
				}
			})();
		} else {
			if (storeIncomeInfoData.statusCode === 200) {
				if (storeIncomeInfoData.userStatus === USER_STATUS.ADDRESS_INFO_PENDING) {
					navigate('/' + ROUTE.CONGRATULATIONS, true);
				} else {
					navigate(getRoute(storeIncomeInfoData.userStatus), false);
				}
			}
		}
	};

	useEffect(() => {
		if (prefillDataApiStatus === 401) {
			(async function refreshToken() {
				try {
					const response = await callRefreshToken();

					if (response) {
						if (check_internet_connection()) {
							dispatch(getSourceOfIncomeList());
							dispatch(getProfessionList());
							dispatch(GetPrefillValues());
						}
					}
				} catch {}
			})();
		}
	}, [prefillDataApiStatus]);

	useEffect(() => {
		if (storeIncomeInfoApiStatus === 401) {
			show_toast('Something went wrong. Please try again.', 'error');
			(async function refreshToken() {
				try {
					await callRefreshToken();
				} catch {}
			})();
		}
	}, [storeIncomeInfoApiStatus]);

	useEffect(() => {
		if (prefillData.statusCode) {
			validatePrefillData();
		}
	}, [prefillData]);

	useEffect(() => {
		if (storeIncomeInfoData.statusCode) {
			validateIncomeInfoApiData();
		}
	}, [storeIncomeInfoData]);

	useEffect(() => {
		if (check_internet_connection()) {
			dispatch(getSourceOfIncomeList());
			dispatch(getProfessionList());
			dispatch(GetPrefillValues());
		}
	}, []);

	useEffect(() => {
		if (SourceOfIncome && SourceOfIncome.length > 0) {
			setIncomeList(SourceOfIncome);
		}
	}, [SourceOfIncome]);

	useEffect(() => {
		if (Profession && Profession.length > 0) {
			setprofessionList(Profession);
		}
	}, [Profession]);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	// prefill data for demoApp
	useEffect(() => {
		if (isDemoEnabled === 'true') {
			formik.setFieldValue('annualIncome', 600000);
			formik.setFieldValue('companyName', 'LF2PEER Financial Private Limited');
			formik.setFieldValue('Profession', 27);
			formik.setFieldValue('Education', 7);
			formik.setFieldValue('salarySource', 'Bank Transfer');
		}
	}, []);

	return (
		<>
			{isLoading || updatingUsrStatus ? <CustomLoader /> : ''}
			<Grid height='100%' overflow='hidden' backgroundColor={secondary}>
				{/* Header */}
				<Grid className={Styles.heading} backgroundColor={props.theme.palette.primary.main}>
					<Grid marginLeft='15px'>
						<Typography fontSize='22px' color={white}>
							Income Information
						</Typography>
					</Grid>
				</Grid>

				<React.Fragment>
					{/* Body */}
					<FormControl component='form' className={Styles.formControl} onSubmit={formik.handleSubmit}>
						<Grid className={Styles.body} backgroundColor={secondary}>
							<Grid marginTop='20px'>
								<CustomCard style={cardStyle}>
									<Grid className={Styles.inputStyle} marginTop='22px'>
										<DropDown label='Select Source of Income' {...formik.getFieldProps('sourceOfIncome')}>
											{incomeList.map((item, idx) => {
												return (
													<MenuItem key={idx} value={item.value} disabled={!item.value}>
														{item.name}
													</MenuItem>
												);
											})}
										</DropDown>
									</Grid>
									<Grid className={Styles.inputStyle}>
										<DropDown label='Select Profession' {...formik.getFieldProps('profession')}>
											{professionList.map((item, idx) => {
												return (
													<MenuItem key={idx} value={item.value} disabled={!item.value}>
														{item.name}
													</MenuItem>
												);
											})}
										</DropDown>
									</Grid>
									{formik.values.profession && +formik.values.profession !== 37 && (
										<>
											<Grid className={Styles.inputStyle}>
												<Input
													maxLength={8}
													mode='numeric'
													label='Annual Income'
													value={formik.values.annualIncome}
													onChangeHandler={e =>
														validateUserInput(e.target.value, {
															inputName: 'annualIncome',
															setterFun: formik.setFieldValue,
															validate: 'only-numeric',
														})
													}
													error={annualIncome ? true : false}
													helperText={annualIncome}
												/>
												<Typography fontSize='smaller' marginTop='10px' color={darkBlack}>
													<b>Note:</b> Annual income includes (salary, bonus, rental income, etc...)
												</Typography>
											</Grid>
											{formik.values.sourceOfIncome !== 10 && (
												<React.Fragment>
													<Grid className={Styles.inputStyle}>
														<Input
															label='Company Name'
															value={formik.values.companyName}
															error={companyName ? true : false}
															helperText={companyName}
															onChangeHandler={e =>
																validateUserInput(e.target.value, {
																	inputName: 'companyName',
																	setterFun: formik.setFieldValue,
																	validate: 'company-name',
																})
															}
														/>
													</Grid>

													{+formik.values.sourceOfIncome === 194 ||
													(selectedSrcOfIncomeFromBasicInfo === 194 &&
														+formik.values.sourceOfIncome === 194) ? (
														<Grid className={Styles.inputStyle}>
															<Input
																mode='numeric'
																value={formik.values.uanNumber}
																onChangeHandler={e =>
																	validateUserInput(e.target.value, {
																		inputName: 'uanNumber',
																		setterFun: formik.setFieldValue,
																		validate: 'only-numeric',
																	})
																}
																label='UAN / PF Number (Optional)'
																error={uanNumber ? true : false}
																helperText={uanNumber}
															/>
														</Grid>
													) : null}
												</React.Fragment>
											)}
											<Grid className={Styles.inputStyle}>
												<Typography variant='body1' color={greyShade2} className={Styles.genderText}>
													How do you get your salary?
												</Typography>
												<CustomRadio
													{...formik.getFieldProps('salarySource')}
													data={[
														{ label: 'Bank Transfer', value: 'Bank Transfer' },
														{ label: 'Cash', value: 'cash' },
														{ label: 'Cheque', value: 'cheque' },
													]}
												/>
											</Grid>
										</>
									)}
								</CustomCard>
							</Grid>
							{formik.values.salarySource === 'Bank Transfer' && (
								// this section is currently disabled.
								<CustomCard style={{ marginTop: '22px', display: 'none' }}>
									<Grid className={Styles.inputStyle} marginTop='22px'>
										<Input
											label='Bank Account Number'
											{...formik.getFieldProps('bankAccountNumber')}
											error={bankAccountNumber ? true : false}
											helperText={bankAccountNumber}
										/>
									</Grid>
									<Grid className={Styles.inputStyle}>
										<Input
											label='IFSC Code'
											{...formik.getFieldProps('ifscCode')}
											error={ifscCode ? true : false}
											helperText={ifscCode}
										/>
									</Grid>
									<Grid className={Styles.inputStyle}>
										<Input
											label='Enter your UPI ID'
											{...formik.getFieldProps('upiId')}
											error={upiId ? true : false}
											helperText={upiId}
										/>
									</Grid>
								</CustomCard>
							)}
						</Grid>
						{/* Footer */}
						<Grid className={Styles.footer} backgroundColor={secondary}>
							<Button
								type='submit'
								buttonText='Save and Continue'
								disabled={isDemoEnabled === 'true' ? false : !didUserProvidedAllInputs}
							/>
						</Grid>
					</FormControl>
				</React.Fragment>
			</Grid>
		</>
	);
}

export default HocWrapper(IncomeInfo);
