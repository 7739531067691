export const ROUTE = Object.freeze({
	GET_STARTED: 'get_started',
	PERMISSIONS: 'permissions',
	PERMISSION_FAIL: 'permission_fail',
	LOGIN: 'login',
	LOGIN_OTP: 'login_otp',
	SIGNUP_OTP: 'signup_otp',
	CREATE_PASSWORD: 'create_password',
	CREATE_PASSWORD_SUCCESS: 'create_password_success',
	MOBILE_VERIFICATION: 'mobile_verification',
	FORGOT_PASSWORD: 'forgot_password',
	FORGOT_PASSWORD_OTP: 'forgot_password_otp',
	RESET_PASSWORD: 'reset_password',
	RESET_SUCCESS: 'reset_success',
	BASIC_INFO: 'basic_info',
	REFERENCE_CONTACT_FAMILY: 'reference_contact_family',
	REFERENCE_FAMILY_OTP: 'reference_otp_family',
	REFERENCE_CONTACT_FRIEND: 'reference_contact_friends',
	REFERENCE_FRIEND_OTP: 'reference_otp_friend',
	HOME: 'home',
	INCOME_INFO: 'income_info',
	PAN_CHECK_FAIL: 'pan_check_fail',
	ON_HOLD: 'onhold',
	ELIGIBILITY_CHECK_INPROGRESS: 'eligibility_check_inprogress',
	MANUAL_VERIFICATION_INPROGRESS: 'manual_verification_inprogress',
	CONGRATULATIONS: 'congratulations',
	KYC_CHECK_INPROGRESS: 'kyc_check_inprogress',
	KYC_MANUAL_VERIFICATION: 'kyc_manual_verification',
	KYC_CHECK_FAIL: 'kyc_check_fail',
	SELFIE_CHECK_INPROGRESS: 'selfie_check_inprogress',
	SELFIE_MANUAL_VERIFICATION: 'selfie_manual_verification',
	SELECT_BANK: 'select_bank',
	PRODUCT: 'product',
	PURPOSE_OF_LOAN: 'purpose_of_loan',
	ADD_BANK: 'add_bank',
	BANK_VERIFICATION_INPROGRESS: 'bank_verification_inprogress',
	BANK_MANUAL_VERIFICATION: 'bank_manual_verification',
	ESIGN_AGREEMENT: 'esign_agreement',
	ESIGN_OTP: 'esign_otp',
	SANCTION_LETTER: 'sanction_letter',
	FCM: 'handle_fcm',
	LOAN_DISBURSAL_INPROGRESS: 'loan_disbursal_inprogress',
	LOAN_DISBURSAL_SUCCESS: 'loan_disbursal_success',
	LOAN_ELIGIBILITY_INPROGRESS: 'loan_eligibility_inprogress',
	RE_EVALUATION: 're-evaluation',
	NO_SERVICE: 'no_service',
	REPAYMENT_HOME: 'repayment_home',
	LOAN_REPAYMENT_DETAILS: 'loan_repayment_details',
	LOAN_REPAYMENT_SUCCESS: 'loan_repayment_success',
	LOAN_REPAYMENT_FAIL: 'loan_repayment_fail',
	LOAN_CLOSED: 'loan_closed',
	UPGRADE_AMOUNT: 'upgrade_amount',
	VKYC_INPROGRESS: 'vkyc_inprogress',
});
