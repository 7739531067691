import React from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// svg
import eligibilityTimer from '../../Assets/Icons/EligibilityTimerIcon.svg';

// Stylesheet
import Styles from './BankVerification.module.scss';

function BankVerificationInprogress(props) {
	const { theme, navigate } = props;
	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={eligibilityTimer} alt='eligibility-timer' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>Bank Account Verification In Progress…</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography className={Styles.CardBodyText} color={theme.palette.textColor.grey}>
							We are waiting for a confirmation on the transaction of ₹1 to your account
							<Typography paddingTop='20px' className={Styles.CardBodyText}>
								It will not take more than a minute.
							</Typography>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default HocWrapper(BankVerificationInprogress);
