import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ENDPOINTS } from './endpoints';

import callRefreshToken from '../Utils/RefreshToken';

const {
	basicInfoDropDown,
	saveBasicInfo,
	createPassword,
	sendOTP,
	refContactStatus,
	refContactRadioButtons,
	saveRefContact,
	sendRefContactOTP,
	validateRefContactOTP,
	resendRefContactOTP,
	skipRefContact,
	editRefContact,
	products,
	purposeOfLoanDropdown,
	submitProduct,
	submitPurposeOfLoan,
	getAllBanks,
	setBankAsPrimary,
	getBanksByIfscOrBranch,
	addBank,
	getPartialLoandetails,
	getSanctionLetter,
	createLoan,
	eSign,
	esignOTP,
	esignCancel,
	updateDemoUserStatus,
	productNavigation,
	submitUpdatedProduct,
} = ENDPOINTS;

const mutationQueryCreator = (url, body) => ({ url, body, method: 'POST' });

const BORROWER_MANAGEMENT_API = process.env.REACT_APP_BORROWER_MANAGEMENT_API;
const THIRD_PARTY_SERVICE_API = process.env.REACT_APP_THIRD_PARTY_SERVICE_API;

const baseQuery = fetchBaseQuery({
	baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
	prepareHeaders: headers => {
		let token = localStorage.getItem('_token');

		headers.set('token', token);
		headers.set('Content-Type', 'application/json');
	},
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);

	if (result?.error?.status === 401) {
		const newToken = await callRefreshToken();
		if (newToken) {
			result = await baseQuery(args, api, extraOptions);
		}
	}

	return result;
};

export const apiSlice = createApi({
	baseQuery: baseQueryWithReAuth,
	tagTypes: ['refStatus', 'bankLists'],
	endpoints: builder => ({
		getBasicInfoDropDownData: builder.query({
			query: dataType => `${basicInfoDropDown}?type=${dataType}`,
		}),
		saveBasicInfo: builder.mutation({
			query: reqBody => mutationQueryCreator(saveBasicInfo, reqBody),
		}),
		createPassword: builder.mutation({
			query: reqBody => mutationQueryCreator(createPassword, reqBody),
		}),
		sendOtp: builder.mutation({
			query: reqBody => mutationQueryCreator(sendOTP, reqBody),
		}),
		getRefContactStatus: builder.query({
			query: () => refContactStatus,
			keepUnusedDataFor: 1,
		}),
		getRefContactRadioButtons: builder.query({
			query: () => refContactRadioButtons,
		}),
		saveRefContact: builder.mutation({
			query: reqBody => mutationQueryCreator(saveRefContact, reqBody),
		}),
		sendRefContactOTP: builder.mutation({
			query: reqBody => mutationQueryCreator(sendRefContactOTP, reqBody),
		}),
		validateRefContactOTP: builder.mutation({
			query: reqBody => mutationQueryCreator(validateRefContactOTP, reqBody),
			providesTags: ['refStatus'],
		}),
		resendRefContactOTP: builder.mutation({
			query: reqBody => mutationQueryCreator(resendRefContactOTP, reqBody),
		}),
		skipVerification: builder.mutation({
			query: reqBody => mutationQueryCreator(skipRefContact, reqBody),
		}),
		editReference: builder.mutation({
			query: reqBody => mutationQueryCreator(editRefContact, reqBody),
		}),
		getProducts: builder.query({
			query: () => ({
				url: `${BORROWER_MANAGEMENT_API}/${products}`,
			}),
			keepUnusedDataFor: 1,
		}),
		getPurposeOfLoanDropdowndata: builder.query({
			query: () => `${BORROWER_MANAGEMENT_API}/${purposeOfLoanDropdown}`,
		}),
		submitProduct: builder.mutation({
			query: reqBody => mutationQueryCreator(`${BORROWER_MANAGEMENT_API}/${submitProduct}`, reqBody),
		}),
		submitPurposeOfLoan: builder.mutation({
			query: reqBody => mutationQueryCreator(`${BORROWER_MANAGEMENT_API}/${submitPurposeOfLoan}`, reqBody),
			invalidatesTags: ['bankLists'],
		}),
		getAllBanks: builder.query({
			query: orderBy => `${THIRD_PARTY_SERVICE_API}/${getAllBanks}?order_by=${orderBy}`,
			providesTags: ['bankLists'],
		}),
		setBankPrimary: builder.mutation({
			query: reqBody => mutationQueryCreator(`${THIRD_PARTY_SERVICE_API}/${setBankAsPrimary}`, reqBody),
			invalidatesTags: ['bankLists'],
		}),
		getBanksByIfscOrBranch: builder.query({
			query: ({ userEnteredIfsc, branchName }) =>
				`${THIRD_PARTY_SERVICE_API}/${getBanksByIfscOrBranch}?bank_search_code=${userEnteredIfsc}&bank_search_type=${branchName}`,
		}),
		addBank: builder.mutation({
			query: reqBody => mutationQueryCreator(`${THIRD_PARTY_SERVICE_API}/${addBank}`, reqBody),
			invalidatesTags: ['bankLists'],
		}),
		getPartialLoandetails: builder.query({
			query: productId => `${BORROWER_MANAGEMENT_API}/${getPartialLoandetails}?product_type_id=${productId}`,
		}),
		getSanctionLetter: builder.query({
			query: selectedBankId =>
				`${BORROWER_MANAGEMENT_API}/${getSanctionLetter}&bank_acc_id=${selectedBankId}`,
		}),
		createLoan: builder.mutation({
			query: reqBody => mutationQueryCreator(`${BORROWER_MANAGEMENT_API}/${createLoan}`, reqBody),
		}),
		getEsignAgreementData: builder.mutation({
			query: reqBody => mutationQueryCreator(`${BORROWER_MANAGEMENT_API}/${eSign}`, reqBody),
		}),
		esignOTP: builder.mutation({
			query: ({ reqBody, endpoint }) =>
				mutationQueryCreator(`${BORROWER_MANAGEMENT_API}/${esignOTP}/${endpoint}`, reqBody),
		}),
		cancelEsign: builder.mutation({
			query: reqBody => mutationQueryCreator(`${BORROWER_MANAGEMENT_API}/${esignCancel}`, reqBody),
		}),
		updateDemoUserStatus: builder.mutation({
			query: reqBody => mutationQueryCreator(updateDemoUserStatus, reqBody),
		}),
		getUpdatedProduct: builder.query({
			query: ({ productTypeId, tenure }) =>
				`${BORROWER_MANAGEMENT_API}/${productNavigation}?product_type_id=${productTypeId}&tenure=${tenure}`,
		}),
		updateNewProduct: builder.mutation({
			query: reqBody => mutationQueryCreator(`${BORROWER_MANAGEMENT_API}/${submitUpdatedProduct}`, reqBody),
		}),
	}),
});

export const {
	useGetBasicInfoDropDownDataQuery,
	useSaveBasicInfoMutation,
	useCreatePasswordMutation,
	useSendOtpMutation,
	useGetRefContactStatusQuery,
	useGetRefContactRadioButtonsQuery,
	useSaveRefContactMutation,
	useSendRefContactOTPMutation,
	useValidateRefContactOTPMutation,
	useResendRefContactOTPMutation,
	useSkipVerificationMutation,
	useEditReferenceMutation,
	useGetProductsQuery,
	useGetPurposeOfLoanDropdowndataQuery,
	useSubmitProductMutation,
	useSubmitPurposeOfLoanMutation,
	useGetAllBanksQuery,
	useSetBankPrimaryMutation,
	useGetBanksByIfscOrBranchQuery,
	useAddBankMutation,
	useGetPartialLoandetailsQuery,
	useLazyGetSanctionLetterQuery,
	useCreateLoanMutation,
	useGetEsignAgreementDataMutation,
	useEsignOTPMutation,
	useUpdateDemoUserStatusMutation,
	useCancelEsignMutation,
	usePaymentDetailsQuery,
	useLazyGetUpdatedProductQuery,
	useUpdateNewProductMutation,
} = apiSlice;
