import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// RTK Query
import { useGetPartialLoandetailsQuery } from '../../Services/apiSlice';

// localStorage Values
import { GetSavedProductId } from '../../Utils/LocalStorageValues';

import { localeCurrencyFormat } from '../../Utils/utilities';

import Styles from './ProductHeading.module.scss';

const ProductHeading = () => {
	const savedProdId = GetSavedProductId();

	const { data: partialProductData } = useGetPartialLoandetailsQuery(savedProdId && savedProdId);

	const amt = partialProductData?.result?.productDetails?.principal_amt;
	const tenureDays = partialProductData?.result?.productDetails?.tenure_days;

	return (
		<Grid container className={Styles.firstSubBody}>
			<Grid className={Styles.firstContent} item xs={9}>
				<Typography color='#27A35B' fontSize='26px' fontWeight='600'>
					{amt !== null &&
						localeCurrencyFormat(amt, 'en-US', {
							style: 'currency',
							currency: 'INR',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						})}
				</Typography>
			</Grid>
			<Grid className={Styles.daysText} item xs={3} backgroundColor='#3A2960'>
				<Grid>
					<Typography color='#fff' fontSize='30px' fontWeight='600'>
						{tenureDays}
					</Typography>
				</Grid>
				<Grid>
					<Typography color='#fff'>
						<i>Days</i>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ProductHeading;
