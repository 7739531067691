import React from 'react';

// MUI related imports
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

// theme
import ThemeProvider from '@mui/system/ThemeProvider';
import { CustomTheme } from '../../Components/Loader/ComponentStyles';

// Stylesheet
import Styles from './PDFRenderer.module.scss';

const PDFSkeleton = () => {
	return (
		<Grid container direction='column' rowGap='20px'>
			<Grid className={Styles.pdfSkeleton}>
				<Skeleton animation='wave' variant='rectangular' height='100%' />
			</Grid>
		</Grid>
	);
};

const PDFDocLoadingSkeleton = () => {
	return (
		<Grid width='100%' height='60vh' display='flex' alignItems='center' justifyContent='center'>
			<ThemeProvider theme={CustomTheme}>
				<CircularProgress id='pdfProgress' />
			</ThemeProvider>
		</Grid>
	);
};

export { PDFSkeleton, PDFDocLoadingSkeleton };
