import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTheme } from './ComponentStyles';
import CustomCheckbox from '../../Components/HtmlElements/CustomCheckbox';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import { permissionsData } from './PermissionsData';
import { Link } from 'react-router-dom';
// Styling Files
import Styles from './Permissions.module.scss';
// Images and Icons
import InstalledApps from '../../Assets/Icons/InstalledAppsIcon.svg';
import LocationIcon from '../../Assets/Icons/LocationIcon.svg';
import UserInfo from '../../Assets/Icons/UserInfoIcon.svg';
import CameraIcon from '../../Assets/Icons/CameraIcon.svg';
import MicrophoneIcon from '../../Assets/Icons/MicrophoneIcon.svg';

function Permissions(props) {
	const { theme, check_internet_connection, handle_android_back_button } = props;
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [showConsentError, setShowConsentError] = useState(false);

	const termsAndConditionsHandler = event => {
		setAcceptTerms(event.target.checked);
		if (showConsentError) {
			setShowConsentError(false);
		}
	};

	const onContinue = () => {
		if (acceptTerms) {
			if (check_internet_connection()) {
				if (window.Android) {
					window.Android.enablePermissions();
				}
			}
		} else {
			setShowConsentError(true);
		}
	};

	const openURL = url => {
		if (window.Android) {
			window.Android.loadWebViewURL(url);
		}
	};

	const handleAndroidBackButton = () => {
		handle_android_back_button('goBack');
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<Grid container direction='row' className={Styles.mainContainer}>
			<Grid backgroundColor={theme.palette.primary.main} className={Styles.permissionGrid}>
				<Typography variant='body1' textAlign='center' fontSize='18px' color={theme.palette.textColor.white}>
					Permission
				</Typography>
			</Grid>
			<Grid className={Styles.permissionSecondHeading} backgroundColor={theme.palette.background.secondary}>
				<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.grey}>
					{permissionsData.mainHeading}
				</Typography>
			</Grid>

			<Grid className={Styles.bodyMain} container direction='column'>
				<Grid maxHeight='560px'>
					<Grid className={Styles.list} container direction='column'>
						<Grid container direction='row' columnGap='12px'>
							<img className={Styles.permissionIcon} src={InstalledApps} alt='' />
							<Typography fontSize={14} fontWeight={600} color={theme.palette.textColor.darkBlack}>
								INSTALLED APPLICATION
							</Typography>
						</Grid>
						<Grid>
							<Typography
								className={Styles.permissionText}
								variant='body1'
								color={theme.palette.textColor.grey}>
								{permissionsData.installedApplications}
							</Typography>
						</Grid>
					</Grid>

					<Grid className={Styles.list} container direction='column'>
						<Grid container direction='row' columnGap='12px'>
							<img className={Styles.permissionIcon} src={LocationIcon} alt='' />
							<Typography fontSize={14} fontWeight={600} color={theme.palette.textColor.darkBlack}>
								LOCATION
							</Typography>
						</Grid>
						<Grid>
							<Typography
								className={Styles.permissionText}
								variant='body1'
								color={theme.palette.textColor.grey}>
								{permissionsData.location}
							</Typography>
						</Grid>
					</Grid>

					<Grid className={Styles.list} container direction='column'>
						<Grid container direction='row' columnGap='12px'>
							<img className={Styles.permissionIcon} src={UserInfo} alt='' />

							<Typography fontSize={14} fontWeight={600} color={theme.palette.textColor.darkBlack}>
								USER PERSONAL INFORMATION
							</Typography>
						</Grid>
						<Grid>
							<Typography
								className={Styles.permissionText}
								variant='body1'
								color={theme.palette.textColor.grey}>
								{permissionsData.personalInfo}
							</Typography>
						</Grid>
					</Grid>

					<Grid className={Styles.list} container direction='column'>
						<Grid container direction='row' columnGap='12px'>
							<img className={Styles.permissionIcon} src={CameraIcon} alt='' />

							<Typography fontSize={14} fontWeight={600} color={theme.palette.textColor.darkBlack}>
								CAMERA
							</Typography>
						</Grid>
						<Grid>
							<Typography
								className={Styles.permissionText}
								variant='body1'
								color={theme.palette.textColor.grey}>
								{permissionsData.camera}
							</Typography>
						</Grid>
					</Grid>

					<Grid className={Styles.list} container direction='column'>
						<Grid container direction='row' columnGap='12px'>
							<img className={Styles.permissionIcon} src={MicrophoneIcon} alt='' />

							<Typography fontSize={14} fontWeight={600} color={theme.palette.textColor.darkBlack}>
								MICROPHONE
							</Typography>
						</Grid>
						<Grid>
							<Typography
								className={Styles.permissionText}
								variant='body1'
								color={theme.palette.textColor.grey}>
								{permissionsData.microPhone}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				container
				direction='column'
				className={Styles.footer}
				backgroundColor={theme.palette.background.secondary}>
				<Grid container direction='row' className={Styles.permissionsConsent}>
					<ThemeProvider theme={CustomTheme}>
						<CustomCheckbox
							id='permissionsConsent'
							className={Styles.CustomCheckbox}
							checked={acceptTerms}
							onChangeHanlder={termsAndConditionsHandler}
						/>
					</ThemeProvider>

					<Typography
						variant='body1'
						fontSize={10}
						color={theme.palette.textColor.darkBlack}
						textAlign='justify'>
						By continuing, you agree to PeopleFront{' '}
						<Link onClick={() => openURL('https://www.peoplefront.in/StaticPages/termsAndCondition')}>
							Terms and Conditions{' '}
						</Link>
						and{' '}
						<Link onClick={() => openURL('https://www.peoplefront.in/StaticPages/Privacypolicy')}>
							Privacy Policies
						</Link>
						{' '} and receive communication from PeopleFront via SMS, E-Mail and WhatsApp
					</Typography>
				</Grid>
				{showConsentError && (
					<Typography className={Styles.errortext} color={theme.palette.error.main}>
						<em>*Please read and accept Terms & Conditions</em>
					</Typography>
				)}

				<Grid>
					<CustomButton buttonText='I Agree' clickHandler={onContinue} />
				</Grid>
			</Grid>
		</Grid>
	);
}

export default HocWrapper(Permissions);
