import CircularProgress from '@mui/material/CircularProgress';
import ThemeProvider from '@mui/system/ThemeProvider';
import Box from '@mui/system/Box';
import React from 'react';
import { CustomTheme } from './ComponentStyles';
import Styles from './CustomLoader.module.scss';

function CustomLoader(props) {
	return (
		<Box className={Styles.ParentMainBox}>
			<ThemeProvider theme={CustomTheme}>
				<CircularProgress id='homeScreenProgress' size={50} {...props} />
			</ThemeProvider>
		</Box>
	);
}

export default CustomLoader;
