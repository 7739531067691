export const SaveToken = token => {
	return localStorage.setItem('_token', token);
};

export const GetSavedToken = () => {
	const token = localStorage.getItem('_token');
	return token ? token : null;
};

export const SaveRefreshToken = token => {
	return localStorage.setItem('_refreshToken', token);
};

export const GetRefreshToken = () => {
	const token = localStorage.getItem('_refreshToken');
	return token ? token : null;
};

export const GetSavedUserName = () => {
	let usrName = localStorage.getItem('_usrname');
	return usrName ? usrName : null;
};

export const SaveProductId = prodId => localStorage.setItem('_productId', prodId);
export const GetSavedProductId = () => {
	let prodId = localStorage.getItem('_productId');
	return prodId ? prodId : null;
};

export const SaveApplicationId = applicationId => localStorage.setItem('_application_id', applicationId);
export const GetSavedApplicationId = () => {
	let appId = localStorage.getItem('_application_id');
	return appId ? appId : null;
};

export const SaveLoanId = loanId => localStorage.setItem('_loan_id', loanId);
export const GetSavedLoanId = () => {
	let loanId = localStorage.getItem('_loan_id');
	return loanId ? loanId : null;
};

export const SaveDemoStatus = bool => localStorage.setItem('_isDemo', bool);
export const GetSavedDemoStatus = () => {
	let demoStatus = localStorage.getItem('_isDemo');
	return demoStatus !== null ? demoStatus : null;
};

export const GetSavedProfiePic = () => {
	let profilePic = localStorage.getItem('profilePicUrl');
	return profilePic !== null ? profilePic : null;
};

export const GetDeviceDetails = () => {
	let deviceDetails = localStorage.getItem('_deviceDetails');

	if (deviceDetails !== null) {
		return JSON.parse(JSON.parse(JSON.stringify(deviceDetails)));
	} else {
		return null;
	}
};

export const SaveTenureDays = tenureDays => localStorage.setItem('_tenureDays', tenureDays);
export const GetSavedTenureDays = () => {
	let tenureDays = localStorage.getItem('_tenureDays');
	return tenureDays ? tenureDays : null;
};


export const SaveReEvaluationStatus = status => localStorage.setItem('_reEvaluationStatus', status);
export const GetSavedReEvaluationStatus = () => {
	let reEvalStatus = localStorage.getItem('_reEvaluationStatus');
	return reEvalStatus ? reEvalStatus : null;
};
