import React, { useEffect } from 'react';
import HocWrapper from '../Utils/Hoc/HocWrapper';

import { useDispatch, useSelector } from 'react-redux';
import { getUserStatus } from '../Containers/HomeScreen/HomeScreenSlice';
import getRoute from '../Utils/GetRoute';

import CustomLoader from '../Components/Loader/CustomLoader';
import ReEvaluation from '../Containers/ReEvaluation/ReEvaluation';

// RTK
import { useGetAllBanksQuery, useLazyGetUpdatedProductQuery } from '../Services/apiSlice';

// localStorageValues
import {
	GetSavedProductId,
	GetSavedTenureDays,
	GetSavedReEvaluationStatus,
} from '../Utils/LocalStorageValues';

// Enum
import { ROUTE, USER_STATUS, BANK_STATUS } from '../Utils/Enum';

const HandleFCM = props => {
	const { navigate, check_internet_connection } = props;

	const isReEvaluationRequired = GetSavedReEvaluationStatus() === 'true';

	const dispatch = useDispatch();
	const homeScreenDetails = useSelector(state => state.homeScreenDetails);

	// localstorage values
	const isBankAdded = localStorage.getItem('_isBankAdded') === 'true';
	const productId = GetSavedProductId();
	const tenureDays = GetSavedTenureDays();

	const { data: userBankList, isSuccess: bankDataSuccess } = useGetAllBanksQuery('bank_account_id');
	const [updateProductDetails] = useLazyGetUpdatedProductQuery();

	useEffect(() => {
		if (check_internet_connection()) {
			dispatch(getUserStatus());
		}
	}, []);

	useEffect(() => {
		if (isBankAdded && bankDataSuccess) {
			let currentAddedBank = userBankList?.results?.banks_list?.[0];
			let addedBankStatus = currentAddedBank?.status;

			if (addedBankStatus === BANK_STATUS.BANK_VERIFICATION_MANUAL) {
				if (check_internet_connection()) {
					navigate('/' + ROUTE.BANK_MANUAL_VERIFICATION, true);
				}
			} else {
				if (check_internet_connection()) {
					navigate('/' + ROUTE.SELECT_BANK, true);
				}
			}
		}
	}, [userBankList]);

	useEffect(() => {
		if (!isBankAdded) {
			if (homeScreenDetails?.userStatus) {
				if (homeScreenDetails.userStatus === USER_STATUS.INCOME_INFO_PENDING) {
					if (check_internet_connection()) {
						navigate('/' + ROUTE.CONGRATULATIONS, true);
					}
				} else if (homeScreenDetails.userStatus === USER_STATUS.LOAN_ELIGIBILITY_SUCCESS) {
					if (isReEvaluationRequired) {
						(async function productNavigation() {
							try {
								if (productId && tenureDays) {
									const response = await updateProductDetails({
										productTypeId: productId,
										tenure: tenureDays,
									}).unwrap();

									if (response?.status === 200) {
										const isNotServiceable = response?.result?.no_service === 1;
										const isProductUpgraded = response?.result?.is_product_upgraded === 1;
										const isProductDegraded = response?.result?.is_product_degraded === 1;
										const userStatusIsPresent = 'user_status' in response?.result;

										if (isNotServiceable) {
											navigate('/' + ROUTE.NO_SERVICE, true);
										} else {
											if (isProductUpgraded) {
												navigate('/' + ROUTE.UPGRADE_AMOUNT, true, {
													updatedProductData: response?.result,
												});
											} else if (isProductDegraded) {
												// navigate to product degrade UI
											} else if (!isNotServiceable && !isProductUpgraded && !isProductDegraded) {
												if (userStatusIsPresent) {
													if (check_internet_connection()) {
														navigate(getRoute(response?.result?.user_status), true);
													}
												} else {
													navigate('/' + ROUTE.PURPOSE_OF_LOAN, true);
												}
											}
										}
									}
								}
							} catch {}
						})();
					} else {
						if (check_internet_connection()) {
							navigate(getRoute(homeScreenDetails.userStatus), true);
						}
					}
				} else {
					if (check_internet_connection()) {
						navigate(getRoute(homeScreenDetails.userStatus), true);
					}
				}
			}
		}
	}, [homeScreenDetails]);

	useEffect(() => {
		return () => {
			localStorage.removeItem('_reEvaluationStatus');
		};
	}, []);

	if (isReEvaluationRequired) {
		return <ReEvaluation />;
	}

	return <CustomLoader />;
};

export default HocWrapper(HandleFCM);
