import { getVersionCode } from '../Utils/AndroidHelper';

const versionCode = getVersionCode();

const lat = localStorage.getItem('_latitude');
const long = localStorage.getItem('_longitude');
const version = versionCode ? versionCode : '1000';

const queryParams = `?v=${version}&latitude=${lat}&longitude=${long}`;

export const ENDPOINTS = {
	basicInfoDropDown: 'borrower/getDropdownList/',
	saveBasicInfo: `borrower_apk/basic_info/save${queryParams}`,
	createPassword: `borrower_apk/signup/register/password${queryParams}`,
	sendOTP: `borrower_apk/signup/otp/send${queryParams}`,
	refContactStatus: `borrower_apk/reference_contact/get/status${queryParams}`,
	refContactRadioButtons: `borrower_apk/reference_contact/get/types${queryParams}`,
	saveRefContact: `borrower_apk/reference_contact/save${queryParams}`,
	sendRefContactOTP: `borrower_apk/reference_contact/otp/send${queryParams}`,
	validateRefContactOTP: `borrower_apk/reference_contact/otp/validate${queryParams}`,
	resendRefContactOTP: `borrower_apk/reference_contact/otp/resend${queryParams}`,
	skipRefContact: 'borrower_apk/reference_contact/skip',
	editRefContact: `borrower_apk/reference_contact/edit${queryParams}`,
	products: 'borrower_apk/products/home',
	purposeOfLoanDropdown: 'borrower_apk/loan/purpose',
	submitProduct: 'borrower_apk/products/submit',
	submitPurposeOfLoan: 'borrower_apk/application/add',
	getAllBanks: 'borrower_apk/bank_account/banks_list/get',
	setBankAsPrimary: 'borrower_apk/bank_account/bank_details/set_primary',
	getBanksByIfscOrBranch: 'borrower_apk/bank_account/bank_details/get',
	addBank: 'borrower_apk/bank_account/bank_details/add',
	getPartialLoandetails: 'borrower_apk/products/by_id',
	getSanctionLetter: `borrower_apk/loan/sanction_letter${queryParams}`,
	createLoan: 'borrower_apk/loan/add',
	eSign: 'borrower_apk/storage/upload',
	esignOTP: 'borrower_apk/esignaggrement/esignotp',
	esignCancel: '/borrower_apk/esignaggrement/esign/cancel',
	updateDemoUserStatus: 'borrower_apk/demo_user/update_profile_status',
	productNavigation: 'borrower_apk/products/navigation',
	submitUpdatedProduct: 'borrower_apk/products/status/update',
};
