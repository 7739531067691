import React, { useEffect } from 'react';
import Registration from '../../Components/Layout/Registration';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
// Icons
import LocationIcon from '../../Assets/Icons/LocationIcon.svg';
import NotificationIcon from '../../Assets/Icons/NotificationIcon.svg';
import CameraIcon from '../../Assets/Icons/CameraIcon.svg';
import MicrophoneIcon from '../../Assets/Icons/MicrophoneIcon.svg'
// Styling files
import Styles from './Permissions.module.scss';
import CustomButton from '../../Components/HtmlElements/CustomButton';

// Enum
import { ROUTE } from '../../Utils/Enum';

let showLocationPermission, showPostNotificationPermission, showCameraPermission, showMicroPhPermission;

function PermissionsFail(props) {
	const { theme, navigate, handle_android_back_button } = props;

	const GoSettings = () => {
		if (window.Android) {
			window.Android.gotoSettings();
		}
	};

	if (window.Android) {
		showLocationPermission = !window.Android.checkLocationPermissionEnabled();
		showCameraPermission = !window.Android.checkCameraPermissionEnabled();
		showMicroPhPermission = !window.Android.checkMicroPermissionEnabled();

		if (window.Android.getAndroidVersionCode() >= 33) {
			showPostNotificationPermission = !window.Android.checkPostNotificationPermissionEnabled();
		}
	}

	if (showLocationPermission && showPostNotificationPermission && showCameraPermission) {
		navigate('/' + ROUTE.HOME, true);
	}

	const handleAndroidBackButton = () => {
		handle_android_back_button('closeApp');
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<Registration heading='Need Permission’s' height='565px' justifyContent='space-between'>
			<Grid>
				<Typography variant='body1' textAlign='justify' fontSize={14} color={theme.palette.textColor.grey}>
					We need permission to run PeopleFront smoothly and to ensure you that you have the best experience
					in getting a quick service.
				</Typography>
				{showPostNotificationPermission && (
					<Grid container flexDirection='row' marginTop='44px' alignItems='center'>
						<Grid width='30px'>
							<img className={Styles.permissionIcon} src={NotificationIcon} alt='' />
						</Grid>
						<Typography
							fontSize={14}
							fontWeight={600}
							color={theme.palette.textColor.darkBlack}
							marginLeft='8px'>
							NOTIFICATION
						</Typography>
					</Grid>
				)}
				{showLocationPermission && (
					<Grid container flexDirection='row' marginTop='16px' alignItems='center'>
						<Grid width='30px'>
							<img className={Styles.permissionIcon} src={LocationIcon} alt='' />
						</Grid>
						<Typography
							fontSize={14}
							fontWeight={600}
							color={theme.palette.textColor.darkBlack}
							marginLeft='8px'>
							LOCATION
						</Typography>
					</Grid>
				)}
				{showCameraPermission && (
					<Grid container flexDirection='row' marginTop='16px' alignItems='center'>
						<Grid width='30px'>
							<img className={Styles.permissionIcon} src={CameraIcon} alt='' />
						</Grid>
						<Typography
							fontSize={14}
							fontWeight={600}
							color={theme.palette.textColor.darkBlack}
							marginLeft='8px'>
							CAMERA
						</Typography>
					</Grid>
				)}

				{showMicroPhPermission && (
					<Grid container flexDirection='row' marginTop='16px' alignItems='center'>
						<Grid width='30px'>
							<img className={Styles.permissionIcon} src={MicrophoneIcon} alt='' />
						</Grid>
						<Typography
							fontSize={14}
							fontWeight={600}
							color={theme.palette.textColor.darkBlack}
							marginLeft='8px'>
							MICROPHONE
						</Typography>
					</Grid>
				)}
			</Grid>
			<CustomButton buttonText='Go to Settings' clickHandler={GoSettings} />
		</Registration>
	);
}

export default HocWrapper(PermissionsFail);
