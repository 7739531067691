import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CustomSlider from '../../Components/HtmlElements/CustomSlider';
import CustomLoader from '../../Components/Loader/CustomLoader';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import Slider from 'react-slick';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import LoanDetails from './LoanDetails';
import EMISchedule from './EMISchedule';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// skeleton
import ProductSelectionSkeleton from './Skeleton/ProductSelectionSkeleton';

// img
import P2PLogo from '../../Assets/Icons/P2pLogo.svg';
import ProfileAvatar from '../../Assets/Images/AccountIcon.png';
import CarouselImg from '../../Assets/Images/product-carousel-img.svg';
import NotificationIcon from '../../Assets/Icons/NotificationIcon.svg';

// styles
import Style from './Product.module.scss';
import '../../Components/GetStarted/Carousel.scss';

// react-slick carousel related styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// RTK Query
import {
	useSubmitProductMutation,
	useUpdateDemoUserStatusMutation,
	useGetProductsQuery,
} from '../../Services/apiSlice';

// utils
import { localeCurrencyFormat } from '../../Utils/utilities';

// localStorage Values
import {
	SaveProductId,
	SaveTenureDays,
	GetSavedDemoStatus,
	GetSavedProfiePic,
	SaveReEvaluationStatus,
} from '../../Utils/LocalStorageValues';

// redux
import { useDispatch } from 'react-redux';
import { storeLocalData } from '../HomeScreen/LocalStateSlice';

// Enum
import { ROUTE, USER_STATUS } from '../../Utils/Enum';

const settings = {
	infinite: true,
	dots: false,
	slidesToShow: 1,
	mobileFirst: true,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
};

const Product = props => {
	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const profilePic = GetSavedProfiePic();

	// theming
	const { textColor, background } = props.theme.palette;

	const { navigate, handle_android_back_button, check_internet_connection, show_toast } = props;

	// local states
	const [switchTab, setSwitchTab] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState('');
	const [selectTenure, setSelectTenure] = useState('');
	const [selectedProductDetails, setSelectedProductDetails] = useState('');
	const [defaultTenureChanged, setDefaultTenureChanged] = useState(false);

	const dispatch = useDispatch();

	// RTK Query
	const { data: userProducts, isSuccess } = useGetProductsQuery();
	const [submitProduct, { isLoading: submittingProduct }] = useSubmitProductMutation();
	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();

	// this will trigger when user switch between loan history and Emi schedule tabs
	const handleTabSwitch = () => setSwitchTab(prevState => !prevState);

	// trigger when slider moves
	const handleSliderValue = e => {
		// storing selected product
		setSelectedProduct(e.target.value);
	};

	//both requires to decide slider min and max value
	const minProduct = userProducts?.result?.min_amount;
	const maxProduct = userProducts?.result?.max_amount;
	const borrowerImg = userProducts?.result?.borrower_image_url;

	// retrieving products value to pre-fill slider. slider takes an array of obj in [{value:<actual_val>}] this format.
	const totalNoOfProducts = userProducts?.result?.products_list?.map(({ principle_amt }) => ({
		value: principle_amt,
	}));

	const propsForCustomSlider = {
		marks: totalNoOfProducts,
		min: minProduct,
		max: maxProduct,
		defaultValue: isDemoEnabled ? 20000 : maxProduct,
		onChange: handleSliderValue,
	};

	//setting min value on load to input box
	useEffect(() => {
		if (userProducts?.result?.context === 'activeloan') {
			return navigate('/' + ROUTE.REPAYMENT_HOME, true);
		}

		if (isDemoEnabled) {
			setSelectedProduct(20000);
		} else {
			setSelectedProduct(maxProduct);
		}
	}, [userProducts]);

	// selecting single product based on slider value
	// it is required to get product details later
	const singleProduct = userProducts?.result?.products_list?.filter(
		({ principle_amt }) => principle_amt === selectedProduct,
	);

	// tenure days to fill dropdown
	const tenureDays = singleProduct && singleProduct[0] && singleProduct[0].tenure_days;

	const handleSelectedTenure = e => {
		setDefaultTenureChanged(true);

		// storing index of selected tenure from dropdown
		const selectedTenureIndex = e.target.value;

		setSelectTenure(selectedTenureIndex);
	};

	const showLoandetailsContainer = selectTenure !== 'placeholder' && selectedProduct;

	const {
		ltrf_amt,
		processing_fee,
		agreement_fee,
		monthly_interest_rate_pct,
		interest_amount,
		gst_amount,
		disbursal_amount,
		product_schedules,
		product_type_id,
		tenure_days,
		repayment_amount,
	} = selectedProductDetails;

	const loanDetailsData = {
		ltrf_amt,
		processing_fee,
		agreement_fee,
		monthly_interest_rate_pct,
		gst_amount,
		interest_amount,
		repayment_amount,
	};

	const handleProductSubmission = () => {
		if (product_type_id !== null) {
			if (check_internet_connection()) {
				(async function productSubmit() {
					try {
						const response = await submitProduct({ product_type_id }).unwrap();
						if (response?.status === 200) {
							// saving productId for purpose of loan API
							SaveProductId(product_type_id);
							// saving tenureDays for product navigation API in handleFCM comp.
							SaveTenureDays(tenure_days);
							dispatch(storeLocalData({ disbursedAmt: disbursal_amount }));

							let isReEvaluationRequired = response?.result?.is_revaluation_required;
							let isNotServiceable = response?.result?.no_service === 1;
							let isProductUpgraded = response?.result?.is_product_upgraded === 1;
							let isProductDegraded = response?.result?.is_product_degraded === 1;

							if (isNotServiceable) {
								navigate('/' + ROUTE.NO_SERVICE, true);
							} else {
								if (isReEvaluationRequired) {
									SaveReEvaluationStatus('true');
									navigate('/' + ROUTE.RE_EVALUATION, false);
								} else {
									if (isProductUpgraded) {
										navigate('/' + ROUTE.UPGRADE_AMOUNT, false, {
											updatedProductData: response?.result,
										});
									} else if (isProductDegraded) {
										// code related to product-degraded
									} else {
										navigate('/' + ROUTE.PURPOSE_OF_LOAN, false, { selectedProductDetails });
									}
								}
							}
						} else {
							show_toast(response?.message, 'error');
						}
					} catch {
						show_toast('Something went wrong while product submission', 'error');
					}
				})();
			}
		}
	};

	const handleAndroidBackButton = () => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.BASIC_INFO_PENDING,
						}).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('closeApp');
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			handle_android_back_button('closeApp');
		}
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		if (singleProduct) {
			// selecting productId based on Index value. Bcz, mutiple tenure can have seperate Ids
			const selectedProductId =
				singleProduct[0]?.product_type_id[defaultTenureChanged ? selectTenure : tenureDays.length - 1];

			if (selectedProductId) {
				// getting productdetails Object using selectedProductId. Since,the obj key is productId.
				const selectedProductDetails = userProducts?.result?.products_details[0][selectedProductId];

				if (selectedProductDetails) {
					// storing selected productdetails into localstate for later use.
					setSelectedProductDetails(selectedProductDetails);
				}
			}
		}
	}, [singleProduct, selectTenure]);

	useEffect(() => {
		if (tenureDays?.length > 0) {
			let maxTenure = tenureDays.length - 1;
			setSelectTenure(maxTenure);
		}
	}, [tenureDays]);

	return (
		<React.Fragment>
			{submittingProduct || updatingUsrStatus ? <CustomLoader /> : ''}

			<Grid className={Style.parentContainer}>
				<Grid>
					{/* Header */}
					<Grid>
						<Grid container justifyContent='space-between' alignItems='center' padding='18px'>
							<Grid item>
								<img
									src={isDemoEnabled ? profilePic : borrowerImg ? borrowerImg : ProfileAvatar}
									alt='sample-dp'
									className={Style.profilePic}
								/>
							</Grid>
							<Grid item>
								<img src={P2PLogo} alt='p2p-logo' />
							</Grid>
							{/* notification container */}
							<Grid item style={{ visibility: 'hidden' }}>
								<img src={NotificationIcon} alt='p2p-logo' />
							</Grid>
						</Grid>
					</Grid>

					{/* Slider */}
					<Grid item marginBottom='15px'>
						<Slider {...settings}>
							<img alt='slide1' src={CarouselImg} />
							<img alt='slide2' src={CarouselImg} />
							<img alt='slide3' src={CarouselImg} />
						</Slider>
					</Grid>

					<Grid padding='0px 16px'>
						{/* Product Selection with slider */}

						{isSuccess ? (
							<Grid item className={Style.productSelectionContainer}>
								<Grid padding='18px'>
									<Grid>
										<Typography variant='body1' fontSize='12px' color={textColor.white} fontWeight='500'>
											Select Loan Amount
										</Typography>
									</Grid>
									<Grid marginTop='10px'>
										<OutlinedInput
											classes={{ notchedOutline: Style.notchedOutline }}
											readOnly
											style={{
												height: 45,
												caretColor: textColor.white,
												color: textColor.white,
												fontWeight: '500',
												fontSize: '23px',
												borderRadius: '8px',
												letterSpacing: '1px',
											}}
											fullWidth
											value={selectedProduct ? localeCurrencyFormat(selectedProduct, 'en-US') : ''}
											startAdornment={
												<InputAdornment position='start'>
													<span style={{ color: '#19C641', fontSize: '23px' }}>₹</span>
												</InputAdornment>
											}
										/>
									</Grid>

									<Grid marginTop='12px' width='100%'>
										<CustomSlider {...propsForCustomSlider} />
									</Grid>

									<Grid container justifyContent='space-between'>
										<Grid item>
											<Typography variant='body1' fontSize='12px' color={textColor.white}>
												Min:{' '}
												<span style={{ letterSpacing: '.5px' }}>
													{localeCurrencyFormat(minProduct, 'en-US', {
														style: 'currency',
														currency: 'INR',
														minimumFractionDigits: 0,
														maximumFractionDigits: 0,
													})}
												</span>
											</Typography>
										</Grid>

										<Grid item>
											<Typography variant='body1' fontSize='12px' color={textColor.white}>
												Max:{' '}
												<span style={{ letterSpacing: '.5px' }}>
													{localeCurrencyFormat(maxProduct, 'en-US', {
														style: 'currency',
														currency: 'INR',
														minimumFractionDigits: 0,
														maximumFractionDigits: 0,
													})}
												</span>
											</Typography>
										</Grid>
									</Grid>
								</Grid>

								<Grid container className={Style.tenureBox}>
									<Grid item xs={5}>
										<Typography variant='body1' fontSize='12px' color={textColor.white}>
											Select tenure
										</Typography>
									</Grid>
									<Grid item xs={7}>
										<Select
											value={selectTenure}
											onChange={handleSelectedTenure}
											style={{ width: '100%', height: '35px', backgroundColor: background.secondary }}>
											<MenuItem disabled value='placeholder'>
												Select Tenure
											</MenuItem>
											{tenureDays &&
												tenureDays.map((t, idx) => {
													return <MenuItem key={idx} value={idx}>{`${t} days`}</MenuItem>;
												})}
										</Select>
									</Grid>
								</Grid>
							</Grid>
						) : (
							<ProductSelectionSkeleton />
						)}

						{showLoandetailsContainer ? (
							<React.Fragment>
								<Grid className={Style.loandetailsContainer}>
									{/* Tabs ( Loan Details & EMIs) */}
									<Grid container justifyContent='space-around'>
										<Grid item>
											<CustomButton
												clickHandler={handleTabSwitch}
												style={{
													fontSize: '12px',
													borderRadius: 0,
													color: switchTab ? textColor.greyShade2 : textColor.darkBlack,
												}}
												buttonText='Loan Details'
												variant='text'
											/>
										</Grid>
										<Grid item>
											<CustomButton
												clickHandler={handleTabSwitch}
												style={{
													fontSize: '12px',
													borderRadius: 0,
													color: switchTab ? textColor.darkBlack : textColor.greyShade2,
												}}
												buttonText='EMIs Schedule'
												variant='text'
											/>
										</Grid>
									</Grid>

									{/* Tab Border line */}
									<Grid className={switchTab ? Style.borderLineEmi : Style.borderLineLoandetails}></Grid>

									{switchTab ? (
										<React.Fragment>
											<EMISchedule data={product_schedules} />
											<Grid padding='4px' container />
										</React.Fragment>
									) : (
										<React.Fragment>
											<LoanDetails data={loanDetailsData} />

											<Grid padding='0px 12px' marginTop='2px'>
												<Grid className={Style.loanDetailsDivider}></Grid>
											</Grid>

											<Grid padding='12px' container>
												<Grid item xs={6}>
													<Typography variant='body1' fontSize='12px' fontWeight='500' color={textColor.grey}>
														Disbursal Amount
													</Typography>
												</Grid>
												<Grid item xs={6} textAlign='right'>
													<Typography
														variant='body1'
														fontSize='12px'
														fontWeight='500'
														color={textColor.darkBlack}>
														₹{disbursal_amount}
													</Typography>
												</Grid>
											</Grid>
										</React.Fragment>
									)}
								</Grid>
							</React.Fragment>
						) : null}
					</Grid>
				</Grid>
				<Grid>
					{showLoandetailsContainer ? (
						<Grid padding='0px 16px 4px 16px'>
							{isSuccess && product_type_id !== null ? (
								<CustomButton
									clickHandler={handleProductSubmission}
									buttonText='Get Loan Now'
									endIcon={<ArrowForwardIcon />}
								/>
							) : (
								''
							)}
						</Grid>
					) : null}
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default HocWrapper(Product);
