import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Registration from '../../Components/Layout/Registration';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// stylesheet
import Styles from './EsignAgreement.module.scss';

import CustomLoader from '../../Components/Loader/CustomLoader';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomNumberInput from '../../Components/HtmlElements/CustomNumberInput';
import CustomCheckbox from '../../Components/HtmlElements/CustomCheckbox';
import ConfirmationPopUp from '../ConfirmationPopUp/ConfirmationPopUp';

// RTK query
import {
	useEsignOTPMutation,
	useUpdateDemoUserStatusMutation,
	useCancelEsignMutation,
} from '../../Services/apiSlice';

// LocalStorageValues
import { GetSavedLoanId, GetSavedDemoStatus, GetDeviceDetails } from '../../Utils/LocalStorageValues';

// Enum
import { ROUTE, USER_STATUS, ESIGN } from '../../Utils/Enum';

let interval, timer;

function EsignAgreementOtp(props) {
	const {
		theme,
		navigate,
		handle_android_back_button,
		show_toast,
		check_internet_connection,
		get_previous_props,
	} = props;
	const { darkBlack, grey } = theme.palette.textColor;
	const { errorColor } = theme.palette.error.main;

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [otp, setOtp] = useState();
	const [otpError, setOtpError] = useState(false);
	const [showResendButton, setShowResendButton] = useState(false);
	const [resendOtpError, setResendOtpError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [checked, setChecked] = useState(false);

	const [triggerEsign, { isLoading: apiIsCalling }] = useEsignOTPMutation();
	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();
	const [cancelEsignProcess, { isLoading: cancellingEsign }] = useCancelEsignMutation();

	const loanId = GetSavedLoanId();
	const prevProps = get_previous_props();

	const mobileNumber = prevProps?.mobileNumber;
	const docId = prevProps?.doc_id;

	const deviceDetails = GetDeviceDetails();
	const versionCode = localStorage.getItem('_versionCode');

	const handleAndroidBackButton = () => setIsConfirmModalOpen(true);

	// modal related methods
	const handleConfirmModalOkay = () => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.LOAN_ELIGIBILITY_SUCCESS,
						}).unwrap();

						if (response?.status === 200) {
							navigate('/' + ROUTE.PRODUCT, true);
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			if (check_internet_connection()) {
				(async function cancelEsign() {
					try {
						const response = await cancelEsignProcess({ loan_id: loanId, doc_id: docId, is_apk: 1 }).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('goBack');
						} else {
							show_toast(response?.message, 'error');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		}
	};
	const handleConfirmModalCancel = () => setIsConfirmModalOpen(false);

	const runTimer = () => {
		let element = document.getElementById('timer');
		timer = 30;
		interval = setInterval(() => {
			if (timer <= 0) {
				clearInterval(interval);
				setShowResendButton(true);
			} else {
				timer = timer - 1;
				element.textContent = timer >= 10 ? `00 : ${timer}s` : `00 : 0${timer}s`;
			}
		}, 1000);
	};

	const otpHandler = event => {
		const val = event.target.value && event.target.value.split('-').join('');
		if (val.length <= 6) {
			setOtp(val);
			if (otpError) {
				setOtpError(false);
				setErrorMessage('');
			}
		}
	};

	const handleResendOTP = () => {
		setShowResendButton(false);
		setChecked(false);

		if (loanId && check_internet_connection()) {
			(async function resendEsignOTP() {
				try {
					const response = await triggerEsign({
						reqBody: { loan_id: loanId, doc_id: docId, is_agreement_signed: true, is_apk: 1 },
						endpoint: 'reSent',
					}).unwrap();

					if (response?.status === 200) {
						runTimer();
						setOtp('');
						show_toast(response?.message, 'info');
					} else {
						show_toast(response?.message, 'error');
						setResendOtpError(true);
					}
				} catch (err) {
					show_toast(err?.data?.message, 'error');
					console.error('Exception occured while esign OTP resend');
				}
			})();
		} else {
			console.error('loanId is missing');
		}
	};

	const handleChange = event => {
		setChecked(event.target.checked);
	};

	const handleEsignContinue = () => {
		if (isDemoEnabled ? otp?.length === 6 || checked : otp.length === 6 && checked) {
			if (check_internet_connection()) {
				(async function validateEsignOtp() {
					const { deviceName, ipAddress, latitude, longitude, macAddress, model } = deviceDetails;
					try {
						const response = await triggerEsign({
							reqBody: {
								user_entered_otp: otp,
								doc_id: docId,
								is_apk: 1,
								information: {
									device_name: deviceName,
									imei_1: 'NA',
									imei_2: 'NA',
									ip_address: ipAddress,
									latitude: latitude,
									longitude: longitude,
									mac_address: macAddress,
									model: model,
									network1: 'NA',
									network2: 'NA',
									signed_date: 'NA',
									browser_name: 'NA',
									full_version: versionCode,
									user_agent: 'NA',
									os_name: 'android',
								},
							},
							endpoint: 'validateOtp',
						}).unwrap();

						if (response?.status === 200) {
							if (response?.result?.status === ESIGN.OTP_VERIFICATION_SUCCESS) {
								navigate('/' + ROUTE.LOAN_DISBURSAL_INPROGRESS, true);
							}
						} else {
							show_toast(response?.message, 'error');
						}
					} catch (err) {
						show_toast(err?.data?.message, 'error');
					}
				})();
			}
		}
	};

	const disableContinueBtn = checked && otp && otp.length === 6;

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		show_toast('OTP sent Successfully');
		runTimer();

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (isDemoEnabled) {
			setChecked(true);
		}
	}, []);

	return (
		<React.Fragment>
			<ConfirmationPopUp
				message={
					<span>
						Do you want to cancel the <br /> E-sign Process?
					</span>
				}
				showModal={isConfirmModalOpen}
				handleConfirmModalOkay={handleConfirmModalOkay}
				handleConfirmModalCancel={handleConfirmModalCancel}
			/>

			{apiIsCalling || updatingUsrStatus || cancellingEsign ? <CustomLoader /> : ''}

			<Registration
				heading='E-Sign Loan Agreement'
				subheading='Verify via OTP'
				minHeight='565px'
				justifyContent='space-between'>
				<Grid>
					<Grid className={Styles.numberText}>
						<Typography color={darkBlack} className={Styles.otpText} variant='body1'>
							Enter 6-digit code we have sent to <br />
							+91 {mobileNumber}
						</Typography>
					</Grid>
					<Grid className={Styles.otpInputGrid}>
						<CustomNumberInput
							id='otp'
							name='otp'
							label='OTP'
							value={otp}
							onChangeHandler={otpHandler}
							maskFormat={'000-000'}
						/>
						{otpError && (
							<Typography className={Styles.errorText} color={errorColor}>
								<em>{errorMessage}</em>
							</Typography>
						)}
						{!showResendButton && (
							<Typography id='timer' className={Styles.timerText} color={grey}>
								{resendOtpError ? '' : '00 : 30s'}
							</Typography>
						)}
						{/* show re-send OTP */}
						{showResendButton && (
							<Grid className={Styles.resendOtpGrid}>
								<Typography className={Styles.resendText} color={grey}>
									Did not receive OTP yet?
								</Typography>
								<CustomButton
									id='resendOtp'
									clickHandler={handleResendOTP}
									buttonText='Resend OTP'
									fullWidth={false}
									size='small'
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid container direction='column' className={Styles.SecondMainDiv}>
					<Grid container direction='row' className={Styles.PermissionConsent}>
						<Grid>
							<CustomCheckbox
								className={Styles.CustomCheckbox}
								checked={checked}
								onChangeHanlder={handleChange}
							/>
						</Grid>
						<Typography variant='body1' fontSize={12} color={darkBlack} textAlign='justify'>
							I authorize PeopleFront to use my virtual ID details (as applicable) to electronically sign this
							loan agreement document. By signing, I agree to the Terms & Conditions of the PeopleFront
							platform, and I understand and confirm that this document will be legally binding.
						</Typography>
					</Grid>
					<Grid>
						<CustomButton
							clickHandler={handleEsignContinue}
							buttonText='Continue'
							disabled={isDemoEnabled ? false : !disableContinueBtn}
						/>
					</Grid>
				</Grid>
			</Registration>
		</React.Fragment>
	);
}

export default HocWrapper(EsignAgreementOtp);
