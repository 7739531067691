import React, { useState, useEffect } from 'react';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomHeader from '../../Components/Layout/CustomHeader';
import CustomLoader from '../../Components/Loader/CustomLoader';
import ConfirmationPopUp from '../ConfirmationPopUp/ConfirmationPopUp';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// MUI related imports
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Icons & Svg
import CheckCircle from '@mui/icons-material/CheckCircle';
import BankImg from '../../Assets/Icons/BankIcon.svg';

// Stylesheet
import Styles from './EsignAgreement.module.scss';

// PDF
import PDFRenderer from '../PDFRenderer/PDFRenderer';

// RTK query
import {
	useEsignOTPMutation,
	useUpdateDemoUserStatusMutation,
	useCancelEsignMutation,
} from '../../Services/apiSlice';

// localStorageValues
import { GetSavedLoanId, GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

// Enum
import { ROUTE, USER_STATUS } from '../../Utils/Enum';

function EsignAgreement(props) {
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const {
		theme,
		navigate,
		handle_android_back_button,
		check_internet_connection,
		get_previous_props,
		show_toast,
	} = props;

	const prevProps = get_previous_props();
	const preSignedURL = prevProps?.loanAgreementPresignedURL;
	const bankName = prevProps?.bankName;
	const bankAccnum = prevProps?.bankAccnum;
	const docId = prevProps?.docId;

	const loan_id = GetSavedLoanId();

	const [triggerEsign, { isLoading: apiIsCalling }] = useEsignOTPMutation();
	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();
	const [cancelEsignProcess, { isLoading: cancellingEsign }] = useCancelEsignMutation();

	const handleAndroidBackButton = () => setIsConfirmModalOpen(true);

	// modal related methods
	const handleConfirmModalOkay = () => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.LOAN_ELIGIBILITY_SUCCESS,
						}).unwrap();

						if (response?.status === 200) {
							navigate('/' + ROUTE.PRODUCT, true);
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			if (check_internet_connection()) {
				(async function cancelEsign() {
					try {
						const response = await cancelEsignProcess({ loan_id, doc_id: docId, is_apk: 1 }).unwrap();

						if (response?.status === 200) {
							navigate('/' + ROUTE.PRODUCT, true);
						} else {
							show_toast(response?.message, 'error');
						}
					} catch (err) {
						show_toast('Some exception occured!');
					}
				})();
			}
		}
	};
	const handleConfirmModalCancel = () => setIsConfirmModalOpen(false);

	const handleEsignClick = () => {
		if (loan_id) {
			if (check_internet_connection()) {
				(async function sendEsignOTP() {
					try {
						const response = await triggerEsign({
							reqBody: { loan_id, doc_id: docId, is_agreement_signed: true, is_apk: 1 },
							endpoint: 'sent',
						}).unwrap();

						if (response?.status === 200 && response?.result?.docID) {
							if (check_internet_connection()) {
								navigate('/' + ROUTE.ESIGN_OTP, true, {
									mobileNumber: response?.result?.mobile_number,
									doc_id: response?.result?.docID,
								});
							}
						} else {
							show_toast(response?.message, 'error');
						}
					} catch {
						console.error('Exception occured while sending Esign OTP');
					}
				})();
			}
		} else {
			console.error('LoanId is not present');
		}
	};

	useEffect(() => {
		if (window.Android) {
			window.Android.getDeviceInfo();
		}
	}, []);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<CustomHeader
			header='E-Sign Agreement'
			style={{ padding: 12 }}
			handleBackButton={handleAndroidBackButton}>
			{apiIsCalling || updatingUsrStatus || cancellingEsign ? <CustomLoader /> : ''}

			<FormControl className={Styles.formControl}>
				<ConfirmationPopUp
					message={
						<span>
							Do you want to cancel the <br /> E-sign Process?
						</span>
					}
					showModal={isConfirmModalOpen}
					handleConfirmModalOkay={handleConfirmModalOkay}
					handleConfirmModalCancel={handleConfirmModalCancel}
				/>

				<Grid container direction='column' className={Styles.firstMainGrid}>
					<Grid container direction='column' rowGap='8px'>
						<Typography fontSize='12px' color={theme.palette.textColor.grey}>
							Selected Bank Account
						</Typography>
						<Grid container flexWrap='nowrap' direction='row' className={Styles.firstSubMainGrid}>
							<Grid>
								<img src={BankImg} alt='sbi-logo' />
							</Grid>
							<Grid>
								<Typography marginLeft='12px' fontSize='16px' fontWeight='600'>
									{bankName} - {bankAccnum}
								</Typography>
							</Grid>
							<Grid>
								<CheckCircle variant='validCheck' fontSize='20px' />
							</Grid>
						</Grid>
						<Grid paddingTop='8px'>
							<Typography variant='body1' fontSize='10px'>
								<b>Note:</b> An OTP will be sent to your registered mobile number
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid className={Styles.pdfContainer}>
					<PDFRenderer URL={preSignedURL} />
				</Grid>

				<Grid>
					<CustomButton clickHandler={handleEsignClick} buttonText='E-Sign Agreement' />
				</Grid>
			</FormControl>
		</CustomHeader>
	);
}

export default HocWrapper(EsignAgreement);
