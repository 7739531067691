import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomInput from '../../Components/HtmlElements/CustomInput';
import { IconsList } from '../../Assets/PathIcons/PathIcons';
import Registration from '../../Components/Layout/Registration';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import DeepClone from '../../Utils/DeepClone';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import { sendGoogleLoginToken, verifyUserLoginDetails, resetLoginState } from './LoginSlice';
import { SaveToken, SaveDemoStatus } from '../../Utils/LocalStorageValues';
import getRoute from '../../Utils/GetRoute';
import CustomLoader from '../../Components/Loader/CustomLoader';
import FormControl from '@mui/material/FormControl';
// Styling
import Styles from './Login.module.scss';
//Images and Icons
import GoogleLogo from '../../Assets/Icons/GoogleIcon.svg';
import { ToggleButton } from '@mui/material';

// utils
import { getVersionCode } from '../../Utils/AndroidHelper';

import { emailWithNoSpace, passwordRegex } from '../../Utils/Regex';

// Enum
import { ROUTE } from '../../Utils/Enum';

function Login(props) {
	const { theme, show_toast, navigate, check_internet_connection, handle_android_back_button } = props;
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [userDetails, setUserDetails] = useState({
		email: '',
		password: '',
		emailError: false,
		passwordError: false,
	});
	const { darkBlack, grey } = theme.palette.textColor;
	const errorColor = theme.palette.error.main;
	const isLoading = useSelector(state => state.userLoginDetails.isLoading);
	const userLoginDetails = useSelector(state => state.userLoginDetails.loginDetails);
	const userSignUpDetails = useSelector(state => state.userLoginDetails.googleSignupDetails);

	const handlePasswordVisibility = () => setShowPassword(show => !show);
	const handleUserInput = event => {
		let { name, value } = event.target;
		const previousState = DeepClone(userDetails);
		previousState[name] = name === 'email' ? value.toLowerCase() : value;
		if (previousState[`${name}Error`]) {
			previousState[`${name}Error`] = false;
		}
		if (name === 'email') {
			if (value === '' || emailWithNoSpace.test(value)) {
				setUserDetails(previousState);
			}
		} else {
			if (value === '' || passwordRegex.test(value)) {
				setUserDetails(previousState);
			}
		}
	};

	const handleForgotPassword = () => {
		// Navigating to next screen
		if (check_internet_connection()) {
			navigate('/' + ROUTE.FORGOT_PASSWORD, false);
		}
	};

	const clearTheStore = () => {
		// Here we are clearing the user login details from the redux store
		dispatch(resetLoginState());
	};

	const googleLoginFromAndroid = () => {
		if (window.Android) {
			window.Android.googleLoginThroughAndroid();
		}
	};

	const callGoogleLoginApi = () => {
		let _googleToken = localStorage.getItem('_googleToken');
		if (_googleToken) {
			if (check_internet_connection()) {
				dispatch(
					sendGoogleLoginToken({
						signup_type: 'google',
						scope: '1',
						access_token: _googleToken,
					}),
				);
			}
		}
	};

	const handleAndroidBackButton = () => {
		handle_android_back_button('closeApp');
	};

	const validateUserDetails = e => {
		e.preventDefault();
		const previousState = DeepClone(userDetails);
		if (userDetails.email && userDetails.password) {
			// Proceed to api
			callLoginApi();
		} else {
			if (!userDetails.email) {
				previousState.emailError = true;
			}
			if (!userDetails.password) {
				previousState.passwordError = true;
			}
		}
		setUserDetails(previousState);
	};

	const callLoginApi = () => {
		if (check_internet_connection()) {
			dispatch(
				verifyUserLoginDetails({
					email_address: userDetails.email.trim(),
					login_password: userDetails.password.trim(),
				}),
			);
		}
	};

	const validateLoginDetails = () => {
		if (userLoginDetails) {
			if (userLoginDetails.statusCode === 200) {
				userLoginDetails.loginToken && SaveToken(userLoginDetails.loginToken);
				navigate(getRoute(userLoginDetails.userStatus, 'login'), true, {
					loginMobileNumber: userLoginDetails.mobileNumber,
					loginMobileToken: userLoginDetails.mobileToken,
				});
			} else {
				userLoginDetails.message && show_toast(userLoginDetails.message, 'error');
			}
		}
	};

	const validateSignupDetails = () => {
		if (userSignUpDetails) {
			if (userSignUpDetails.statusCode === 200) {
				userSignUpDetails.loginToken && SaveToken(userSignUpDetails.loginToken);
				if (userSignUpDetails?.is_demo_user !== null) {
					SaveDemoStatus(userSignUpDetails?.is_demo_user);
				}
				// if (window.Android) {
				// 	window.Android.storeAccessToken(userLoginDetails.loginToken);
				// }
				navigate(getRoute(userSignUpDetails.userStatus), true, {
					emailId: userSignUpDetails.loginEmail,
				});
			} else {
				userSignUpDetails.message && show_toast(userSignUpDetails.message, 'error');
			}
		}
	};

	useEffect(() => {
		document.addEventListener('getGooleLoginToken', callGoogleLoginApi);
		return () => {
			document.removeEventListener('getGooleLoginToken', callGoogleLoginApi);
		};
	}, []);

	useEffect(() => {
		if (userLoginDetails.statusCode) {
			validateLoginDetails();
		}
	}, [userLoginDetails]);

	useEffect(() => {
		if (userSignUpDetails.statusCode) {
			validateSignupDetails();
		}
	}, [userSignUpDetails]);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			clearTheStore();
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		getVersionCode();
	}, []);

	return (
		<>
			{isLoading && <CustomLoader />}
			<Registration
				heading='Welcome'
				subheading='Please log in to your account'
				justifyContent='space-between'
				height='565px'>
				<Grid container direction='column' className={Styles.inputsMainDiv}>
					<FormControl component='form' onSubmit={validateUserDetails}>
						<Grid container direction='column' className={Styles.InputSubDiv}>
							<Grid className={Styles.ButtonSwitch}>
								<ToggleButton value='investor' className={Styles.InvestorButtonSwitch} disabled>
									INVESTOR
								</ToggleButton>
								<ToggleButton value='borrower' className={Styles.BorrowerButtonSwitch}>
									BORROWER
								</ToggleButton>
							</Grid>

							<Grid>
								<Grid className={Styles.inputDiv}>
									<CustomInput
										name='email'
										value={userDetails.email}
										onChange={handleUserInput}
										withIcon
										Icon={IconsList.MailIcon}
										label='Email ID'
										placeholder='example@gmail.com'
										error={userDetails.emailError}
									/>
									{userDetails.emailError && (
										<Typography className={Styles.errorText} color={errorColor}>
											<em>*Please enter your email address</em>
										</Typography>
									)}
								</Grid>
								<Grid className={Styles.inputDiv}>
									<CustomInput
										type={showPassword ? 'text' : 'password'}
										name='password'
										withIcon
										Icon={IconsList.PasswordIcon}
										label='Password'
										placeholder='Password'
										value={userDetails.password}
										onChange={handleUserInput}
										error={userDetails.passwordError}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton onClick={handlePasswordVisibility}>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									{userDetails.passwordError && (
										<Typography className={Styles.errorText} color={errorColor}>
											<em>*Please enter your password</em>
										</Typography>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid container direction='column' className={Styles.LoginBtnMainDiv}>
							<CustomButton
								type='submit'
								variant='contained'
								buttonText='Log in as Borrower'
								disableElevation={true}
								className={Styles.BorrowerLoginButton}
							/>
						</Grid>
					</FormControl>
					<Grid direction='column' m='0' className={Styles.LoginBtnMainDiv}>
						<Typography
							variant='body2'
							color={darkBlack}
							className={Styles.forgotText}
							onClick={handleForgotPassword}>
							Forgot Password?
						</Typography>
					</Grid>
				</Grid>
				<Grid className={Styles.DividerGrid}>
					<Divider>
						<Grid className={Styles.orGrid}>OR</Grid>
					</Divider>
				</Grid>
				<Grid>
					<Grid className={Styles.signUpMainDiv}>
						<Typography color={grey} fontSize={14} fontWeight={600}>
							Don’t have an account? Create Account
						</Typography>
					</Grid>
					<Grid xs={12} item className={Styles.googleLogin} onClick={googleLoginFromAndroid}>
						<img src={GoogleLogo} alt='google-logo' />
						<Typography variant='body1' color={darkBlack} className={Styles.signupText}>
							Sign Up with Google
						</Typography>
					</Grid>
				</Grid>
			</Registration>
		</>
	);
}

export default HocWrapper(Login);
