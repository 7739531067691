import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	disbursal_amt: '',
};

export const LocalStateSlice = createSlice({
	name: 'localState',
	initialState,
	reducers: {
		resetLocalState: state => {
			state.disbursal_amt = '';
		},
		storeLocalData: (state, { payload }) => {
			state.disbursal_amt = payload.disbursedAmt;
		},
	},
});

export const { storeLocalData } = LocalStateSlice.actions;

export default LocalStateSlice.reducer;
