import { combineReducers } from '@reduxjs/toolkit';
import LoginReducer from '../Containers/Login/LoginSlice';
import OtpReducer from '../Containers/Otp/OtpSlice';
import ForgotPasswordReducer from '../Containers/ForgotPassword/ForgotPasswordSlice';
import ResetPasswordReducer from '../Containers/ResetPassword/ResetPasswordSlice';
import IncomeInfoReducer from '../Containers/IncomeInfo/IncomeInfoSlice';
import HomeScreenReducer from '../Containers/HomeScreen/HomeScreenSlice';
import LocalStateReducer from '../Containers/HomeScreen/LocalStateSlice';
// RTK Query
import { apiSlice } from '../Services/apiSlice';

export const rootReducer = combineReducers({
	// Your reducers has to come here:
	userLoginDetails: LoginReducer,
	userOtpDetails: OtpReducer,
	forgotPasswordDetails: ForgotPasswordReducer,
	resetPasswordDetails: ResetPasswordReducer,
	incomeInfoDetails: IncomeInfoReducer,
	homeScreenDetails: HomeScreenReducer,
	localState: LocalStateReducer,
	// passing API slice to the reducer
	[apiSlice.reducerPath]: apiSlice.reducer,
});

export const whiteListedReducers = ['localState'];
