export const IconsList = {
	MailIcon: (
		<path
			id='Path_248012'
			data-name='Path 248012'
			d='M10,22v8H26V22l-8,3Zm0-4v2l8,3,8-3V18Zm0-2H26a2,2,0,0,1,2,2V30a2,2,0,0,1-2,2H10a2,2,0,0,1-2-2V18a2,2,0,0,1,2-2Z'
			transform='translate(-8 -16)'
			fill='#a0a0a0'
		/>
	),
	PasswordIcon: (
		<path
			id="Path_248013"
			data-name="Path 248013"
			d="M9.263,24.526a5.271,5.271,0,0,0,5.156-4.211h2.213v2.105h2.105V20.316h2.105v3.158h2.105V20.316H24V18.211H14.419a5.263,5.263,0,1,0-5.156,6.316Zm0-8.421a3.158,3.158,0,1,1-3.158,3.158A3.162,3.162,0,0,1,9.263,16.105Z"
			transform="translate(-4 -14)"
			fill="#a0a0a0"
		/>
	),
};
