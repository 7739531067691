import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import exclamation from '../../Assets/Icons/ExclamationIcon.svg';
import calender from '../../Assets/Icons/CalendarCheckIcon.svg';
import CustomButton from '../../Components/HtmlElements/CustomButton';

// slice
import { getUserStatus, getPanName } from '../HomeScreen/HomeScreenSlice';

// Stylesheet
import Styles from './EligibilityCheck.module.scss';

import { useDispatch, useSelector } from 'react-redux';

function OnHold(props) {
	const { theme, navigate, handle_android_back_button, check_internet_connection } = props;

	const dispatch = useDispatch();
	const panName = useSelector(getPanName);

	const moveUserToHomeScreen = () => navigate('/home', true);

	const handleAndroidBackButton = () => handle_android_back_button('closeApp');

	useEffect(() => {
		if (check_internet_connection()) {
			dispatch(getUserStatus());
		}
	}, []);
	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);

		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={exclamation} alt='exclamation-icon' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>Hello! {panName}</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography className={Styles.CardBodyText} color={theme.palette.textColor.darkBlack}>
						At present, we are not serving in your area. Thank you for your interest. 
						</Typography>

						<Grid className={Styles.CardBodyLastGrid}>
							<Grid>
								<img src={calender} alt='calender-check' />
							</Grid>
							<Typography>Please try again after 90 days.</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid className={Styles.NavigationButton}>
					<CustomButton buttonText='Go to Home Screen' clickHandler={moveUserToHomeScreen} />
				</Grid>
			</Grid>
		</Grid>
	);
}

export default HocWrapper(OnHold);
