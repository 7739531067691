import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// svg
import eligibilityTimer from '../../Assets/Icons/EligibilityTimerIcon.svg';

// Stylesheet
import Styles from './BankVerificationManual.module.scss';

// ENUM
import { ROUTE } from '../../Utils/Enum';

function BankVerificationManual(props) {
	const { theme, navigate } = props;

	const handleAndroidBackButton = () => navigate('/' + ROUTE.PRODUCT, true);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);

		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={eligibilityTimer} alt='eligibility-timer' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>Manual Verification in Progress…</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography className={Styles.CardBodyText} color={theme.palette.textColor.grey}>
							Your profile is reviewing. Please wait, It will take 12 hours.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default HocWrapper(BankVerificationManual);
