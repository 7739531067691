import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// svg
import SuccessIcon from '../../Assets/Icons/SuccessTickIcon.svg';

//Styles
import Styles from './LoanDisbursalSuccess.module.scss';
import CustomButton from '../../Components/HtmlElements/CustomButton';

function LoanDisbursalSuccess(props) {
	const { theme } = props;

	return (
		<Grid
			container
			direction='column'
			backgroundColor={theme.palette.primary.main}
			className={Styles.ParentContainer}>
			<Grid className={Styles.FirstChildContainer}>
				<img src={SuccessIcon} alt='success-img' />
				<Typography variant='h6' color={theme.palette.textColor.white} className={Styles.Textheader}>
					Hooray!
				</Typography>
				<Typography variant='h6' color={theme.palette.textColor.white}>
					Disbursal Completed
				</Typography>
			</Grid>
			<Grid className={Styles.SecondChildContainer} backgroundColor={theme.palette.background.primary}>
				<Typography
					variant='body1'
					color={theme.palette.textColor.grey}
					className={Styles.SuccessMessageText}>
					We have transferred your loan amount ₹20,000. It should be reflecting in your account soon.
				</Typography>
				<CustomButton buttonText='Continue' />
			</Grid>
		</Grid>
	);
}

export default HocWrapper(LoanDisbursalSuccess);
