import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ThemeProvider from '@mui/system/ThemeProvider';
import React, { useEffect } from 'react';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStatus, resetHomeScreenState } from './HomeScreenSlice';
import getRoute from '../../Utils/GetRoute';
// Styling files:
import Styles from './Home.module.scss';
// Images and Icons:
import AccountIcon from '../../Assets/Images/AccountIcon.png';
import P2PLOGO from '../../Assets/Icons/P2pLogo.svg';
import CarouselImg from '../../Assets/Images/product-carousel-img.svg';

// theme
import { CustomTheme } from '../../Components/Loader/ComponentStyles';

// RTK query
import { useGetRefContactStatusQuery } from '../../Services/apiSlice';
import Slider from 'react-slick';

// localStorageValues
import { SaveDemoStatus } from '../../Utils/LocalStorageValues';
import callRefreshToken from '../../Utils/RefreshToken';

// Enum
import { ROUTE, USER_STATUS } from '../../Utils/Enum';

const settings = {
	infinite: true,
	slidesToShow: 1,
	mobileFirst: true,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
};

function Home(props) {
	const { theme, navigate, check_internet_connection, handle_android_back_button } = props;

	const dispatch = useDispatch();
	const homeScreenDetails = useSelector(state => state.homeScreenDetails);

	const { data: refContactStat } = useGetRefContactStatusQuery();
	const referenceContactStatus = refContactStat?.result?.reference_contact_status;
	const isLoanEligibilitySuccess = homeScreenDetails?.userStatus === USER_STATUS.LOAN_ELIGIBILITY_SUCCESS;

	const homeApiStatus = homeScreenDetails?.error;

	const moveUserBasedOnStatus = () => {
		const {
			PAN_CHECK_FAILED,
			ELIGIBILITY_CHECK_FAILED,
			ELIGIBILITY_CHECK_INPROGRESS,
			ELIGIBILITY_CHECK_MANUAL,
			BASIC_INFO_PENDING,
			REFERENCE_CONTACT_INFO_PENDING,
			KYC_VERIFICATION_PENDING,
			KYC_VERIFICATION_MANUAL,
			KYC_VERIFICATION_FAILED,
			KYC_VERIFICATION_INPROGRESS,
			SELFIE_VERIFICATION_FAILED,
			SELFIE_CHECK_INPROGRESS,
			SELFIE_VERIFICATION_MANUAL,
			SELFIE_VERIFICATION_PENDING,
			SELFIE_VERIFICATION_INPROGRESS,
			LOAN_ELIGIBILITY_INPROGRESS,
			LOAN_ELIGIBILITY_SUCCESS,
			ELIGIBILITY_CHECK_PENDING,
			ON_HOLD,
			LOAN_ELIGIBILITY_FAILED,
			VKYC_PENDING,
			VKYC_AUDIT_PENDING,
		} = USER_STATUS;

		if (
			[
				PAN_CHECK_FAILED,
				ELIGIBILITY_CHECK_FAILED,
				ELIGIBILITY_CHECK_INPROGRESS,
				ELIGIBILITY_CHECK_MANUAL,
				BASIC_INFO_PENDING,
				REFERENCE_CONTACT_INFO_PENDING,
				KYC_VERIFICATION_PENDING,
				KYC_VERIFICATION_MANUAL,
				KYC_VERIFICATION_FAILED,
				KYC_VERIFICATION_INPROGRESS,
				SELFIE_VERIFICATION_FAILED,
				SELFIE_CHECK_INPROGRESS,
				SELFIE_VERIFICATION_MANUAL,
				SELFIE_VERIFICATION_PENDING,
				SELFIE_VERIFICATION_INPROGRESS,
				LOAN_ELIGIBILITY_INPROGRESS,
				LOAN_ELIGIBILITY_SUCCESS,
				ELIGIBILITY_CHECK_PENDING,
				ON_HOLD,
				LOAN_ELIGIBILITY_FAILED,
				VKYC_PENDING,
				VKYC_AUDIT_PENDING,
			].includes(homeScreenDetails?.userStatus)
		) {
			if (homeScreenDetails?.userStatus === VKYC_PENDING) {
				if (window.Android) {
					if (window.Android.checkMicroPermissionEnabled() && window.Android.checkCameraPermissionEnabled()) {
						if (check_internet_connection()) {
							navigate('/' + ROUTE.CONGRATULATIONS, true);
						}
					} else {
						navigate('/' + ROUTE.PERMISSION_FAIL, true);
					}
				}
			} else {
				if (check_internet_connection()) {
					navigate(
						getRoute(homeScreenDetails && homeScreenDetails.userStatus, referenceContactStatus),
						false,
					);
				}
			}
		} else {
			if (check_internet_connection()) {
				navigate('/' + ROUTE.CONGRATULATIONS, true);
			}
		}
	};

	const handleAndroidBackButton = () => handle_android_back_button('closeApp');

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
			dispatch(resetHomeScreenState());
		};
	}, []);

	useEffect(() => {
		if (homeApiStatus === 401) {
			(async function refreshToken() {
				try {
					const response = await callRefreshToken();
					if (response) {
						if (check_internet_connection()) {
							dispatch(getUserStatus());
						}
					}
				} catch {}
			})();
		} else {
			if (check_internet_connection()) {
				dispatch(getUserStatus());
			}
		}
	}, [homeApiStatus]);

	useEffect(() => {
		if (homeScreenDetails?.is_demo_user !== null) {
			SaveDemoStatus(homeScreenDetails?.is_demo_user);
		}

		if (homeScreenDetails?.userStatus === USER_STATUS.LOAN_ELIGIBILITY_SUCCESS) {
			navigate(getRoute(homeScreenDetails?.userStatus, homeScreenDetails?.loanStatus), true);
		}
	}, [homeScreenDetails?.userStatus]);

	useEffect(() => {
		if (window.Android) {
			window.Android.storeUserStatus(homeScreenDetails?.userStatus, homeScreenDetails?.loanStatus);
		}
	}, [homeScreenDetails]);

	return (
		<>
			{homeScreenDetails.isLoading || isLoanEligibilitySuccess ? <HomeScreenLoader /> : null}

			<Grid container direction='column' className={Styles.ParentDivContainer} flexWrap='noWrap'>
				<Grid>
					<Grid container className={Styles.MainHeader}>
						<Grid>
							<img src={AccountIcon} alt='profile-icon' className={Styles.profile} />
						</Grid>
						<Grid>
							<img src={P2PLOGO} alt='logo' className={Styles.p2pLogo} />
						</Grid>
						<Grid>{/* notification Icon will goes here */}</Grid>
					</Grid>

					<Grid>
						<Slider {...settings}>
							<img alt='slide1' src={CarouselImg} />
							<img alt='slide2' src={CarouselImg} />
							<img alt='slide3' src={CarouselImg} />
						</Slider>
					</Grid>

					<Grid className={Styles.secondContainer}>
						<Grid container direction='column' rowGap='16px' color={theme.palette.textColor.white}>
							<Typography fontSize='16px' color={theme.palette.textColor.white}>
								Please Complete Your Loan Application
							</Typography>
							<Typography fontSize='12px'>
								It will help us to provide you customised loan products based on your profile.
							</Typography>
							<Typography fontSize='12px'>It will take 10 minute only.</Typography>
						</Grid>
					</Grid>

					<Grid
						className={Styles.documentRequiredContainer}
						backgroundColor={theme.palette.background.secondary}>
						<Grid>
							<Typography
								padding='10px 20px 2px 20px'
								fontSize='12px'
								color={theme.palette.textColor.darkBlack}>
								Documents Required :
							</Typography>
							<ul className={Styles.documentList}>
								<li>PAN Card</li>
								<li>Aadhaar Card</li>
								<li>Driving License/Voter ID</li>
							</ul>
						</Grid>
					</Grid>
				</Grid>
				<Grid className={Styles.buttonGrid}>
					<CustomButton buttonText='Complete My Loan Application' clickHandler={moveUserBasedOnStatus} />
				</Grid>
			</Grid>
		</>
	);
}

export const HomeScreenLoader = () => {
	return (
		<Box className={Styles.homeScreenLoader}>
			<ThemeProvider theme={CustomTheme}>
				<CircularProgress id='circularProgress' size={50} />
			</ThemeProvider>
		</Box>
	);
};

export default HocWrapper(Home);
