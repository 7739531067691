import React from 'react';
import Slider from '@mui/material/Slider';

const marks = [
	{
		value: 1000,
	},
	{
		value: 2000,
	},
	{
		value: 3000,
	},
	{
		value: 4000,
	},
	{
		value: 5000,
	},
];

const overrideDefaultStyles = {
	mark: {
		style: {
			// this will remove the dots in the rails
			background: 'transparent',
		},
	},
	rail: {
		style: {
			backgroundColor: '#C3CEEB',
			boxShadow: 'inset 0 3px 6px #00000029',
			opacity: 1,
			height: '5px',
		},
	},
	track: {
		style: {
			backgroundColor: '#FFFFFF',
			border: 'none',
		},
	},
	thumb: {
		style: {
			width: '23px',
			height: '23px',
			background:
				'radial-gradient(circle, rgba(31,182,66,1) 38%, rgba(255,255,255,1) 39%, rgba(255,255,255,1) 100%)',
			boxShadow: 'none',
		},
	},
};

const CustomSlider = ({ ...props }) => <Slider {...props} step={null} slotProps={overrideDefaultStyles} />;

export default CustomSlider;
