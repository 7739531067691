import React, { useEffect } from 'react';
import Registration from '../Layout/Registration';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomButton from '../HtmlElements/CustomButton';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getUserStatus } from '../../Containers/HomeScreen/HomeScreenSlice';

// RTK query
import { useGetRefContactStatusQuery, useUpdateDemoUserStatusMutation } from '../../Services/apiSlice';

// img
import Emoji from '../../Assets/Images/logo-congrats.png';

// Styles
import Styles from './Congratulations.module.scss';

// icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// routes
import getRoute from '../../Utils/GetRoute';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

import callRefreshToken from '../../Utils/RefreshToken';

const RenderImg = () => <img src={Emoji} className={Styles.headerEmoji} alt='congrats' />;

const CongratsText = () => (
	<Grid className={Styles.congratsTxtContainer}>
		<Typography color='white' component='div' variant='body1' fontSize='28px'>
			Congratulations
		</Typography>
	</Grid>
);

const Congratulations = props => {
	let { primary, textColor } = props.theme.palette;
	const { handle_android_back_button, check_internet_connection, show_toast } = props;

	const dispatch = useDispatch();

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const usrStatus = useSelector(state => state.homeScreenDetails?.userStatus);
	const isLoading = useSelector(state => state.homeScreenDetails?.isLoading);

	const {
		data: refContactStat,
		refetch: refetchRefContactStatus,
		isFetching,
	} = useGetRefContactStatusQuery();
	const [updateUsrStatus] = useUpdateDemoUserStatusMutation();

	const referenceContactStatus = refContactStat?.result?.reference_contact_status;

	const homeApiStatus = useSelector(state => state.homeScreenDetails?.error);

	const progress = {
		0: usrStatus === 'basic_info_pending' ? false : true,
		1: usrStatus === 'income_info_pending' || usrStatus === 'basic_info_pending' ? false : true,
		2:
			usrStatus === 'income_info_pending' ||
			usrStatus === 'address_info_pending' ||
			usrStatus === 'basic_info_pending'
				? false
				: true,
		3:
			usrStatus === 'basic_info_pending' ||
			usrStatus === 'income_info_pending' ||
			usrStatus === 'address_info_pending' ||
			usrStatus === 'doc_upload_pending' ||
			usrStatus === 'vkyc_pending'
				? false
				: true,
		4:
			usrStatus === 'basic_info_pending' ||
			usrStatus === 'income_info_pending' ||
			usrStatus === 'address_info_pending' ||
			usrStatus === 'doc_upload_pending' ||
			usrStatus === 'vkyc_pending' ||
			usrStatus === 'selfie_upload_pending'
				? false
				: true,
	};

	const progressList = [
		'PAN and Basic Details',
		'Income Information',
		'Address Information',
		'KYC',
		'Selfie Upload',
	];

	useEffect(() => {
		if (props.check_internet_connection()) {
			dispatch(getUserStatus());
		}
	}, [referenceContactStatus]);

	useEffect(() => {
		if (homeApiStatus === 401) {
			(async function refreshToken() {
				try {
					const response = await callRefreshToken();
					if (response) {
						if (check_internet_connection()) {
							dispatch(getUserStatus());
						}
					}
				} catch {}
			})();
		}
	}, [homeApiStatus]);

	useEffect(() => {
		if (props.check_internet_connection()) {
			refetchRefContactStatus();
		}
	}, []);

	const handleAndroidBackButton = () => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: 'basic_info_pending',
						}).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('closeApp');
						} else {
							show_toast('Something went wrong!');
						}
					} catch (err) {
						show_toast(err?.data?.message);
					}
				})();
			}
		} else {
			props.navigate('/home', true);
		}
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	const handleContinueButton = () => {
		if (isDemoEnabled) {
			if (usrStatus === 'reference_contact_info_pending') {
				props.navigate('/reference_contact_family', true);
			} else {
				if (props.check_internet_connection()) {
					props.navigate(getRoute(usrStatus, referenceContactStatus), true);
				}
			}
		} else {
			if (props.check_internet_connection()) {
				props.navigate(getRoute(usrStatus, referenceContactStatus), true);
			}
		}
	};

	return (
		<React.Fragment>
			<Registration heading={<RenderImg />} subheading={<CongratsText />}>
				<Grid>
					<Grid marginBottom='70px'>
						<Typography fontSize='14px'>
							Please complete following steps to avail loan amount. It helps us to provide you customise loan.
						</Typography>
					</Grid>

					{isLoading || isFetching ? (
						<Typography variant='body1' textAlign='center'>
							Please wait...
						</Typography>
					) : (
						<React.Fragment>
							<Grid height='255px' container>
								<Grid item xs={8} md={8}>
									<Typography component='ol' padding='0' marginLeft='20px'>
										{progressList.map((item, idx, arr) => {
											return (
												<Typography
													key={item}
													marginTop={idx === 0 ? '0' : '30px'}
													component='li'
													fontWeight='600'
													color={progress[idx] ? primary.main : textColor.greyShade2}>
													{item}
												</Typography>
											);
										})}
									</Typography>
								</Grid>

								<Grid item xs={4} md={4}>
									{progressList.map((_, idx) => (
										<Grid key={idx} textAlign='right' marginTop={idx === 0 ? '0' : '30px'}>
											<CheckCircleIcon
												variant={progress[idx] ? 'validCheck' : 'invalidCheck'}
												fontSize='20px'
											/>
										</Grid>
									))}
								</Grid>
							</Grid>

							<Grid marginTop='70px'>
								<CustomButton buttonText='Continue' clickHandler={handleContinueButton} />
							</Grid>
						</React.Fragment>
					)}
				</Grid>
			</Registration>
		</React.Fragment>
	);
};

export default HocWrapper(Congratulations);
