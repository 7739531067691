import * as Yup from 'yup';
import { onlyDigits, acceptAlphabetsAndNumbersandSpace, uanNumber, ifscCode, upiId } from '../Utils/Regex';

export const basicInfoSchema = Yup.object({
	annualIncome: Yup.string()
		.matches(onlyDigits, 'Only digits allowed')
		.test({
			skipAbsent: true,
			test(value, ctx) {
				if (+value < 180000) {
					return ctx.createError({ message: 'annual income should be minimum 1,80,000 P.A' });
				}
				if (+value > 9999999) {
					return ctx.createError({ message: 'annual income should not be greater than 99,99,999 P.A' });
				}
				return true;
			},
		}),
	companyName: Yup.string()
		.matches(acceptAlphabetsAndNumbersandSpace, 'Special characters are not allowed')
		.test({
			skipAbsent: true,
			test(value, ctx) {
				const splittedValue = value.split(' ');
				const result = splittedValue
					.map(val => val.replace(' ', ''))
					.filter(item => item !== '')
					.join('');
				if (result.trim().length < 3) {
					return ctx.createError({ message: 'Minimum 3 letters required' });
				}
				return true;
			},
		})
		.max(50, 'Maximum 50 characters is allowed'),
	uanNumber: Yup.string().matches(uanNumber, 'Enter a valid UAN Number'),
	bankAccountNumber: Yup.string().matches(onlyDigits, 'Only digits allowed'),
	ifscCode: Yup.string().matches(ifscCode, 'Enter a valid IFSC Code'),
	upiId: Yup.string().matches(upiId, 'Enter a valid UPI id'),
});
