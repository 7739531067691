import React, { useState, useEffect } from 'react';
import { CustomTheme } from '../AddBank/ComponentStyles';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomCheckbox from '../../Components/HtmlElements/CustomCheckbox';
import CustomHeader from '../../Components/Layout/CustomHeader';
import CustomLoader from '../../Components/Loader/CustomLoader';
import ConfirmationPopUp from '../ConfirmationPopUp/ConfirmationPopUp';

// MUI related imports
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';

// PDF
import PDFRenderer from '../PDFRenderer/PDFRenderer';

// Stylesheet
import Styles from './SanctionLetter.module.scss';

// LocalstorageValues
import { GetSavedLoanId, GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

// RTK Query
import { useGetEsignAgreementDataMutation, useUpdateDemoUserStatusMutation } from '../../Services/apiSlice';

// Enum
import { ROUTE, USER_STATUS } from '../../Utils/Enum';

function SanctionLetter(props) {
	const {
		theme,
		navigate,
		handle_android_back_button,
		check_internet_connection,
		show_toast,
		get_previous_props,
	} = props;

	const [checked, setChecked] = useState(false);
	const [pdfHasLoaded, setPadfHasLoaded] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const [fetchEsignData, { isLoading: fetchingEsignData }] = useGetEsignAgreementDataMutation();
	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();

	const prevProps = get_previous_props();
	const presignedSanctionURL = prevProps?.presignedURL;
	const bankAccountId = prevProps?.bankAccId;

	const handleCheckBoxChange = () => setChecked(prevState => !prevState);

	const handlePDFLoaded = pageNum => {
		if (pageNum !== null) {
			setPadfHasLoaded(true);
		}
	};

	const handleAndroidBackButton = () => setIsConfirmModalOpen(true);

	// modal related methods
	const handleConfirmModalOkay = () => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.LOAN_ELIGIBILITY_SUCCESS,
						}).unwrap();

						if (response?.status === 200) {
							navigate('/' + ROUTE.PRODUCT, true);
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			handle_android_back_button('goBack');
		}
	};
	const handleConfirmModalCancel = () => setIsConfirmModalOpen(false);

	const handleOnContinue = () => {
		const userLoanId = GetSavedLoanId();

		if (userLoanId) {
			if (check_internet_connection()) {
				(async function getEsignData() {
					try {
						const response = await fetchEsignData({
							loan_id: userLoanId,
							bank_acc_id: bankAccountId,
						}).unwrap();

						if (response?.status === 200) {
							// source can be either otp_esign or digio
							const moveToLoanAgreementScreen = response?.result?.source === 'otp_esign';

							if (moveToLoanAgreementScreen) {
								const loanAgreementPresignedURL = response?.result?.url;
								const bankAccnum = response?.result?.bank_details?.account_number;
								const bankName = response?.result?.bank_details?.bank_name;
								const docId = response?.result?.doc_id;

								if (loanAgreementPresignedURL) {
									navigate('/' + ROUTE.ESIGN_AGREEMENT, true, {
										loanAgreementPresignedURL,
										bankAccnum,
										bankName,
										docId,
									});
								} else {
									console.error('loan agreement pre-signed URL is not exist.');
								}
							}
						} else {
							show_toast(response?.message, 'error');
						}
					} catch {
						console.error('Exception occured while fetching loanAgreement');
					}
				})();
			}
		} else {
			console.error('user loanId is not available to make API call!');
		}
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		if (isDemoEnabled) {
			setChecked(true);
		}
	}, []);

	return (
		<React.Fragment>
			<CustomHeader
				header='Sanction Letter'
				style={{ padding: 12 }}
				handleBackButton={handleAndroidBackButton}>
				<ConfirmationPopUp
					message='Do you want to cancel the Sanction Process?'
					showModal={isConfirmModalOpen}
					handleConfirmModalOkay={handleConfirmModalOkay}
					handleConfirmModalCancel={handleConfirmModalCancel}
				/>
				<FormControl className={Styles.formControl}>
					<React.Fragment>
						{fetchingEsignData || updatingUsrStatus ? <CustomLoader /> : ''}

						<Grid container direction='column' rowGap='20px'>
							<Grid className={Styles.esignAgreement}>
								<PDFRenderer URL={presignedSanctionURL} isPdfHasLoaded={handlePDFLoaded} />
							</Grid>
						</Grid>

						{pdfHasLoaded && (
							<Grid container direction='row' className={Styles.PermissionConsent}>
								<Grid>
									<ThemeProvider theme={CustomTheme}>
										<CustomCheckbox
											id='primaryAccountConsent'
											className={Styles.CustomCheckbox}
											checked={checked}
											onChangeHanlder={handleCheckBoxChange}
										/>
									</ThemeProvider>
								</Grid>
								<Grid>
									<Typography
										variant='body1'
										fontSize='11px'
										color={theme.palette.textColor.darkBlack}
										textAlign='justify'>
										I agree to the{' '}
										<a>
											<b>terms and conditions</b>
										</a>{' '}
										mentioned in the sanction letter. We will also send the sanction letter to your registered
										E-mail ID.
									</Typography>
								</Grid>
							</Grid>
						)}

						{pdfHasLoaded && (
							<Grid>
								<CustomButton
									onClick={handleOnContinue}
									buttonText='Continue'
									disabled={isDemoEnabled ? false : !checked}
								/>
							</Grid>
						)}
					</React.Fragment>
				</FormControl>
			</CustomHeader>
		</React.Fragment>
	);
}

export default HocWrapper(SanctionLetter);
