import { USER_STATUS, ROUTE, REF_CONTACT_STATUS, LOAN_STATUS } from './Enum';

const getRoute = (userStatus, from) => {
	// value for from can also be loan status

	if (userStatus === USER_STATUS.LOGIN) return '/' + ROUTE.LOGIN;
	else if (userStatus === USER_STATUS.OTP_VERIFICATION_PENDING)
		return from === 'login'
			? '/' + ROUTE.LOGIN_OTP
			: from === 'signup'
			? '/' + ROUTE.SIGNUP_OTP
			: from === 'forgot'
			? '/' + ROUTE.FORGOT_PASSWORD_OTP
			: '';
	else if (userStatus === USER_STATUS.MOBILE_VERIFICATION_PENDING) return '/' + ROUTE.MOBILE_VERIFICATION;
	else if (userStatus === USER_STATUS.PASSWORD_UPDATE_PENDING) return '/' + ROUTE.CREATE_PASSWORD;
	else if (userStatus === USER_STATUS.PASSWORD_RESET_PENDING) return '/' + ROUTE.RESET_PASSWORD;
	else if (userStatus === USER_STATUS.BASIC_INFO_PENDING) return '/' + ROUTE.BASIC_INFO;
	else if (userStatus === USER_STATUS.ON_HOLD) return '/' + ROUTE.ON_HOLD;
	else if (
		userStatus === USER_STATUS.PAN_CHECK_FAILED ||
		userStatus === USER_STATUS.ELIGIBILITY_CHECK_FAILED ||
		userStatus === USER_STATUS.LOAN_ELIGIBILITY_FAILED
	)
		return '/' + ROUTE.PAN_CHECK_FAIL;
	else if (userStatus === USER_STATUS.INCOME_INFO_PENDING) return '/' + ROUTE.INCOME_INFO;
	else if (
		userStatus === USER_STATUS.PAN_CHECK_PENDING ||
		userStatus === USER_STATUS.ELIGIBILITY_CHECK_PENDING ||
		userStatus === USER_STATUS.ELIGIBILITY_CHECK_INPROGRESS
	)
		return '/' + ROUTE.ELIGIBILITY_CHECK_INPROGRESS;
	else if (userStatus === USER_STATUS.ELIGIBILITY_CHECK_MANUAL)
		return '/' + ROUTE.MANUAL_VERIFICATION_INPROGRESS;
	else if (
		userStatus === USER_STATUS.LOAN_ELIGIBILITY_INPROGRESS ||
		userStatus === USER_STATUS.LOAN_ELIGIBILITY_PENDING
	)
		return '/' + ROUTE.LOAN_ELIGIBILITY_INPROGRESS;
	else if (
		userStatus === USER_STATUS.KYC_VERIFICATION_PENDING ||
		userStatus === USER_STATUS.KYC_VERIFICATION_INPROGRESS
	)
		return '/' + ROUTE.KYC_CHECK_INPROGRESS;
	else if (userStatus === USER_STATUS.KYC_VERIFICATION_MANUAL) return '/' + ROUTE.KYC_MANUAL_VERIFICATION;
	else if (
		userStatus === USER_STATUS.SELFIE_CHECK_INPROGRESS ||
		userStatus === USER_STATUS.SELFIE_VERIFICATION_PENDING ||
		userStatus === USER_STATUS.SELFIE_VERIFICATION_INPROGRESS
	)
		return '/' + ROUTE.SELFIE_CHECK_INPROGRESS;
	else if (userStatus === USER_STATUS.SELFIE_VERIFICATION_MANUAL)
		return '/' + ROUTE.SELFIE_MANUAL_VERIFICATION;
	else if (
		userStatus === USER_STATUS.KYC_VERIFICATION_FAILED ||
		userStatus === USER_STATUS.SELFIE_VERIFICATION_FAILED
	)
		return '/' + ROUTE.KYC_CHECK_FAIL;
	else if (userStatus === USER_STATUS.REFERENCE_CONTACT_INFO_PENDING) {
		return from === REF_CONTACT_STATUS.FAMILY_REFERENCE_PENDING
			? '/' + ROUTE.REFERENCE_CONTACT_FAMILY
			: from === REF_CONTACT_STATUS.FAMILY_REFERENCE_OTP_VERIFICATION_PENDING
			? '/' + ROUTE.REFERENCE_FAMILY_OTP
			: from === REF_CONTACT_STATUS.FRIEND_REFERENCE_PENDING
			? '/' + ROUTE.REFERENCE_CONTACT_FRIEND
			: from === REF_CONTACT_STATUS.FRIEND_REFERENCE_OTP_VERIFICATION_PENDING
			? '/' + ROUTE.REFERENCE_FRIEND_OTP
			: '';
	} else if (userStatus === USER_STATUS.LOAN_ELIGIBILITY_SUCCESS) {
		return from === LOAN_STATUS.ESIGN_VERIFIED
			? '/' + ROUTE.LOAN_DISBURSAL_INPROGRESS
			: from === LOAN_STATUS.DISBURSAL_INITIATED
			? '/' + ROUTE.LOAN_DISBURSAL_INPROGRESS
			: from === LOAN_STATUS.DISBURSAL_INPROGRESS
			? '/' + ROUTE.LOAN_DISBURSAL_INPROGRESS
			: from === LOAN_STATUS.DISBURSAL_COMPLETED
			? '/' + ROUTE.REPAYMENT_HOME
			: from === LOAN_STATUS.REPAYMENT_PENDING
			? '/' + ROUTE.REPAYMENT_HOME
			: '/' + ROUTE.PRODUCT;
	} else {
		if (userStatus === USER_STATUS.SELFIE_UPLOAD_PENDING) {
			if (localStorage.getItem('_isDemo') === 'true') {
				if (window.Android) {
					window.Android.navigateToAndroid(userStatus);
				}
			} else {
				if (window.Android) {
					if (window.Android.checkCameraPermissionEnabled()) {
						window.Android.navigateToAndroid(userStatus);
					} else {
						return '/' + ROUTE.PERMISSION_FAIL;
					}
				}
			}
		} else {
			if (window.Android) window.Android.navigateToAndroid(userStatus);
		}
	}
};

export default getRoute;
