import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import eligibilityTimer from '../../Assets/Icons/EligibilityTimerIcon.svg';
import Api from '../../Utils/Api';
// Stylesheet
import Styles from './EligibilityCheck.module.scss';

// navigation
import getRoute from '../../Utils/GetRoute';

let interval;
function EligibilityCheckInprogress(props) {
	const { theme, navigate, handle_android_back_button } = props;

	const callHomeApi = () => {
		Api(
			'get',
			'borrower_apk/home/screen/status?v=1000&latitude=12.960680890828371&longitude=77.63665560632944',
		)
			.then(res => {
				if (res) {
					if (res.data && res.data.status === 200) {
						if (res.data.result && res.data.result.user_status) {
							navigate(getRoute(res.data.result.user_status), true);
						}
					}
				}
			})
			.catch(err => console.log(err));
	};

	// useEffect(() => {
	// 	interval = setInterval(() => {
	// 		callHomeApi();
	// 	}, 15000);
	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// }, []);

	const handleAndroidBackButton = () => {
		handle_android_back_button('goBack');
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);

		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={eligibilityTimer} alt='eligibility-timer' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>Eligibility check in progress</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography className={Styles.CardBodyText} color={theme.palette.textColor.darkBlack}>
							We are accessing your loan eligibility. This should not take more than 1 minute.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default HocWrapper(EligibilityCheckInprogress);
