export const AllowOnlyNumeric = value => {
	const regex = /^[0-9\b]+$/;
	return regex.test(value);
};

const acceptAlphabetsAndSpace = /^[a-zA-Z ]*$/;
const panNumber = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
const onlyDigits = /^[0-9\b]+$/;
const acceptAlphabetsAndNumbersandSpace = /^[a-zA-Z0-9 ]*$/;
const uanNumber = /^[0-9\b]{12}$/;
const ifscCode = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
const acceptAlphabetsAndNumbersNoSpace = /^[a-zA-Z0-9]*$/;
const upiId = /^[a-zA-Z0-9@]*$/;
const acceptNoSpaceAtBeginning = /^(?![\s-])[\w\s-]+$/;
const emailWithNoSpace = /^[a-zA-Z0-9@._-]*$/;
const passwordRegex = /^[a-zA-Z0-9`~!@$#%^&*()_+-=|{}:'"><.?/]*$/;
const acceptWhiteSpaceWithNoSpaceAtBeginning = /^\S(?:.*\S)?$/;

export {
	acceptAlphabetsAndSpace,
	panNumber,
	onlyDigits,
	acceptAlphabetsAndNumbersandSpace,
	uanNumber,
	ifscCode,
	acceptAlphabetsAndNumbersNoSpace,
	upiId,
	acceptNoSpaceAtBeginning,
	acceptWhiteSpaceWithNoSpaceAtBeginning,
	emailWithNoSpace,
	passwordRegex,
};
