import { createTheme } from '@mui/material/styles';
import { Theme } from '../../Assets/CustomStyles/MaterialTheme';

export const CustomTheme = createTheme({
	...Theme,
	components: {
		MuiSwitch: {
			styleOverrides: {
				thumb: {
					color: '#fff',
				},
				track: ({ ownerState }) => ({
					...(ownerState.id === 'MobileVerificationSwitch' && {
						'.Mui-checked.Mui-checked + &': {
							backgroundColor: '#1B9136',
							opacity: 1,
						},
					}),
				}),
			},
		},
	},
});
