import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// styles
import Style from './EMISchedule.module.scss';

const EMISchedule = props => {
	const { textColor, background, primary } = props.theme.palette;

	return (
		<Grid className={Style.productDetailsContainer}>
			{props.data &&
				props.data.map(ps => {
					const { schedule_num, due_amount, due_date, schedule_days } = ps;

					return (
						<Grid container padding='8px' key={schedule_num}>
							<Grid
								className={Style.borderEmiDetails}
								item
								xs={8}
								padding='8px'
								backgroundColor={background.primary}>
								<Grid container>
									<Grid item xs={6}>
										<Grid>
											<Grid>
												<Typography variant='body1' fontSize='8px'  fontWeight='600' color={textColor.darkBlack}>
													EMI {schedule_num}
												</Typography>
											</Grid>
											<Grid marginTop='4px'>
												<Typography variant='body1' fontSize='14px'  fontWeight='600' color={primary.main}>
													₹{due_amount}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6}>
										<Grid>
											<Grid>
												<Typography variant='body1' fontSize='8px' color={textColor.darkBlack}>
													Due Date
												</Typography>
											</Grid>
											<Grid marginTop='4px'>
												<Typography variant='body1' fontSize='12px' color={textColor.darkBlack}>
													{due_date}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid className={Style.dueInBorder} item xs={4} padding='8px' backgroundColor='#191919'>
								<Grid textAlign='center'>
									<Grid>
										<Typography variant='body1' fontSize='12px' color={textColor.white}>
											Due in
										</Typography>
									</Grid>
									<Grid marginTop='4px'>
										<Typography variant='body1' fontSize='12px' color={textColor.white}>
											{schedule_days} Days
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					);
				})}
		</Grid>
	);
};

export default HocWrapper(EMISchedule);
