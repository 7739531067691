export const REF_CONTACT_STATUS = Object.freeze({
	ADD_BANK_PENDING: 'add_bank_pending',
	FAMILY_REFERENCE_PENDING: 'family_reference_pending',
	FAMILY_REFERENCE_OTP_VERIFICATION_PENDING: 'family_reference_otp_verification_pending',
	FRIEND_REFERENCE_PENDING: 'friend_reference_pending',
	FRIEND_REFERENCE_OTP_VERIFICATION_PENDING: 'friend_reference_otp_verification_pending',
	REFERENCE_CONTACT_COMPLETED: 'reference_contact_completed',
	VKYC_PENDING: 'vkyc_pending_screen',
	VKYC_WAITING: 'vkyc_waiting_screen',
	LOAN_ELIGIBILITY_FAILED_SCREEN: 'loan_eligibility_failed_screen',
	LOAN_ELIGIBILITY_MANUAL_SCREEN: 'loan_eligibility_manual_screen',
	KYC_MANUAL_SCREEN: 'kyc_manual_screen',
});
