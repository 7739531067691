import { createTheme } from '@mui/material/styles';
export const Theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#3a2960',
		},
		secondary: {
			main: '#1d2a78',
		},
		background: {
			primary: '#fff',
			secondary: '#F2F2F2',
		},
		textColor: {
			white: '#fff',
			darkBlack: '#191919',
			grey: '#696969',
			greyShade1: '#CCCCCC',
			greyShade2: '#AFAFAF',
			green: '#1B9136',
		},
		error: {
			main: '#D15757',
		},
		warning: {
			main: '#dc863b',
		},
		info: {
			main: '#3a2960',
		},
		success: {
			main: '#2b8130',
		},
		divider: 'rgba(18,18,18,0.12)',
		done: {
			main: '#1B9136',
		},
	},
	typography: {
		fontFamily: 'Poppins',
		body1: {
			lineHeight: 1.2,
			fontSize: '16px',
		},
		body2: {
			lineHeight: 1.2,
			fontSize: '16px',
		},
		passwordRules: {
			lineHeight: 1.2,
			fontSize: '14px',
			fontWeight: '500',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '25px',
					fontWeight: '500',
					textTransform: 'none',
					fontFamily: 'Poppins',
					fontSize: '16px',
					':disabled': {
						backgroundColor: '#696969',
						color: '#fff',
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					width: '1em',
					fontWeight: '600',
				},
			},
			variants: [
				{
					props: { variant: 'validCheck' },
					style: {
						color: '#1B9136',
					},
				},
				{
					props: { variant: 'invalidCheck' },
					style: {
						color: '#AFAFAF',
					},
				},
			],
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					color: '#AFAFAF',
					fontSize: '16px',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					letterSpacing: '0.2px',
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.id === 'withIcon' && {
						marginLeft: '4px',
					}),
				}),
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					// reset default grey color background on focus.
					backgroundColor: 'transparent',
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				color: 'red',
				fontSize: '12px',
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					maxHeight: '200px',
				},
			},
		},
		MuiDatePickerToolbar: {
			styleOverrides: {
				title: {
					color: 'background',
				},
			},
		},
		MuiGrid: {
			styleOverrides: {
				root: {
					fontFamily: 'Poppins',
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderWidth: '1px',
					borderColor: '#cccccc',
				},
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					opacity: '0.65',
				},
			},
		},
	},
});
