import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Registration from '../../Components/Layout/Registration';
import WhatsappIcon from '../../Assets/Icons/WhatsappIcon.svg';
import CustomCheckbox from '../../Components/HtmlElements/CustomCheckbox';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomNumberInput from '../../Components/HtmlElements/CustomNumberInput';
import { CustomTheme } from './ComponentStyles';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomLoader from '../../Components/Loader/CustomLoader';
import FormControl from '@mui/material/FormControl';
// styles
import Styles from './MobileNumber.module.scss';
// API Services
import { useSendOtpMutation } from '../../Services/apiSlice';

// formik
import { useFormik } from 'formik';
import { mobileNumberInfoSchema } from '../../FormikSchema/MobileNumberInfo';

// LocalStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

function MobileNumber(props) {
	const {
		theme,
		navigate,
		check_internet_connection,
		show_toast,
		get_previous_props,
		handle_android_back_button,
	} = props;
	const previousScreenState = get_previous_props();

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const [checked, setChecked] = useState(false);
	const [switchChecked, setSwitchChecked] = useState(false);
	const [aadharChecked, setAadharChecked] = useState(false);
	const [otpSignupId, setOtpSignupId] = useState();

	const errorColor = theme.palette.error.main;

	const [postMobileNumber, { isLoading }] = useSendOtpMutation();

	const formik = useFormik({
		initialValues: {
			mobileNumber: '',
		},
		validationSchema: mobileNumberInfoSchema,
	});
	const { mobileNumber } = formik.errors;

	const disableSendOtpBtn = checked && formik.values.mobileNumber.length === 12 && formik.isValid;

	const handleSwitchChange = event => {
		setSwitchChecked(event.target.checked);
	};

	const handleChange = event => {
		setChecked(event.target.checked);
	};
	const checkBoxForAadharLinkHandler = event => {
		setAadharChecked(event.target.checked);
	};

	const openURL = url => {
		if (window.Android) {
			window.Android.loadWebViewURL(url);
		}
	};

	const handleAndroidBackButton = () => {
		handle_android_back_button('closeApp');
	};

	const submitHandler = e => {
		e.preventDefault();
		if (check_internet_connection()) {
			postMobileNumber({ mobile_number: formik.values.mobileNumber.split('-').join('') })
				.unwrap()
				.then(success => {
					if (success && success.status && success.status === 200) {
						setOtpSignupId(success && success.result && success.result.otp_signup_id);
					} else {
						show_toast(success && success.message);
					}
				})
				.catch(err => {
					let errorMsg = err && err.data && err.data && err.data.message;
					errorMsg && show_toast(errorMsg);
				});
		}
	};

	useEffect(() => {
		if (isDemoEnabled) {
			formik.setFieldValue('mobileNumber', '9999966666');
			setAadharChecked(true);
			setChecked(true);
		} else {
			if (previousScreenState && previousScreenState.mobileNumber) {
				formik.setFieldValue('mobileNumber', previousScreenState.mobileNumber);
			}
		}
	}, []);

	useEffect(() => {
		if (otpSignupId) {
			let whatsappConsentCheck = switchChecked ? 1 : 0;
			let isMobileNumberLinkedWithAadhar = aadharChecked ? 1 : 0;
			if (check_internet_connection()) {
				navigate('/signup_otp', true, {
					is_mobile_no_linked_with_aadhar: isMobileNumberLinkedWithAadhar,
					whatsapp_consent: whatsappConsentCheck,
					otp_signup_id: otpSignupId,
					mobileNumber: formik.values.mobileNumber.split('-').join(''),
				});
			}
		}
	}, [otpSignupId]);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<>
			{isLoading && <CustomLoader />}
			<FormControl component='form' onSubmit={submitHandler}>
				<Registration
					heading='Verify Mobile Number'
					subheading='Provide a valid number associated with your Aadhaar or PAN Card.'
					minHeight='550px'
					justifyContent='space-between'>
					<Grid container direction='column' className={Styles.FirstMainDiv}>
						<Grid container className={Styles.InputBoxContainer}>
							<Grid className={Styles.MobileNumAddon} color={CustomTheme.palette.textColor.darkBlack}>
								+91
							</Grid>
							<Grid className={Styles.InputDiv}>
								<CustomNumberInput
									label='Enter mobile number'
									error={mobileNumber ? true : false}
									maskFormat={'000-000-0000'}
									{...formik.getFieldProps('mobileNumber')}
								/>
							</Grid>
						</Grid>
						{mobileNumber && (
							<Typography className={Styles.errorText} color={errorColor}>
								<em>*Please enter valid mobile number</em>
							</Typography>
						)}

						<Grid container direction='column' rowGap='15px' pt='15px'>
							<Grid container direction='row' className={Styles.checkboxMainDiv}>
								<Grid>
									<CustomCheckbox
										className={Styles.aadharLinkConsentCheckbox}
										checked={aadharChecked}
										onChangeHanlder={checkBoxForAadharLinkHandler}
									/>
								</Grid>
								<Typography
									className={Styles.text}
									variant='body1'
									color={CustomTheme.palette.textColor.darkBlack}>
									This number is linked with my Aadhaar Card
								</Typography>
							</Grid>
							<Grid container direction='row' className={Styles.WatsappUpdateDiv}>
								<img src={WhatsappIcon} alt='whatsapp-icon' />
								<Typography className={Styles.text}>Get updates on my WhatsApp</Typography>
								<Grid>
									<ThemeProvider theme={CustomTheme}>
										<Switch
											checked={switchChecked}
											onChange={handleSwitchChange}
											id='MobileVerificationSwitch'
										/>
									</ThemeProvider>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container direction='column' className={Styles.SecondMainDiv}>
						<Grid container direction='row' className={Styles.PermissionConsent}>
							<Grid>
								<CustomCheckbox
									className={Styles.CustomCheckbox}
									checked={checked}
									onChangeHanlder={handleChange}
								/>
							</Grid>
							<Typography
								variant='body1'
								fontSize={12}
								color={CustomTheme.palette.textColor.darkBlack}
								textAlign='justify'>
								By continuing, you agree to PeopleFront{' '}
								<a
									href='#'
									onClick={() => openURL('https://www.peoplefront.in/StaticPages/termsAndCondition')}>
									Terms and Conditions
								</a>{' '}
								and{' '}
								<a href='#' onClick={() => openURL('https://www.peoplefront.in/StaticPages/Privacypolicy')}>
									Privacy Policies
								</a>{' '}
								and receive communication from PeopleFront via SMS, E-Mail and WhatsApp
							</Typography>
						</Grid>
						<Grid>
							<CustomButton type='submit' buttonText='Send OTP' disabled={!disableSendOtpBtn} />
						</Grid>
					</Grid>
				</Registration>
			</FormControl>
		</>
	);
}

export default HocWrapper(MobileNumber);
