import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import dayjs from 'dayjs';

// MUI-comp
import Grid from '@mui/system/Unstable_Grid/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';

// Local Imports
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import { Button, DropDown, CustomRadio, DatePicker, Input } from '../../Components/HtmlElements';
import CustomLoader from '../../Components/Loader/CustomLoader';

// layout
import CustomCard from '../../Components/Layout/CustomCard';

// SVGs
import Aaadhar from '../../Assets/Icons/AaadharIcon.svg';

// Stylesheet
import Styles from './BasicInfo.module.scss';

// Formik schema
import { basicInfoSchema } from '../../FormikSchema/BasicInfo';

// RTK related imports
import {
	useGetBasicInfoDropDownDataQuery as getDropDownData,
	useUpdateDemoUserStatusMutation,
	useSaveBasicInfoMutation,
} from '../../Services/apiSlice';

// navigation
import getRoute from '../../Utils/GetRoute';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';
import { validateUserInput } from '../../Utils/utilities';

// Enum
import { ROUTE, USER_STATUS } from '../../Utils/Enum';

const cardStyle = { textAlign: 'left' };

const BasicInfo = props => {
	const { textColor, primary, background } = props.theme.palette;

	const isDemoEnabled = GetSavedDemoStatus();

	const { navigate, check_internet_connection, show_toast, handle_android_back_button } = props;

	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

	// Aadhar masking input state
	const [isSelected, setIsSelected] = useState(false);

	const { requiredDataForbasicInfo, httpStatus } = getDropDownData('full', {
		selectFromResult: ({ data, isError, isLoading }) => {
			let profession = data && data.result && data.result.profession;
			let educationalQual = data && data.result && data.result.educationalQual;
			let sourceOfIncome = data && data.result && data.result.sourceOfIncome;

			return {
				requiredDataForbasicInfo: { profession, educationalQual, sourceOfIncome },
				httpStatus: { isError, isLoading },
			};
		},
	});

	const [postBasicInfo, { isLoading: submittingBasicInfo }] = useSaveBasicInfoMutation();
	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();

	const handleConfirmModalCancel = () => setIsConfirmModalOpen(false);

	const handleConfirmModalShow = e => {
		e.preventDefault();
		setIsConfirmModalOpen(true);
	};

	const formik = useFormik({
		initialValues: {
			Gender: '',
			Name: '',
			'PAN Number': '',
			'Date of Birth': null,
			'Last 4-digit Aadhaar': '',
			'Current Address Pincode': '',
			'Permanent Address Pincode': '',
			'Source of Income': '',
			Profession: '',
			Education: '',
		},

		validateOnChange: true,
		validateOnBlur: false,

		validationSchema: isDemoEnabled === 'true' ? null : basicInfoSchema,

		onSubmit: async formValues => {
			let requestBody = {
				pan_name: formValues.Name,
				pan_number: formValues['PAN Number'],
				date_of_birth: dayjs(formValues['Date of Birth']).format('YYYY-MM-DD'),
				masked_aadhaar_num: formValues['Last 4-digit Aadhaar'],
				current_address_pincode: formValues['Current Address Pincode'],
				permanent_pincode: formValues['Permanent Address Pincode'],
				source_of_income: formValues['Source of Income'],
				profession: formValues.Profession,
				education: formValues.Education,
				gender: formValues.Gender === 'Male' ? 'male' : formValues.Gender === 'Female' ? 'female' : 'other',
			};

			try {
				if (check_internet_connection()) {
					const response = await postBasicInfo(requestBody).unwrap();
					if (response?.status === 200) {
						if (check_internet_connection()) {
							navigate(getRoute(response?.result?.user_status || response?.result?.status), true);
						}
					} else show_toast(response?.message);
				}
			} catch (err) {
				show_toast(err?.data?.message);
			}
		},
	});

	let hasUserProvidedAllInputs = Object.values(formik.values).every(formVal => formVal);

	// these values used to display error message below MUI inputs
	const {
		Name,
		'PAN Number': pan_number,
		'Last 4-digit Aadhaar': masked_aadhaar_num,
		'Current Address Pincode': current_address_pincode,
		'Permanent Address Pincode': permanent_pincode,
	} = formik.errors;

	const handleAndroidBackButton = () => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.BASIC_INFO_PENDING,
						}).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('closeApp');
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			navigate('/' + ROUTE.HOME, true);
		}
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);

		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	// prefill data for demoApp
	useEffect(() => {
		if (isDemoEnabled === 'true') {
			formik.setFieldValue('Gender', 'Male');
			formik.setFieldValue('Name', 'DEMO ACCOUNT');
			formik.setFieldValue('PAN Number', 'ABCDE1234F');
			formik.setFieldValue('Date of Birth', dayjs('1997-06-28'));
			formik.setFieldValue('Last 4-digit Aadhaar', '1234');
			formik.setFieldValue('Current Address Pincode', '560008');
			formik.setFieldValue('Permanent Address Pincode', '560008');
			formik.setFieldValue('Source of Income', 194);
			formik.setFieldValue('Profession', 27);
			formik.setFieldValue('Education', 7);
		}
	}, []);

	const confirmModalData = Object.keys(formik.values);

	const blurFuncHandler = () => {
		if (!formik.values['Last 4-digit Aadhaar']) {
			setIsSelected(false);
		}
	};

	return (
		<React.Fragment>
			{httpStatus.isLoading || updatingUsrStatus ? <CustomLoader /> : ''}

			<Grid height='100%' overflow='hidden' backgroundColor={background.secondary}>
				{/* Header */}
				<Grid className={Styles.heading} backgroundColor={primary.main}>
					<Grid marginLeft='15px'>
						<Typography fontSize='22px' color={textColor.white}>
							PAN and Basic Details
						</Typography>
					</Grid>
				</Grid>

				<React.Fragment>
					{/* Body */}
					<FormControl component='form' sx={{ height: '100%', width: '100%' }}>
						<Dialog
							fullScreen
							open={isConfirmModalOpen}
							// paperProps helps here to make modal bg trasparent.
							PaperProps={{
								sx: { backgroundColor: 'rgba(0,0,0,0.5)', width: 'auto', justifyContent: 'center' },
							}}>
							<Grid className={Styles.mainContainer}>
								{submittingBasicInfo && <CustomLoader />}

								<Grid className={Styles.modalHeader}>
									<Grid className={Styles.confirmDetails}>
										<Grid marginLeft='5px'>
											<Typography
												variant='body1'
												fontSize='14px'
												fontWeight='bold'
												color={textColor.darkBlack}>
												Confirm Details
											</Typography>
										</Grid>
									</Grid>
									<Grid marginTop='10px'>
										<Typography variant='body1' fontSize='14px' color={textColor.grey}>
											Please check the data you entered. This Information can not be changed in future.
										</Typography>
									</Grid>
								</Grid>

								<Grid className={Styles.modalBody}>
									<Grid>
										{confirmModalData &&
											confirmModalData.map((data, idx, arr) => {
												let selectedDOB = dayjs(formik.values['Date of Birth']).format('DD-MM-YYYY');

												const findDropDownValue = dropVal => dropVal.value === formik.values[data];

												let professionName =
													requiredDataForbasicInfo?.profession?.find(findDropDownValue)?.name;

												let incomeSrc =
													requiredDataForbasicInfo?.sourceOfIncome?.find(findDropDownValue)?.name;

												let eduQual =
													requiredDataForbasicInfo?.educationalQual?.find(findDropDownValue)?.name;

												return (
													<React.Fragment key={data}>
														<Grid container rowSpacing={4}>
															<Grid xs={5}>
																<Typography variant='body1' fontSize='12px' color={textColor.greyShade2}>
																	{data}
																</Typography>
															</Grid>
															<Grid xs={7}>
																<Typography variant='body1' fontSize='12px' color={textColor.darkBlack}>
																	{data === 'Date of Birth'
																		? selectedDOB
																		: data === 'Profession'
																		? professionName
																		: data === 'Source of Income'
																		? incomeSrc
																		: data === 'Education'
																		? eduQual
																		: data === 'Last 4-digit Aadhaar'
																		? `XXXX-XXXX-${formik.values['Last 4-digit Aadhaar']}`
																		: formik.values[data]}
																</Typography>
															</Grid>
														</Grid>
														{arr.length - 1 !== idx && <Divider className={Styles.divider} />}
													</React.Fragment>
												);
											})}
									</Grid>
								</Grid>
								<Grid className={Styles.modalFooter} container>
									<Grid>
										<Button
											id='cancel-confirm-modal'
											clickHandler={handleConfirmModalCancel}
											buttonText='Edit'
											variant='text'
											sx={{ color: textColor.grey }}
										/>
									</Grid>
									<Grid>
										<Button
											id='basic-info-submit'
											clickHandler={formik.handleSubmit}
											buttonText='Submit'
											type='submit'
											variant='text'
										/>
									</Grid>
								</Grid>
							</Grid>
						</Dialog>

						<Grid className={Styles.body} backgroundColor={background.secondary}>
							{/* Gender */}

							<Grid marginTop='20px'>
								<CustomCard style={cardStyle}>
									<Typography className={Styles.gender}>Select Gender</Typography>

									<Grid>
										<CustomRadio
											{...formik.getFieldProps('Gender')}
											data={[
												{ label: 'Male', value: 'Male' },
												{ label: 'Female', value: 'Female' },
												{ label: 'Other', value: 'Other' },
											]}
										/>
									</Grid>
								</CustomCard>
							</Grid>

							{/* PAN & Aaadhaar */}

							<Grid>
								<CustomCard style={cardStyle}>
									<Grid className={Styles.pan}>
										<Input
											label='Enter Name (As per PAN)'
											className={Styles.firstItem}
											error={Name ? true : false}
											helperText={Name}
											value={formik.values.Name}
											onChangeHandler={e =>
												validateUserInput(e.target.value.toUpperCase(), {
													inputName: 'Name',
													setterFun: formik.setFieldValue,
													validate: 'user-name',
												})
											}
										/>
										<Input
											label='PAN Number'
											value={formik.values['PAN Number']}
											onChangeHandler={e =>
												validateUserInput(e.target.value, {
													inputName: 'PAN Number',
													setterFun: formik.setFieldValue,
													validate: 'only-numeric',
												})
											}
											maxLength={10}
											error={pan_number ? true : false}
											helperText={pan_number}
										/>
										<DatePicker
											label='Date of Birth (As per PAN)'
											value={formik.values['Date of Birth']}
											onChange={value => formik.setFieldValue('Date of Birth', value)}
										/>
										<Grid position='relative'>
											<Input
												mode='numeric'
												maxLength={4}
												className={Styles.lastItem}
												label='Enter last 4-digit of Your Aadhaar'
												value={formik.values['Last 4-digit Aadhaar']}
												onChangeHandler={e =>
													validateUserInput(e.target.value, {
														inputName: 'Last 4-digit Aadhaar',
														setterFun: formik.setFieldValue,
														validate: 'only-numeric',
													})
												}
												error={masked_aadhaar_num ? true : false}
												helperText={masked_aadhaar_num}
												onFocus={e => setIsSelected(true)}
												onBlur={blurFuncHandler}
												InputProps={
													isSelected || isDemoEnabled
														? {
																startAdornment: (
																	<InputAdornment sx={{ marginRight: 0 }} position='start'>
																		<Typography marginBottom='4px' color={textColor.darkBlack}>
																			XXXX-XXXX-
																		</Typography>
																	</InputAdornment>
																),
														  }
														: ''
												}
											/>{' '}
											<img src={Aaadhar} alt='aadhaar' className={Styles.aadharImg} />
										</Grid>
									</Grid>
								</CustomCard>
							</Grid>

							{/* Pin code */}

							<Grid>
								<CustomCard style={cardStyle}>
									<Grid className={Styles.pincode}>
										<Input
											mode='numeric'
											label='Current Address Pincode'
											maxLength={6}
											className={Styles.firstItem}
											value={formik.values['Current Address Pincode']}
											onChangeHandler={e =>
												validateUserInput(e.target.value, {
													inputName: 'Current Address Pincode',
													setterFun: formik.setFieldValue,
													validate: 'only-numeric',
												})
											}
											error={current_address_pincode ? true : false}
											helperText={current_address_pincode}
										/>
										<Input
											mode='numeric'
											label='Permanent Address Pincode'
											maxLength={6}
											value={formik.values['Permanent Address Pincode']}
											onChangeHandler={e =>
												validateUserInput(e.target.value, {
													inputName: 'Permanent Address Pincode',
													setterFun: formik.setFieldValue,
													validate: 'only-numeric',
												})
											}
											className={Styles.lastItem}
											error={permanent_pincode ? true : false}
											helperText={permanent_pincode}
										/>
									</Grid>
								</CustomCard>
							</Grid>

							{/* Source of Income, Profession & Eduction select */}

							<Grid>
								<CustomCard style={cardStyle}>
									<Grid className={Styles.sourceofIncome}>
										{/* Source of Income */}

										<DropDown
											label='Select Source of Income'
											className={Styles.firstItem}
											{...formik.getFieldProps('Source of Income')}>
											{requiredDataForbasicInfo &&
												requiredDataForbasicInfo.sourceOfIncome &&
												requiredDataForbasicInfo.sourceOfIncome.map(item => (
													<MenuItem key={item.value} value={item.value} disabled={!item.value}>
														{item.name}
													</MenuItem>
												))}
										</DropDown>

										{/* Profression */}

										<DropDown label='Select Profession' {...formik.getFieldProps('Profession')}>
											{requiredDataForbasicInfo &&
												requiredDataForbasicInfo.profession &&
												requiredDataForbasicInfo.profession.map(item => (
													<MenuItem key={item.value} value={item.value} disabled={!item.value}>
														{item.name}
													</MenuItem>
												))}
										</DropDown>

										{/* Education */}

										<DropDown
											label='Select Education'
											className={Styles.lastItem}
											{...formik.getFieldProps('Education')}>
											{requiredDataForbasicInfo &&
												requiredDataForbasicInfo.educationalQual &&
												requiredDataForbasicInfo.educationalQual.map(item => (
													<MenuItem key={item.value} value={item.value} disabled={!item.value}>
														{item.name}
													</MenuItem>
												))}
										</DropDown>
									</Grid>
								</CustomCard>
							</Grid>
						</Grid>

						{/* Footer */}
						<Grid className={Styles.footer} backgroundColor={background.secondary}>
							<Button
								type='submit'
								id='basic-info-show-confirm-modal'
								clickHandler={handleConfirmModalShow}
								buttonText='Save and Continue'
								disabled={isDemoEnabled === 'true' ? false : !hasUserProvidedAllInputs || !formik.isValid}
							/>
						</Grid>
					</FormControl>
				</React.Fragment>
			</Grid>
		</React.Fragment>
	);
};

export default HocWrapper(BasicInfo);
