import React, { useEffect, useState } from 'react';
import Registration from '../../Components/Layout/Registration';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CustomInput from '../../Components/HtmlElements/CustomInput';
import { IconsList } from '../../Assets/PathIcons/PathIcons';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail } from './ForgotPasswordSlice';
import CustomLoader from '../../Components/Loader/CustomLoader';
import { resetForgotPasswordState } from '../ForgotPassword/ForgotPasswordSlice';
import FormControl from '@mui/material/FormControl';
// Styling Files
import Styles from './ForgotPassword.module.scss';

// localStorageValues
import { SaveDemoStatus } from '../../Utils/LocalStorageValues';

import { emailWithNoSpace } from '../../Utils/Regex';

function ForgotPassword(props) {
	const { theme, navigate, show_toast, check_internet_connection, handle_android_back_button } = props;
	const errorColor = theme.palette.error.main;
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(false);
	const isLoading = useSelector(state => state.forgotPasswordDetails.isLoading);
	const verifyEmailDetails = useSelector(state => state.forgotPasswordDetails.verifyEmailDetails);

	const onChangeHandler = event => {
		let userEnteredEmail = event.target.value.toLowerCase();

		if (userEnteredEmail === '' || emailWithNoSpace.test(userEnteredEmail)) {
			setEmail(userEnteredEmail);
		}

		if (emailError) {
			setEmailError(false);
		}
	};

	const handleAndroidBackButton = () => {
		handle_android_back_button('goBack');
	};

	const clearTheStore = () => {
		// Here we are clearing the user login details from the redux store
		dispatch(resetForgotPasswordState());
	};

	const validate = e => {
		e.preventDefault();
		if (emailError) {
			return;
		} else {
			if (email?.trim()) {
				if (check_internet_connection()) {
					dispatch(
						verifyEmail({
							email_address: email.trim(),
						}),
					);
				}
			} else {
				setEmailError(true);
			}
		}
	};

	const validateVerifyEmailDetails = () => {
		if (verifyEmailDetails.statusCode === 200) {
			SaveDemoStatus(verifyEmailDetails?.is_demo_user);
			if (verifyEmailDetails.emailOtpId) {
				navigate('/forgot_password_otp', true, {
					emailId: email.trim(),
					emailOtpId: verifyEmailDetails.emailOtpId,
				});
			}
		} else {
			verifyEmailDetails.message && show_toast(verifyEmailDetails.message);
		}
	};

	useEffect(() => {
		if (verifyEmailDetails) {
			validateVerifyEmailDetails();
		}
	}, [verifyEmailDetails]);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			clearTheStore();
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<>
			{isLoading && <CustomLoader />}
			<FormControl component='form' onSubmit={validate}>
				<Registration
					heading='Forgot Password?'
					subheading='Don’t worry, please enter the email associated with your account.'
					direction='column'
					minHeight='550px'
					justifyContent='space-between'>
					<Grid className={Styles.inputGrid}>
						<CustomInput
							label='Enter Registered Email ID'
							withIcon='true'
							Icon={IconsList.MailIcon}
							placeholder='example@gmail.com'
							value={email}
							onChangeHandler={onChangeHandler}
							error={emailError}
						/>
						{emailError && (
							<Typography className={Styles.errorText} color={errorColor}>
								<em>*Please enter your email address</em>
							</Typography>
						)}
					</Grid>
					<CustomButton type='submit' buttonText='Verify via OTP' disabled={!email} />
				</Registration>
			</FormControl>
		</>
	);
}

export default HocWrapper(ForgotPassword);
