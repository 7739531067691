import React from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomButton from '../../Components/HtmlElements/CustomButton';

import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

// Stylesheet
import Styles from './ConfirmationPopUp.module.scss';

const ConfirmationPopUp = props => {
	const { theme } = props;

	return (
		<Modal className={Styles.modal} open={props.showModal}>
			<Grid className={Styles.mainContainer}>
				<Grid>
					<Typography color={theme.palette.textColor.darkBlack} fontWeight='bold'>
						{props.message}
					</Typography>
				</Grid>
				<Grid className={Styles.footerButton}>
					<Grid>
						<CustomButton
							clickHandler={props.handleConfirmModalOkay}
							variant='text'
							buttonText='Yes'
							sx={{ color: theme.palette.textColor.grey, fontWeight: '600' }}
						/>
					</Grid>
					<Grid>
						<CustomButton
							clickHandler={props.handleConfirmModalCancel}
							variant='text'
							buttonText='No'
							sx={{ fontWeight: '600' }}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Modal>
	);
};

export default HocWrapper(ConfirmationPopUp);
