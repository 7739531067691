import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CreateSvgIcon from '../../Utils/CreateSvgIcon';

function CustomInput(props) {
	const {
		withIcon,
		fullWidth,
		disabled,
		label,
		error,
		required,
		id,
		multiline,
		minRows,
		maxRows,
		name,
		onChangeHandler,
		placeholder,
		type,
		value,
		Icon,
		IconName,
		autoComplete,
		shrink,
		maxLength,
		...rest
	} = props;
	const InputIcon = CreateSvgIcon(Icon, IconName);
	return (
		<>
			{withIcon ? (
				<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
					{InputIcon && <InputIcon />}
					<TextField
						id='withIcon'
						label={label ? label : 'No Label'}
						placeholder={placeholder ? placeholder : ''}
						size='small'
						name={name ? name : ''}
						type={type ? type : 'text'}
						variant='standard'
						fullWidth={fullWidth ? fullWidth : true}
						disabled={disabled ? disabled : false}
						error={error}
						required={required}
						multiline={multiline ? multiline : false}
						minRows={minRows ? minRows : 3}
						maxRows={maxRows ? maxRows : 5}
						value={value ? value : ''}
						onChange={onChangeHandler ? onChangeHandler : null}
						autoCorrect='false'
						autoSave='false'
						onFocus={rest.onFocus}
						onBlur={rest.onBlur}
						inputProps={{
							autoComplete: 'off',
							maxLength: maxLength ? maxLength : 1000,
							inputMode: rest.mode,
							
						}}
						InputLabelProps={
							shrink && {
								shrink: shrink ? shrink : false,
							}
						}
						{...rest}
					/>
				</Box>
			) : (
				<TextField
					id={id ? id : ''}
					label={label ? label : 'No Label'}
					placeholder={placeholder ? placeholder : ''}
					name={name ? name : ''}
					type={type ? type : 'text'}
					size='small'
					variant='standard'
					fullWidth={fullWidth ? fullWidth : true}
					disabled={disabled ? disabled : false}
					error={error}
					required={required}
					multiline={multiline ? multiline : false}
					minRows={minRows ? minRows : 3}
					maxRows={maxRows ? maxRows : 5}
					value={value ? value : ''}
					onChange={onChangeHandler ? onChangeHandler : null}
					autoCorrect='false'
					autoSave='false'
					onFocus={rest.onFocus}
					onBlur={rest.onBlur}
					inputProps={{
						autoComplete: 'off',
						maxLength: maxLength ? maxLength : 1000,
						inputMode: rest.mode,
					}}
					InputLabelProps={
						shrink && {
							shrink: shrink ? shrink : false,
						}
					}
					{...rest}
				/>
			)}
		</>
	);
}

export default CustomInput;
