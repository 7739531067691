import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CustomButton from '../HtmlElements/CustomButton';
import Slider from 'react-slick';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
// styles
import Styles from './GetStarted.module.scss';
// styles to reset some default carousel classes
import './Carousel.scss';
// react-slick carousel related styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// images
import P2PLOGO from '../../Assets/Icons/P2pLogo.svg';
import CarouselImg from '../../Assets/Icons/CarouselItemIcon.svg';

const GetStarted = props => {
	const { navigate, theme, check_internet_connection, handle_android_back_button } = props;

	const settings = {
		infinite: true,
		dots: true,
		slidesToShow: 1,
		mobileFirst: true,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
	};

	const handleStartBtn = () => {
		if (check_internet_connection()) {
			if (window.Android) {
				let showPostNotificationPermission = true;
				if (window.Android.getAndroidVersionCode() >= 33) {
					showPostNotificationPermission = window.Android.checkPostNotificationPermissionEnabled();
				}
				if (window.Android.checkPermissionsEnabled() && showPostNotificationPermission) {
					navigate('/login', true);
				} else {
					navigate('/permissions', false);
				}
			}
		}
	};

	const handleAndroidBackButton = () => {
		handle_android_back_button('closeApp');
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<React.Fragment>
			{/* CssBaseline used to reset all browser default styles */}
			<CssBaseline />
			<Grid
				className={Styles.GetStartedBtnContainer}
				height={window.innerHeight}
				display='flex'
				justifyContent='space-around'
				flexDirection='column'>
				{/* Heading and welcome text */}
				<Grid>
					<Grid>
						<Grid container justifyContent='center' alignItems='center' columnGap='25px' padding='16px'>
							<Grid>
								<Typography variant='h6' className={Styles.WelcomeTxt}>
									Welcome to
								</Typography>
							</Grid>
							<Grid>
								<img src={P2PLOGO} alt='logo' />
							</Grid>
						</Grid>

						<Grid container className={Styles.HeaderTagline} backgroundColor={theme.palette.primary.main}>
							<Grid item xs={2} paddingLeft='12px'>
								✨
							</Grid>
							<Grid item xs={8} color={theme.palette.textColor.white}>
								<Typography className={Styles.HeaderTaglineTxt} textTransform='uppercase'>
									india’s most trusted peer to peer lending platform
								</Typography>
							</Grid>
							<Grid item xs={2} paddingRight='12px' textAlign='right'>
								✨
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{/* Carousel */}
				<Grid padding='0px 15px'>
					<Slider {...settings}>
						<img alt='slide1' src={CarouselImg} />
						<img alt='slide2' src={CarouselImg} />
						<img alt='slide3' src={CarouselImg} />
					</Slider>
				</Grid>
				{/* Let's Start button */}
				<Grid padding='0px 15px'>
					<CustomButton clickHandler={handleStartBtn} buttonText='Let’s start' />
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default HocWrapper(GetStarted);
