import * as Yup from 'yup';

export const mobileNumberInfoSchema = Yup.object({
	mobileNumber: Yup.string().test({
		skipAbsent: true,
		test(value, ctx) {
			let mobileNumberStartsWithValidation =
				value.startsWith(6) || value.startsWith(7) || value.startsWith(8) || value.startsWith(9);
			if (mobileNumberStartsWithValidation) {
				return value && value.split('-').join('');
			} else {
				return ctx.createError({ message: 'Enter valid mobile number' });
			}
		},
	}),
});
