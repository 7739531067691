import { GetSavedToken, GetRefreshToken } from './LocalStorageValues';

export const tokenConfig = () => {
	const token = GetSavedToken();
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};
	if (token) {
		config.headers['token'] = token;
	}
	return config;
};

export const refreshTokenConfig = () => {
	const refreshToken = GetRefreshToken();

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};
	if (refreshToken) {
		config.headers['refresh_token'] = refreshToken;
	}
	return config;
};
