import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// styles
import Style from './LoanDetails.module.scss';

const LoanDetails = props => {
	const { textColor } = props.theme.palette;

	const {
		ltrf_amt,
		processing_fee,
		agreement_fee,
		monthly_interest_rate_pct,
		interest_amount,
		gst_amount,
		repayment_amount,
	} = props.data;

	return (
		<React.Fragment>
			<Grid padding='12px' className={Style.productDetailsContainer}>
				<Grid container>
					<Grid item xs={6}>
						<Typography fontWeight='600' variant='body1' fontSize='10px' color={textColor.darkBlack}>
							Total Repayment
						</Typography>
					</Grid>
					<Grid item xs={6} textAlign='right'>
						<Typography fontWeight='600' variant='body1' fontSize='12px' color={textColor.darkBlack}>
							₹{repayment_amount}
						</Typography>
					</Grid>
				</Grid>

				<Grid container marginTop='12px'>
					<Grid item xs={6}>
						<Typography variant='body1' fontSize='10px' color={textColor.grey}>
							Lifetime Registration Fee
						</Typography>
					</Grid>
					<Grid item xs={6} textAlign='right'>
						<Typography variant='body1' fontSize='12px' color={textColor.darkBlack}>
							₹{ltrf_amt}
						</Typography>
					</Grid>
				</Grid>

				<Grid container marginTop='12px'>
					<Grid item xs={6}>
						<Typography variant='body1' fontSize='10px' color={textColor.grey}>
							Processing Fee
						</Typography>
					</Grid>
					<Grid item xs={6} textAlign='right'>
						<Typography variant='body1' fontSize='12px' color={textColor.darkBlack}>
							₹{processing_fee}
						</Typography>
					</Grid>
				</Grid>

				<Grid container marginTop='12px'>
					<Grid item xs={6}>
						<Typography variant='body1' fontSize='10px' color={textColor.grey}>
							Loan Agreement Fee
						</Typography>
					</Grid>
					<Grid item xs={6} textAlign='right'>
						<Typography variant='body1' fontSize='12px' color={textColor.darkBlack}>
							₹{agreement_fee}
						</Typography>
					</Grid>
				</Grid>

				<Grid container marginTop='12px'>
					<Grid item xs={6}>
						<Typography variant='body1' fontSize='10px' color={textColor.grey}>
							Interest @{monthly_interest_rate_pct}% monthly
						</Typography>
					</Grid>
					<Grid item xs={6} textAlign='right'>
						<Typography variant='body1' fontSize='12px' color={textColor.darkBlack}>
							₹{interest_amount}
						</Typography>
					</Grid>
				</Grid>

				<Grid container marginTop='12px'>
					<Grid item xs={6}>
						<Typography variant='body1' fontSize='10px' color={textColor.grey}>
							GST Amount
						</Typography>
					</Grid>
					<Grid item xs={6} textAlign='right'>
						<Typography variant='body1' fontSize='12px' color={textColor.darkBlack}>
							₹{gst_amount}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default HocWrapper(LoanDetails);
