import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../Utils/Api';

const GoogleSignupApi =
	'borrower_apk/signup/register/user?v=1000&latitude=12.960680890828371&longitude=77.63665560632944';
const LoginApi =
	'borrower_apk/login/otp/request?v=1000&latitude=12.960680890828371&longitude=77.63665560632944';

export const sendGoogleLoginToken = createAsyncThunk('Login/verifyGoogleLoginTokenApi', async apiBody => {
	return Api('post', GoogleSignupApi, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					data['apiMessage'] = response.data.message;
					if (response.data.result) {
						data['token'] = response.data.result.token;
						data['email'] = response.data.result.email_address;
						data['userStatus'] = response.data.result.status;
						data['is_demo_user'] = response.data.result.is_demo_user;
					}
					data['statusCode'] = response.data.status;
					data['apiResponse'] = response.data;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return err.response.data.message;
			}
			// if (err && err.message) {
			// 	return { apiMessage: err.message };
			// }
		});
});

export const verifyUserLoginDetails = createAsyncThunk('Login/userLoginDetailsApi', async apiBody => {
	return Api('post', LoginApi, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data && response.data.status !== 200) {
					return {
						apiMessage: response.data.message,
						userStatus: response.data.result && response.data.result.status,
						statusCode: response.data.status,
						apiResponse: response.data,
					};
				} else {
					if (response.data.result) {
						return {
							apiMessage: response.data.message,
							mobileNumber: response.data.result.mobile_number,
							mobileToken: response.data.result.mobile_token,
							token: response.data.result.token && response.data.result.token,
							userStatus: response.data.result.status,
							statusCode: response.data.status,
							apiResponse: response.data,
						};
					}
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return {
					apiMessage: err.response.data.message,
				};
			}
		});
});

const initialState = {
	isLoading: false,
	googleSignupDetails: {
		apiResult: '',
		message: '',
		loginToken: '',
		loginEmail: '',
		userStatus: '',
		statusCode: '',
		is_demo_user: '',
	},
	loginDetails: {
		apiResult: '',
		message: '',
		mobileNumber: '',
		mobileToken: '',
		loginToken: '',
		userStatus: '',
		statusCode: '',
	},
};

const loginSlice = createSlice({
	name: 'Login',
	initialState,
	reducers: {
		resetLoginState: (state, action) => {
			state.isLoading = false;
			state.googleSignupDetails = {
				apiResult: '',
				message: '',
				loginToken: '',
				loginEmail: '',
				userStatus: '',
				statusCode: '',
				is_demo_user: '',
			};
			state.loginDetails = {
				apiResult: '',
				message: '',
				mobileNumber: '',
				mobileToken: '',
				loginToken: '',
				userStatus: '',
				statusCode: '',
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(sendGoogleLoginToken.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(sendGoogleLoginToken.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.googleSignupDetails.apiResult = payload.apiResponse;
				state.googleSignupDetails.message = payload.apiMessage;
				state.googleSignupDetails.loginToken = payload.token;
				state.googleSignupDetails.loginEmail = payload.email;
				state.googleSignupDetails.userStatus = payload.userStatus;
				state.googleSignupDetails.statusCode = payload.statusCode;
				state.googleSignupDetails.is_demo_user = payload.is_demo_user;
			}
		});
		builder.addCase(sendGoogleLoginToken.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.googleSignupDetails.message = payload && payload.apiMessage;
		});
		builder.addCase(verifyUserLoginDetails.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(verifyUserLoginDetails.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.loginDetails.message = payload.apiMessage;
				state.loginDetails.mobileNumber = payload.mobileNumber;
				state.loginDetails.mobileToken = payload.mobileToken;
				state.loginDetails.loginToken = payload.token;
				state.loginDetails.userStatus = payload.userStatus;
				state.loginDetails.statusCode = payload.statusCode;
				state.loginDetails.apiResult = payload.apiResponse;
			}
		});
		builder.addCase(verifyUserLoginDetails.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.loginDetails.message = payload && payload.apiMessage;
		});
	},
});

export const { resetLoginState } = loginSlice.actions;
export default loginSlice.reducer;
