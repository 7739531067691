import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Theme } from '../../Assets/CustomStyles/MaterialTheme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// styles
import Styles from './CustomHeader.module.scss';

const CustomHeader = props => {
	const { textColor } = Theme.palette;
	const { header, children, ...rest } = props;
	return (
		<Grid className={Styles.header}>
			<Grid container className={Styles.headerText}>
				<Grid className={Styles.firstItem}>
					<ArrowBackIcon className={Styles.backArrow} onClick={rest.handleBackButton} />
					<Typography color={textColor.white}>{header}</Typography>
				</Grid>
				<Grid>
					<Typography color={textColor.white}>{/* Help? */}</Typography>
				</Grid>
			</Grid>
			<Grid className={Styles.body} {...rest}>
				{children}
			</Grid>
		</Grid>
	);
};
export default CustomHeader;
