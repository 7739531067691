import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ProductHeading from '../Product/ProductHeading';

const SelectBankSkeleton = () => {
	return (
		<React.Fragment>
			<ProductHeading />

			<Grid marginTop='44px'>
				<Typography variant='body1' fontSize='12px'>
					Saved Bank Account
				</Typography>
			</Grid>

			<Grid container width='100%' marginTop='28px' alignItems='center'>
				<Grid item xs={2}>
					<Skeleton variant='circular' width={40} height={40} />
				</Grid>

				<Grid item xs={10}>
					<Typography marginLeft='21px' variant='body1' fontSize='14px' fontWeight='500'>
						<Skeleton animation='wave' sx={{ bgcolor: '#cacaca47', width: '80%' }} />
					</Typography>
				</Grid>
			</Grid>

			<Grid container width='100%' marginTop='28px' alignItems='center'>
				<Grid item xs={2}>
					<Skeleton variant='circular' width={40} height={40} />
				</Grid>

				<Grid item xs={10}>
					<Typography marginLeft='21px' variant='body1' fontSize='14px' fontWeight='500'>
						<Skeleton animation='wave' sx={{ bgcolor: '#cacaca47', width: '80%' }} />
					</Typography>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default SelectBankSkeleton;
