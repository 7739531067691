import React, { useState, useReducer, useEffect } from 'react';
import Registration from '../../Components/Layout/Registration';
import CustomInput from '../../Components/HtmlElements/CustomInput';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomLoader from '../../Components/Loader/CustomLoader';
import { resetLoginState } from '../Login/LoginSlice';
// MUI components & Icons
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconsList } from '../../Assets/PathIcons/PathIcons';
import FormControl from '@mui/material/FormControl';

// styles
import Styles from './CreatePassword.module.scss';

// API Services

import { useCreatePasswordMutation } from '../../Services/apiSlice';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

const { MailIcon, PasswordIcon } = IconsList;

const reducer = (state, action) => {
	switch (action.type) {
		case 'email': {
			return {
				...state,
				email: action.payload,
			};
		}
		case 'pw': {
			return {
				...state,
				// '/\s/g' regex is used to prevent white space in the input.
				password: action.payload && action.payload.replace(/\s/g, ''),
			};
		}
		case 'repw': {
			return {
				...state,
				// '/\s/g' regex is used to prevent white space in the input.
				repw: action.payload && action.payload.replace(/\s/g, ''),
			};
		}
		case 'reset': {
			return { email: '', pw: '', repw: '' };
		}
		case 'prefill': {
			return {
				...state,
				password: 'Demo123@',
				repw: 'Demo123@',
			};
		}
		default: {
			return state;
		}
	}
};

const CreatePassword = props => {
	const { navigate, check_internet_connection, show_toast, handle_android_back_button, get_previous_props } =
		props;
	// data extraction from redux slice
	const previousScreenState = get_previous_props();
	const [state, dispatch] = useReducer(reducer, {
		email: previousScreenState?.emailId,
		password: '',
		repw: '',
	});

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const [showPassword, setShowPassword] = useState(false);
	const [pwRules, setPwRules] = useState([
		{ ruleName: 'Minimum 8 & maximum 16 characters.', reg: /^.{8,16}$/, valid: false },
		{ ruleName: 'Mix of alphanumeric characters.', reg: /[0-9]/, valid: false },
		{ ruleName: 'One uppercase', reg: /(?=.*[A-Z])/, valid: false },
		{ ruleName: 'At least one special character (@, #, $, %)', reg: /(?=.*[!@#$%^&.*])/, valid: false },
	]);

	const passwordRulesForDemo = [
		{ ruleName: 'Minimum 8 & maximum 16 characters.', reg: /^.{8,16}$/, valid: true },
		{ ruleName: 'Mix of alphanumeric characters.', reg: /[0-9]/, valid: true },
		{ ruleName: 'One uppercase', reg: /(?=.*[A-Z])/, valid: true },
		{ ruleName: 'At least one special character (@, #, $, %)', reg: /(?=.*[!@#$%^&.*])/, valid: true },
	];

	/** 'useCreatePasswordMutation' will return 2 things.
	 * - A triggering function used to make POST req.
	 * - An object containing request status like loading
	 */

	const [postUserPassword, { isLoading }] = useCreatePasswordMutation();

	// destructuring required color palette
	const { darkBlack } = props.theme.palette.textColor;

	const handlePasswordVisibility = () => setShowPassword(show => !show);

	const handleUserInput = e => {
		let { name, value } = e.target;
		if (name === 'pw') {
			let passwordRules = [...pwRules];

			passwordRules.forEach(element => {
				if (element.reg.test(value)) {
					element['valid'] = true;
				} else {
					element['valid'] = false;
				}
			});
			setPwRules(passwordRules);
		}
		dispatch({ type: name, payload: value });
	};

	const handleAndroidBackButton = () => {
		handle_android_back_button('closeApp');
	};

	// password and confirm password validation
	const isCnfmPasswrdEqualsPasswrd =
		state.password.length > 0 && state.repw.length > 0 ? state.password === state.repw : false;

	// password rules validation
	const isAllPasswordRulesMatching = pwRules.map(({ valid }) => valid).every(Boolean);

	// 'save and continue btn visibility
	const saveBtnStatus = isCnfmPasswrdEqualsPasswrd && isAllPasswordRulesMatching;

	// create password `handler`
	const handleCreatePassword = e => {
		e.preventDefault();
		let errorMsg = '';
		postUserPassword({ password: state.password })
			// this promise based unwrap method provides raw response/error.
			.unwrap()
			.then(success => {
				if (success && success.status && success.status === 200) {
					if (
						success &&
						success.result &&
						success.result.status &&
						success.result.status === 'mobile_verification_pending'
					) {
						if (check_internet_connection()) {
							dispatch(resetLoginState());
							navigate('/create_password_success', true);
						}
					}
				} else {
					errorMsg = success && success.message;
					show_toast(errorMsg && errorMsg);
				}
			})
			.catch(err => {
				errorMsg = err && err.data && err.data && err.data.message;
				show_toast(errorMsg && errorMsg);
			});
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		if (isDemoEnabled) {
			dispatch({ type: 'prefill' });
		}
	}, []);

	return (
		<>
			{isLoading && <CustomLoader />}
			<Registration
				passwordTheme
				height='565px'
				padding='0 !important'
				backgroundColor='#F2F2F2'
				heading='Create Password'
				subheading='Keep your account safe and secure.'>
				<FormControl component='form' onSubmit={handleCreatePassword}>
					<Grid display='flex' height='565px' flexDirection='column' justifyContent='space-around'>
						<Grid
							className='sample'
							display='flex'
							flexDirection='column'
							rowGap='22px'
							padding='30px 30px 0px 30px'>
							<CustomInput
								id='email'
								disabled
								withIcon
								Icon={MailIcon}
								name='email'
								label='Email ID'
								value={state.email}
								onChange={handleUserInput}
								InputProps={{
									endAdornment: (
										<InputAdornment position='start'>
											{state.email ? <CheckCircleIcon variant='validCheck' fontSize='20px' /> : null}
										</InputAdornment>
									),
								}}
							/>

							<CustomInput
								id='password'
								withIcon
								Icon={PasswordIcon}
								name='pw'
								label='Password'
								type={showPassword ? 'text' : 'password'}
								value={state.password}
								onChange={handleUserInput}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton onClick={handlePasswordVisibility}>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>

							<CustomInput
								id='confirm-password'
								withIcon
								Icon={PasswordIcon}
								name='repw'
								label='Confirm Password'
								value={state.repw}
								onChange={handleUserInput}
								InputProps={{
									endAdornment: isCnfmPasswrdEqualsPasswrd && (
										<InputAdornment position='start'>
											<CheckCircleIcon variant='validCheck' fontSize='20px' />
										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid>
							<Grid className={Styles.passwordContainer}>
								<Typography
									color={darkBlack}
									fontWeight='500'
									variant='body1'
									textAlign='left'
									margin='0 0 10px 4px'>
									Password Rules:
								</Typography>

								<Grid container rowGap='11px'>
									{isDemoEnabled
										? passwordRulesForDemo.map(({ ruleName, valid }, idx) => (
												<Grid item display='flex' alignItems='center' xs={12} key={idx}>
													<CheckCircleIcon variant={valid ? 'validCheck' : 'invalidCheck'} fontSize='20px' />
													<Typography
														color={darkBlack}
														display='inline'
														variant='passwordRules'
														marginLeft='11px'
														textAlign='left'>
														{ruleName}
													</Typography>
												</Grid>
										  ))
										: pwRules.map(({ ruleName, valid }, idx) => (
												<Grid item display='flex' alignItems='center' xs={12} key={idx}>
													<CheckCircleIcon variant={valid ? 'validCheck' : 'invalidCheck'} fontSize='20px' />
													<Typography
														color={darkBlack}
														display='inline'
														variant='passwordRules'
														marginLeft='11px'
														textAlign='left'>
														{ruleName}
													</Typography>
												</Grid>
										  ))}
								</Grid>
							</Grid>
						</Grid>

						<Grid padding='30px'>
							<CustomButton
								type='submit'
								buttonText='Save and Continue'
								disabled={isDemoEnabled ? false : !saveBtnStatus}
							/>
						</Grid>
					</Grid>
				</FormControl>
			</Registration>
		</>
	);
};

export default HocWrapper(CreatePassword);
