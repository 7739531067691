import React from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import eligibilityTimer from '../../Assets/Icons/EligibilityTimerIcon.svg';

//Stylesheet
import Styles from './LoanEligibilityInprogress.module.scss';

const LoanEligibilityInProgress = props => {
	const { theme } = props;

	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={eligibilityTimer} alt='eligibility-timer' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>Loan Eligibility Check in Progress</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography className={Styles.CardBodyText} color={theme.palette.textColor.darkBlack}>
							We are accessing your loan eligibility. This should not take more than 5 minute.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default HocWrapper(LoanEligibilityInProgress);
