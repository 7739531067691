// /
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../Utils/Api';

const resetPasswordUrl =
	'borrower_apk/forgot_password/update?v=1000&latitude=12.960680890828371&longitude=77.63665560632944';

export const saveResetPassword = createAsyncThunk('ResetPassword/saveResetPasswordApi', async apiBody => {
	return Api('post', resetPasswordUrl, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					data['apiMessage'] = response.data.message;
					data['statusCode'] = response.data.status;
					data['apiResponse'] = response.data;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return {
					apiMessage: err.response.data.message,
				};
			}
		});
});

const initialState = {
	isLoading: false,
	resetPasswordDetails: {
		apiResult: '',
		message: '',
		userStatus: '',
		statusCode: '',
	},
};

const resetPasswordSlice = createSlice({
	name: 'Login',
	initialState,
	reducers: {
		resetState: (state, action) => {
			state.isLoading = false;
			state.resetPasswordDetails = {
				apiResult: '',
				message: '',
				userStatus: '',
				statusCode: '',
			};
		}
	},
	extraReducers: builder => {
		builder.addCase(saveResetPassword.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(saveResetPassword.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.resetPasswordDetails.apiResult = payload.apiResponse;
				state.resetPasswordDetails.message = payload.apiMessage;
				state.resetPasswordDetails.userStatus = payload.userStatus;
				state.resetPasswordDetails.statusCode = payload.statusCode;
			}
		});
		builder.addCase(saveResetPassword.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.resetPasswordDetails.message = payload && payload.apiMessage;
		});
	},
});

export const { resetState } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
