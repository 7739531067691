import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import eligibilityTimer from '../../Assets/Icons/EligibilityTimerIcon.svg';

// Stylesheet
import Styles from './EligibilityCheck.module.scss';

function ManualVerificationInprogress(props) {
	const { theme, handle_android_back_button } = props;

	const handleAndroidBackButton = () => {
		handle_android_back_button('closeApp');
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);

		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={eligibilityTimer} alt='eligibility-timer' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>Manual Verification in progress</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography className={Styles.CardBodyText} color={theme.palette.textColor.darkBlack}>
							We are verifying your details. It may take upto 1 business day.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default HocWrapper(ManualVerificationInprogress);
