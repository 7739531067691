import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const CustomRadio = ({ children, ...rest }) => {
	const { data } = rest;

	return (
		<FormControl>
			<RadioGroup {...rest} row>
				{data &&
					data.map((item, idx) => (
						<FormControlLabel value={item.value} control={<Radio />} label={item.label} key={idx} />
					))}
				{children}
			</RadioGroup>
		</FormControl>
	);
};

export default CustomRadio;

/** ----- Usage Info -----*/
/**
 * 	Pass an array of object to data prop.
 *  <CustomRadio
 * 	onChange={()=>{}}
 *  data={[{ value: 'male', label:'Male' }, { value: 'female', label:'Female' }]}
 *  {...props}
 * >
 */
