import React from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// svg
import reEvaluation from '../../Assets/Images/re-evaluation.png';

// Stylesheet
import Styles from './ReEvaluation.module.scss';

const ReEvaluation = props => {
	const { theme } = props;

	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={reEvaluation} alt='eligibility-timer' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>Profile Re-Evaluation</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography
							fontWeight='600'
							color={theme.palette.textColor.darkBlack}
							className={Styles.CardBodyText}>
							Profile Re-Evaluation in progress…
						</Typography>
						<Typography color={theme.palette.textColor.grey} className={Styles.CardBodyText}>
							Please wait while we are re-evaluating your profile to give you the best loan products.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default HocWrapper(ReEvaluation);
