// regex
import { acceptNoSpaceAtBeginning, acceptAlphabetsAndNumbersNoSpace } from './Regex';

export const localeCurrencyFormat = (currencyVal, locale, style) => {
	if (currencyVal) {
		return currencyVal.toLocaleString(locale, style ? style : {});
	}
};

export const validateUserInput = (userEnteredValue, config) => {
	const { inputName: formikStateName, setterFun, validate } = config;

	let noSpaceAtTheBeginning = userEnteredValue === '' || acceptNoSpaceAtBeginning.test(userEnteredValue);

	if (noSpaceAtTheBeginning) {
		let regexOutput = '';

		switch (validate) {
			case 'user-name':
				regexOutput = userEnteredValue.replace(/  +/g, ' ').replace(/[^a-zA-Z ]/g, '');

				setterFun(formikStateName, regexOutput);
				break;

			case 'only-numeric':
				if (acceptAlphabetsAndNumbersNoSpace.test(userEnteredValue)) {
					setterFun(formikStateName, userEnteredValue.toUpperCase());
				}
				break;

			case 'company-name':
				regexOutput = userEnteredValue.replace(/  +/g, ' ').replace(/[^a-zA-Z0-9 ]/g, '');

				setterFun(formikStateName, regexOutput);
				break;
			default:
				setterFun(formikStateName, userEnteredValue);
				break;
		}
	}
};
