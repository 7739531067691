import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Registration from '../../Components/Layout/Registration';
import CustomNumberInput from '../../Components/HtmlElements/CustomNumberInput';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import { verifyForgotPasswordOtp, ResendForgotPasswordOtp, resetOtpState } from './OtpSlice';
import getRoute from '../../Utils/GetRoute';
import CustomLoader from '../../Components/Loader/CustomLoader';
// Styling files
import Styles from './Otp.module.scss';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

let interval, timer;
const ForgotPasswordOtp = props => {
	const {
		theme,
		show_toast,
		navigate,
		get_previous_props,
		check_internet_connection,
		handle_android_back_button,
	} = props;
	const { darkBlack, grey } = theme.palette.textColor;
	const { errorColor } = theme.palette.error.main;
	const previousScreenState = get_previous_props();
	const dispatch = useDispatch();
	const [otp, setOtp] = useState();
	const [otpError, setOtpError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showResendButton, setShowResendButton] = useState(false);
	const [resendOtpError, setResendOtpError] = useState(false);
	const otpId = useSelector(state => state.forgotPasswordDetails.verifyEmailDetails.emailOtpId);
	const isLoading = useSelector(state => state.userOtpDetails.isLoading);
	const verifyForgotPasswordOtpDetails = useSelector(state => state.userOtpDetails.forgotPasswordOtpDetails);
	const resendForgotPasswordOtpDetails = useSelector(state => state.userOtpDetails.resendOtpDetails);

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const otpHandler = event => {
		const val = event.target.value && event.target.value.split('-').join('');
		if (val.length <= 6) {
			setOtp(val);
			if (otpError) {
				setOtpError(false);
				setErrorMessage('');
			}
		}
	};

	const handleAndroidBackButton = () => {
		handle_android_back_button('closeApp');
	};

	const handleResendOTP = () => {
		setShowResendButton(false);
		if (previousScreenState.emailId) {
			if (check_internet_connection()) {
				dispatch(
					ResendForgotPasswordOtp({
						email_address: previousScreenState.emailId,
					}),
				);
			}
		}
	};

	const clearState = () => {
		// Here we are clearing the user details from the store
		dispatch(resetOtpState());
	};

	const validate = () => {
		if (otp) {
			if (otp.length === 6) {
				if (check_internet_connection()) {
					let demoUser = {
						email_otp: otp.trim(),
						email_otp_id: previousScreenState && previousScreenState.emailOtpId,
						email_address: previousScreenState?.emailId,
					};
					let normalUser = {
						email_otp: otp.trim(),
						email_otp_id: previousScreenState && previousScreenState.emailOtpId,
					};

					dispatch(verifyForgotPasswordOtp(isDemoEnabled ? demoUser : normalUser));
				}
			} else {
				setOtpError(true);
				setErrorMessage('*Please enter a valid OTP');
			}
		} else {
			setOtpError(true);
			setErrorMessage('*Please enter the OTP');
		}
	};

	const runTimer = () => {
		let element = document.getElementById('timer');
		timer = 30;
		interval = setInterval(() => {
			if (timer <= 0) {
				clearInterval(interval);
				setShowResendButton(true);
			} else {
				timer = timer - 1;
				element.textContent = timer >= 10 ? `00 : ${timer}s` : `00 : 0${timer}s`;
			}
		}, 1000);
	};

	const validateForgotPasswordVerifyOtpDetails = () => {
		if (verifyForgotPasswordOtpDetails.statusCode === 200) {
			let route = getRoute(verifyForgotPasswordOtpDetails.userStatus);
			if (route) {
				// clearState();
				navigate(route, true, previousScreenState);
			}
		} else {
			verifyForgotPasswordOtpDetails.message && show_toast(verifyForgotPasswordOtpDetails.message, 'error');
		}
	};

	const validateForgotPasswordResendOtpDetails = () => {
		if (resendForgotPasswordOtpDetails) {
			if (resendForgotPasswordOtpDetails.statusCode === 200) {
				runTimer();
				setOtp('');
				resendForgotPasswordOtpDetails.message && show_toast('OTP Sent Successfully', 'info');
			} else {
				resendForgotPasswordOtpDetails.message && show_toast(resendForgotPasswordOtpDetails.message, 'error');
				setResendOtpError(true);
			}
		}
	};

	useEffect(() => {
		runTimer();
		show_toast('OTP Sent Successfully');
	}, []);

	useEffect(() => {
		if (otp && otp.length === 6) {
			validate();
		}
	}, [otp]);

	useEffect(() => {
		if (verifyForgotPasswordOtpDetails) validateForgotPasswordVerifyOtpDetails();
	}, [verifyForgotPasswordOtpDetails]);

	useEffect(() => {
		if (resendForgotPasswordOtpDetails) validateForgotPasswordResendOtpDetails();
	}, [resendForgotPasswordOtpDetails]);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			clearState();
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<>
			{isLoading && <CustomLoader />}
			<CssBaseline />
			<Registration heading='OTP Verification' subheading='Verify via OTP'>
				<Grid className={Styles.numberText}>
					<Typography color={darkBlack} className={Styles.otpText} variant='body1'>
						Enter 6-digit Code we have sent to <br />
						{previousScreenState && previousScreenState.emailId && previousScreenState.emailId}
					</Typography>
				</Grid>
				<Grid className={Styles.otpInputGrid}>
					<CustomNumberInput
						id='otp'
						name='otp'
						label='OTP'
						value={otp}
						onChangeHandler={otpHandler}
						maskFormat={'000-000'}
					/>
					{otpError && (
						<Typography className={Styles.errorText} color={errorColor}>
							<em>{errorMessage}</em>
						</Typography>
					)}
					{!showResendButton && (
						<Typography id='timer' className={Styles.timerText} color={grey}>
							{resendOtpError ? '' : '00 : 30s'}
						</Typography>
					)}
					{/* show re-send OTP */}
					{showResendButton && (
						<Grid className={Styles.resendOtpGrid}>
							<Typography className={Styles.resendText} color={grey}>
								Did not receive OTP yet?
							</Typography>
							<CustomButton
								id='resendOtp'
								clickHandler={handleResendOTP}
								buttonText='Resend OTP'
								fullWidth={false}
								size='small'
							/>
						</Grid>
					)}
				</Grid>
			</Registration>
		</>
	);
};

export default HocWrapper(ForgotPasswordOtp);
