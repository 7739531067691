import Styles from './CustomCard.module.scss';

const CustomCard = ({ children, ...rest }) => (
	<div className={Styles.cardContainer} {...rest}>
		{children}
	</div>
);

export default CustomCard;

/** ----- Usage Info -----*/
/**
 * This component can be used as a Card comp basic info screen onwards.
 * >
 */