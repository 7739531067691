import { tokenConfig, refreshTokenConfig } from './Config';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const borrowerURL = process.env.REACT_APP_BORROWER_MANAGEMENT_API;
const thirdPartyURL = process.env.REACT_APP_THIRD_PARTY_SERVICE_API;

const Api = (target, ApiURL, body, targetURL) => {
	if (target === 'post')
		return axios.post(
			`${
				targetURL === 'borrower' ? borrowerURL : targetURL === 'thirdParty' ? thirdPartyURL : baseURL
			}/${ApiURL}`,
			body ? body : {},
			tokenConfig(),
		);
	if (target === 'get')
		return axios.get(
			`${
				targetURL === 'borrower' ? borrowerURL : targetURL === 'thirdParty' ? thirdPartyURL : baseURL
			}/${ApiURL}`,
			{ ...tokenConfig(), data: {} },
		);
	if (target === 'refreshToken') {
		return axios.get(`${baseURL}/${ApiURL}`, { ...refreshTokenConfig(), data: {} });
	}
};

export default Api;
