import { createTheme } from '@mui/material/styles';
import { Theme } from '../../Assets/CustomStyles/MaterialTheme';

export const CustomTheme = createTheme({
	...Theme,
	components: {
		...Theme.components,
		MuiCheckbox: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.id === 'primaryAccountConsent' && {
						'&.Mui-checked': {
							padding: '0px 4px 0px 0px',
							alignItems: 'flex-start',
							color: 'green',
						},
					}),
				}),
			},
		},
	},
});
