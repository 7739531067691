import * as Yup from 'yup';
import { acceptAlphabetsAndSpace } from '../Utils/Regex';

export const referenceInfoSchema = Yup.object({
	mobile_number: Yup.string().test({
		skipAbsent: true,
		test(value, ctx) {
			let mobileNumberStartsWithValidation =
				value.startsWith(6) || value.startsWith(7) || value.startsWith(8) || value.startsWith(9);
			if (mobileNumberStartsWithValidation) {
				return value && value.split('-').join('');
			} else {
				return ctx.createError({ message: 'Enter valid mobile number' });
			}
		},
	}),
	reference_contact_name: Yup.string()
		.test({
			skipAbsent: true,
			test(value, ctx) {
				const splittedValue = value.split(' ');
				const result = splittedValue
					.map(val => val.replace(' ', ''))
					.filter(item => item !== '')
					.join('');
				if (result.trim().length < 3) {
					return ctx.createError({ message: 'Minimum 3 letters required' });
				}
				return true;
			},
		})
		.max(100, 'Maximum 100 letters allowed')
		.matches(acceptAlphabetsAndSpace, 'Only alphabets are allowed'),
});
