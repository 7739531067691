// MUI-X comp for datePicker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePickerToolbar } from '@mui/x-date-pickers';

// MUI comp
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';

// icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import CustomInput from './CustomInput';

// styles
import Styles from './CustomDatePicker.module.scss';

const CustomDatePicker = ({ value, onChange, label, ...rest }) => {
	const additionalPropsToCustomInput = {
		label: label ? label : '',
		InputProps: {
			endAdornment: (
				<InputAdornment position='end'>
					<CalendarMonthIcon />
				</InputAdornment>
			),
		},
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DemoContainer components={['DatePicker']} sx={{ paddingTop: '0px' }}>
				<DatePicker
					format='DD/MM/YYYY'
					disableFuture
					value={value}
					onChange={onChange}
					// slots is used to override default behaviour of the MUI comp.
					slots={{
						textField: CustomInput,
						toolbar: DatePickerToolbar,
						mobilePaper: Paper,
					}}
					slotProps={{
						textField: additionalPropsToCustomInput,
						toolbar: {
							className: Styles.toolBar,
						},
						mobilePaper: {
							square: true,
						},
					}}
					{...rest}
				/>
			</DemoContainer>
		</LocalizationProvider>
	);
};

export default CustomDatePicker;

/** ----- Usage Info -----*/
/**
 * <CustomDatePicker {...props} />
 */
