import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// Screens
import Permissions from '../Containers/Permissions/Permissions';
import NotFound from './NotFound';
import GetStarted from '../Components/GetStarted/GetStarted';
import CreatePassword from '../Containers/CreatePassword/CreatePassword';
import Login from '../Containers/Login/Login';
import LoginOtp from '../Containers/Otp/LoginOtp';
import RegistrationOtp from '../Containers/Otp/RegistrationOtp';
import MobileNumber from '../Containers/MobileNumber/MobileNumber';
import ForgotPassword from '../Containers/ForgotPassword/ForgotPassword';
import ForgotPasswordOtp from '../Containers/Otp/ForgotPasswordOtp';
import ResetPassword from '../Containers/ResetPassword/ResetPassword';
import ResetSuccess from '../Containers/ResetPassword/ResetSuccess';
import BasicInfo from '../Containers/BasicInfo/BasicInfo';
import Home from '../Containers/HomeScreen/Home';
import PermissionsFail from '../Containers/Permissions/PermissionsFail';
import IncomeInfo from '../Containers/IncomeInfo/IncomeInfo';
import PanCheckFail from '../Containers/EligibilityCheckScreen/PanCheckFail';
import OnHold from '../Containers/EligibilityCheckScreen/OnHold';
import EligibilityCheckInprogress from '../Containers/EligibilityCheckScreen/EligibilityCheckInprogress';
import ManualVerificationInprogress from '../Containers/EligibilityCheckScreen/ManualVerificationInprogress';
import CreatePasswordSuccess from '../Containers/CreatePassword/CreatePasswordSuccess';
import ReferenceContactFamily from '../Containers/ReferenceContact/ReferenceContactFamily';
import ReferenceContactFriends from '../Containers/ReferenceContact/ReferenceContactFriends';
import FamilyReferenceOtp from '../Containers/Otp/FamilyReferenceOtp';
import FriendReferenceOtp from '../Containers/Otp/FriendReferenceOtp';
import Congratulations from '../Components/Congratulations/Congratulations';
import KycManualVerificationInprogress from '../Containers/KycCheckScreens/KycManualVerificationInprogress';
import KycCheckInprogress from '../Containers/KycCheckScreens/KycCheckInprogress';
import SelfieCheckInprogress from '../Containers/SelfieCheckScreens/SelfieCheckInprogress';
import SelectBank from '../Containers/SelectBank/SelectBank';
import AddBank from '../Containers/AddBank/AddBank';
import Product from '../Containers/Product/Product';
import BankVerificationInprogress from '../Containers/BankVerificationCheckScreen/BankVerificationInprogress';
import PurposeOfLoan from '../Containers/PurposeOfLoan/PurposeOfLoan';
import LoanDisbursalInprogress from '../Containers/LoanDisbursalInprogress/LoanDisbursalInprogress';
import EsignAgreement from '../Containers/EsignAgreement/EsignAgreement';
import EsignAgreementOtp from '../Containers/Otp/EsignAgreementOtp';
import SanctionLetter from '../Containers/SanctionLetter/SanctionLetter';
import LoanEligibilityInProgress from '../Containers/LoanEligibility/LoanEligibilityInProgress';
import ReEvaluation from '../Containers/ReEvaluation/ReEvaluation';
import NoService from '../Containers/Noservice/NoService';
import LoanDisbursalSuccess from '../Containers/LoanDisbursalSuccessScreen/LoanDisbursalSuccess';
import RepaymentHomeScreen from '../Containers/LoanRepayment/RepaymentHomeScreen';
import LoanRepaymentDetails from '../Containers/LoanRepayment/LoanRepaymentDetails';
import LoanRepaymentSuccess from '../Containers/LoanRepayment/LoanRepaymentSuccess';
import LoanRepaymentFail from '../Containers/LoanRepayment/LoanRepaymentFail';
import LoanClosed from '../Containers/LoanRepayment/LoanClosed';
import VKYCInprogress from '../Containers/VKYC/VKYCInprogress';
import BankVerificationManual from '../Components/BankVerificationManual/BankVerificationManual';
// FCM
import HandleFCM from './HandleFCM';
import UpgradeAmount from '../Containers/UpgradeAmount/UpgradeAmount';

// Enum
import { ROUTE } from '../Utils/Enum/Route';

function Routes() {
	const routes = createBrowserRouter([
		{
			path: '/',
			element: <GetStarted />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.GET_STARTED,
			element: <GetStarted />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.PERMISSIONS,
			element: <Permissions />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.LOGIN,
			element: <Login />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.CREATE_PASSWORD,
			element: <CreatePassword />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.MOBILE_VERIFICATION,
			element: <MobileNumber />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.LOGIN_OTP,
			element: <LoginOtp />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.SIGNUP_OTP,
			element: <RegistrationOtp />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.FORGOT_PASSWORD,
			element: <ForgotPassword />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.FORGOT_PASSWORD_OTP,
			element: <ForgotPasswordOtp />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.RESET_PASSWORD,
			element: <ResetPassword />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.RESET_SUCCESS,
			element: <ResetSuccess />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.BASIC_INFO,
			element: <BasicInfo />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.REFERENCE_CONTACT_FAMILY,
			element: <ReferenceContactFamily />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.REFERENCE_CONTACT_FRIEND,
			element: <ReferenceContactFriends />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.PERMISSION_FAIL,
			element: <PermissionsFail />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.HOME,
			element: <Home />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.INCOME_INFO,
			element: <IncomeInfo />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.PAN_CHECK_FAIL,
			element: <PanCheckFail />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.ON_HOLD,
			element: <OnHold />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.ELIGIBILITY_CHECK_INPROGRESS,
			element: <EligibilityCheckInprogress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.MANUAL_VERIFICATION_INPROGRESS,
			element: <ManualVerificationInprogress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.CREATE_PASSWORD_SUCCESS,
			element: <CreatePasswordSuccess />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.REFERENCE_FAMILY_OTP,
			element: <FamilyReferenceOtp />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.REFERENCE_FRIEND_OTP,
			element: <FriendReferenceOtp />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.CONGRATULATIONS,
			element: <Congratulations />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.KYC_CHECK_INPROGRESS,
			element: <KycCheckInprogress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.KYC_MANUAL_VERIFICATION,
			element: <KycManualVerificationInprogress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.KYC_CHECK_FAIL,
			element: <PanCheckFail />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.SELFIE_CHECK_INPROGRESS,
			element: <SelfieCheckInprogress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.SELFIE_MANUAL_VERIFICATION,
			element: <ManualVerificationInprogress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.SELECT_BANK,
			element: <SelectBank />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.PRODUCT,
			element: <Product />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.PURPOSE_OF_LOAN,
			element: <PurposeOfLoan />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.ADD_BANK,
			element: <AddBank />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.BANK_VERIFICATION_INPROGRESS,
			element: <BankVerificationInprogress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.ESIGN_AGREEMENT,
			element: <EsignAgreement />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.ESIGN_OTP,
			element: <EsignAgreementOtp />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.SANCTION_LETTER,
			element: <SanctionLetter />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.FCM,
			element: <HandleFCM />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.LOAN_DISBURSAL_INPROGRESS,
			element: <LoanDisbursalInprogress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.LOAN_DISBURSAL_SUCCESS,
			element: <LoanDisbursalSuccess />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.LOAN_ELIGIBILITY_INPROGRESS,
			element: <LoanEligibilityInProgress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.RE_EVALUATION,
			element: <ReEvaluation />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.NO_SERVICE,
			element: <NoService />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.REPAYMENT_HOME,
			element: <RepaymentHomeScreen />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.LOAN_REPAYMENT_DETAILS,
			element: <LoanRepaymentDetails />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.LOAN_REPAYMENT_SUCCESS,
			element: <LoanRepaymentSuccess />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.LOAN_REPAYMENT_FAIL,
			element: <LoanRepaymentFail />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.LOAN_CLOSED,
			element: <LoanClosed />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.UPGRADE_AMOUNT,
			element: <UpgradeAmount />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.VKYC_INPROGRESS,
			element: <VKYCInprogress />,
			errorElement: <NotFound />,
		},
		{
			path: ROUTE.BANK_MANUAL_VERIFICATION,
			element: <BankVerificationManual />,
			errorElement: <NotFound />,
		},
	]);
	return <RouterProvider router={routes} />;
}

export default Routes;
