import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
// Icons
import SuccessIcon from '../../Assets/Icons/SuccessTickIcon.svg';
import CustomButton from '../../Components/HtmlElements/CustomButton';

// Enum
import { ROUTE } from '../../Utils/Enum';

function ResetSuccess(props) {
	const { theme, navigate } = props;

	const moveToLogin = () => {
		navigate('/' + ROUTE.LOGIN, true);
	};

	const handleAndroidBackButton = () => {
		navigate('/' + ROUTE.LOGIN, true);
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<Grid
			container
			display='flex'
			flexDirection='column'
			backgroundColor={theme.palette.primary.main}
			justifyContent='space-between'
			height='100vh'>
			<Grid
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
				width='100%'
				margin='auto'>
				<img src={SuccessIcon} alt='' />
				<Typography variant='h6' color={theme.palette.textColor.white} marginTop='18px' textAlign='center'>
					Password Reset Successful
				</Typography>
			</Grid>
			<Grid
				width='100%'
				padding='22px'
				justifyContent='center'
				alignItems='center'
				backgroundColor={theme.palette.background.primary}>
				<Typography
					variant='body1'
					color={theme.palette.textColor.grey}
					fontSize={12}
					fontWeight={500}
					marginBottom='38px'
					marginTop='12px'>
					You can now use your new password to log in to your account.
				</Typography>
				<CustomButton buttonText='Log In' clickHandler={moveToLogin} />
			</Grid>
		</Grid>
	);
}

export default HocWrapper(ResetSuccess);
