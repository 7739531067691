import React, { useEffect, useState } from 'react';
import Registration from '../../Components/Layout/Registration';
import CustomInput from '../../Components/HtmlElements/CustomInput';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconsList } from '../../Assets/PathIcons/PathIcons';
import DeepClone from '../../Utils/DeepClone';
import { useDispatch, useSelector } from 'react-redux';
import { saveResetPassword, resetState } from './ResetPasswordSlice';
import CustomLoader from '../../Components/Loader/CustomLoader';
import FormControl from '@mui/material/FormControl';
// styles
import Styles from './ResetPassword.module.scss';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

// regex
import { passwordRegex } from '../../Utils/Regex';

// Enum
import { ROUTE } from '../../Utils/Enum';

const ResetPassword = props => {
	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const {
		navigate,
		show_toast,
		theme,
		get_previous_props,
		check_internet_connection,
		handle_android_back_button,
	} = props;
	const dispatch = useDispatch();
	const { PasswordIcon } = IconsList;
	const { darkBlack } = theme.palette.textColor;
	const errorColor = theme.palette.error.main;
	const previousScreenState = get_previous_props();
	const [passwordDetails, setPasswordDetails] = useState({ password: '', confirmPassword: '' });
	const [showPassword, setShowPassword] = useState(false);
	const [pwRules, setPwRules] = useState([
		{ ruleName: 'Minimum 8 & maximum 16 characters.', reg: /^.{8,16}$/, valid: false },
		{ ruleName: 'Mix of alphanumeric characters.', reg: /[0-9]/, valid: false },
		{ ruleName: 'One uppercase', reg: /(?=.*[A-Z])/, valid: false },
		{ ruleName: 'At least one special character (@, #, $, %)', reg: /(?=.*[!@#$%^&.*])/, valid: false },
	]);

	const passwordRulesForDemo = [
		{ ruleName: 'Minimum 8 & maximum 16 characters.', reg: /^.{8,16}$/, valid: true },
		{ ruleName: 'Mix of alphanumeric characters.', reg: /[0-9]/, valid: true },
		{ ruleName: 'One uppercase', reg: /(?=.*[A-Z])/, valid: true },
		{ ruleName: 'At least one special character (@, #, $, %)', reg: /(?=.*[!@#$%^&.*])/, valid: true },
	];

	const [errors, setErrors] = useState({
		rulesError: false,
		confirmIsSame: false,
		passwordError: false,
		confirmPasswordError: false,
	});
	const isLoading = useSelector(state => state.resetPasswordDetails.isLoading);
	const resetPasswordDetails = useSelector(state => state.resetPasswordDetails.resetPasswordDetails);

	const handlePasswordVisibility = () => setShowPassword(show => !show);

	const handleUserInput = e => {
		const previousState = DeepClone(passwordDetails);
		const oldErrorState = DeepClone(errors);
		let { name, value } = e.target;
		previousState[name] = value;
		if (name === 'password') {
			let passwordRules = [...pwRules];
			passwordRules.forEach(element => {
				if (element.reg.test(value)) {
					element['valid'] = true;
				} else {
					element['valid'] = false;
				}
			});

			setPwRules(passwordRules);
		}
		if (name === 'password' || name === 'confirmPassword') {
			oldErrorState.rulesError = false;
			oldErrorState.confirmIsSame = false;
			oldErrorState.passwordError = false;
			oldErrorState.confirmPasswordError = false;
		}

		if (value === '' || passwordRegex.test(value)) {
			setPasswordDetails(previousState);
		}
		setErrors(oldErrorState);
	};

	const handleAndroidBackButton = () => {
		handle_android_back_button('closeApp');
	};

	const onSave = e => {
		e.preventDefault();
		if (isDemoEnabled) {
			navigate('/' + ROUTE.RESET_SUCCESS, true);
		} else {
			const oldData = DeepClone(errors);
			if (passwordDetails.password) {
				if (passwordDetails.confirmPassword) {
					if (isAllPasswordRulesMatching) {
						if (isCnfmPasswrdEqualsPasswrd) {
							if (saveBtnStatus) {
								if (previousScreenState && previousScreenState.emailId) {
									if (check_internet_connection()) {
										dispatch(
											saveResetPassword({
												email_address: previousScreenState.emailId,
												new_password: passwordDetails.password,
											}),
										);
									}
								} else {
									show_toast('Email Id is missing', 'error');
								}
							}
						} else {
							oldData.confirmIsSame = true;
							oldData.rulesError = false;
						}
					} else {
						oldData.rulesError = true;
					}
				} else {
					oldData.confirmPasswordError = true;
				}
			} else {
				oldData.passwordError = true;
			}
			setErrors(oldData);
		}
	};

	// password and confirm password validation
	const isCnfmPasswrdEqualsPasswrd =
		passwordDetails.password.length > 0 && passwordDetails.confirmPassword.length > 0
			? passwordDetails.password === passwordDetails.confirmPassword
			: false;
	// password rules validation
	const isAllPasswordRulesMatching = pwRules.map(({ valid }) => valid).every(Boolean);
	// 'save and continue btn visibility
	const saveBtnStatus = isCnfmPasswrdEqualsPasswrd && isAllPasswordRulesMatching;

	const validateResetPasswordDetails = () => {
		if (resetPasswordDetails.statusCode === 200) {
			navigate('/' + ROUTE.RESET_SUCCESS, true);
		} else {
			resetPasswordDetails.message && show_toast(resetPasswordDetails.message);
		}
	};

	const clearTheStore = () => {
		// Here we are clearing the user login details from the redux store
		dispatch(resetState());
	};

	useEffect(() => {
		if (isDemoEnabled) {
			setPasswordDetails({ password: 'Demo123@', confirmPassword: 'Demo123@' });
		}
	}, []);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			clearTheStore();
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		if (resetPasswordDetails) {
			validateResetPasswordDetails();
		}
	}, [resetPasswordDetails]);

	return (
		<>
			{isLoading && <CustomLoader />}
			<Registration
				heading='Reset Your Password'
				subheading='Keep your account safe and secure.'
				direction='column'
				height='550px'
				justifyContent='space-between'>
				<FormControl component='form' onSubmit={onSave}>
					<Grid display='flex' flexDirection='column'>
						<Grid className={Styles.inputDiv}>
							<CustomInput
								id='password'
								withIcon
								Icon={PasswordIcon}
								name='password'
								label='Set New Password'
								type={showPassword ? 'text' : 'password'}
								value={passwordDetails.password}
								onChange={handleUserInput}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton onClick={handlePasswordVisibility}>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>

							{errors.passwordError && (
								<Typography className={Styles.errorText} color={errorColor}>
									<em>*Please enter your password</em>
								</Typography>
							)}
						</Grid>
						<Grid className={Styles.inputDiv}>
							<CustomInput
								id='confirm-password'
								withIcon
								Icon={PasswordIcon}
								name='confirmPassword'
								label='Confirm New Password'
								value={passwordDetails.confirmPassword}
								onChange={handleUserInput}
								InputProps={{
									endAdornment: isCnfmPasswrdEqualsPasswrd && (
										<InputAdornment position='start'>
											<CheckCircleIcon variant='validCheck' fontSize='20px' />
										</InputAdornment>
									),
								}}
							/>
							{errors.confirmPasswordError && (
								<Typography className={Styles.errorText} color={errorColor}>
									<em>*Please enter confirm password</em>
								</Typography>
							)}
							{!errors.confirmPasswordError && errors.confirmIsSame && !errors.rulesError && (
								<Typography className={Styles.errorText} color={errorColor}>
									<em>*Password and confirm password is not matching</em>
								</Typography>
							)}
							{errors.rulesError && !errors.confirmPasswordError && !errors.confirmIsSame && (
								<Typography className={Styles.errorText} color={errorColor}>
									<em>*Please enter a strong password matching the below rules</em>
								</Typography>
							)}
						</Grid>
					</Grid>
					<Grid>
						<Grid className={Styles.passwordContainer}>
							<Typography
								color={darkBlack}
								fontWeight='500'
								variant='body1'
								textAlign='left'
								margin='0 0 10px 4px'>
								Password Rules:
							</Typography>
							<Grid container rowGap='11px'>
								{isDemoEnabled
									? passwordRulesForDemo.map(({ ruleName, valid }, idx) => (
											<Grid item display='flex' alignItems='center' xs={12} key={idx}>
												<CheckCircleIcon variant={valid ? 'validCheck' : 'invalidCheck'} fontSize='20px' />
												<Typography
													color={darkBlack}
													display='inline'
													variant='passwordRules'
													marginLeft='11px'
													textAlign='left'>
													{ruleName}
												</Typography>
											</Grid>
									  ))
									: pwRules.map(({ ruleName, valid }, idx) => (
											<Grid item display='flex' alignItems='center' xs={12} key={idx}>
												<CheckCircleIcon variant={valid ? 'validCheck' : 'invalidCheck'} fontSize='20px' />
												<Typography
													color={darkBlack}
													display='inline'
													variant='passwordRules'
													marginLeft='11px'
													textAlign='left'>
													{ruleName}
												</Typography>
											</Grid>
									  ))}
							</Grid>
						</Grid>
						<Grid>
							<CustomButton
								type='submit'
								buttonText='Reset My Password'
								disabled={isDemoEnabled ? false : !saveBtnStatus}
							/>
						</Grid>
					</Grid>
				</FormControl>
			</Registration>
		</>
	);
};

export default HocWrapper(ResetPassword);
