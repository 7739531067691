import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import Style from '../Product.module.scss';

const ProductSelectionSkeleton = () => {
	return (
		<Grid item className={Style.productSelectionContainer}>
			<Grid padding='18px'>
				<Grid>
					<Typography>
						<Skeleton animation='wave' sx={{ bgcolor: '#cacaca47', width: 50 }} />
					</Typography>
				</Grid>
				<Grid marginTop='10px'>
					<Skeleton animation='wave' sx={{ bgcolor: '#cacaca47', height: 45 }} />
				</Grid>

				<Grid marginTop='12px' width='100%'>
					<Skeleton animation='wave' sx={{ bgcolor: '#cacaca47' }} />
				</Grid>

				<Grid container justifyContent='space-between'>
					<Grid item>
						<Skeleton animation='wave' sx={{ bgcolor: '#cacaca47', width: 80 }} />
					</Grid>

					<Grid item>
						<Skeleton animation='wave' sx={{ bgcolor: '#cacaca47', width: 80 }} />
					</Grid>
				</Grid>
			</Grid>

			<Grid container className={Style.tenureBox}>
				<Grid item xs={5}>
					<Typography>
						<Skeleton animation='wave' sx={{ bgcolor: '#cacaca47', width: 50 }} />
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Skeleton animation='wave' sx={{ bgcolor: '#cacaca47', height: 45 }} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ProductSelectionSkeleton;
