import React, { useState, memo } from 'react';
import Grid from '@mui/material/Grid';

// Skeleton
import { PDFSkeleton, PDFDocLoadingSkeleton } from './PDFSkeletons';

// react-pdf
import { Document, Page, pdfjs } from 'react-pdf';

// styles
import Styles from './PDFRenderer.module.scss';

const PDFRenderer = memo(({ URL, isPdfHasLoaded }) => {
	const [numPages, setNumPages] = useState(null);

	// a worker is required to work react-pdf
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
		isPdfHasLoaded && isPdfHasLoaded(numPages);
	};

	return (
		<Document
			loading={<PDFDocLoadingSkeleton />}
			renderMode='canvas'
			file={URL && URL}
			onLoadSuccess={onDocumentLoadSuccess}>
			{Array.apply(null, Array(numPages))
				.map((_, i) => i + 1)
				.map((page, idx) => (
					<Grid className={Styles.singlePage}>
						<Page
							key={idx}
							loading={<PDFSkeleton />}
							scale={0.6}
							renderTextLayer={false}
							renderAnnotationLayer={false}
							customTextRenderer={false}
							pageNumber={page}
						/>
					</Grid>
				))}
		</Document>
	);
});

export default PDFRenderer;
