import React from 'react';
import { useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

function SubscribeTheme(OriginalComponent) {
	// This is a Higher order component which adds the extra features for your component and returns the new component
	const NewComponent = props => {
		const theme = useTheme();
		return (
			<>
				<CssBaseline />
				<OriginalComponent {...props} theme={theme} />
			</>
		);
	};
	return NewComponent;
}

export default SubscribeTheme;
