import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getVersionCode } from '../../Utils/AndroidHelper';
import Api from '../../Utils/Api';

const versionCode = getVersionCode();
const lat = localStorage.getItem('_latitude');
const long = localStorage.getItem('_longitude');
const version = versionCode ? versionCode : '1000';

const loginOtpUrl = `borrower_apk/login/otp/validate?v=${version}&latitude=${lat}&longitude=${long}`;
const signupOtpUrl = `borrower_apk/signup/otp/validate?v=${version}&latitude=${lat}&longitude=${long}`;
const forgotPasswordOtpUrl = `borrower_apk/forgot_password/otp/validate?v=${version}&latitude=${lat}&longitude=${long}`;
const resendLoginOtp = `borrower_apk/login/otp/resend?v=${version}&latitude=${lat}&longitude=${long}`;
const resendSignupOtp = `borrower_apk/signup/otp/resend?v=${version}&latitude=${lat}&longitude=${long}`;
const resendForgotPasswordOtp = `borrower_apk/forgot_password/otp/resend?v=${version}&latitude=${lat}&longitude=${long}`;

export const verifyLoginOtp = createAsyncThunk('otp/VerifyLoginOtp', async apiBody => {
	// This will be called from the Login otp screen
	return Api('post', loginOtpUrl, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					data['apiMessage'] = response.data.message;
					if (response.data.result) {
						data['token'] = response.data.result.token;
						data['refreshToken'] = response.data.result.refresh_token;
						data['userStatus'] = response.data.result.status;
						data['prefill'] = response.data.result.do_prefill;
					}
					data['statusCode'] = response.data.status;
					data['apiResponse'] = response.data;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return {
					apiMessage: err.response.data.message,
				};
			}
		});
});

export const verifySignupOtp = createAsyncThunk('otp/VerifySignupOtp', async apiBody => {
	// This will be called from the signup otp screen
	return Api('post', signupOtpUrl, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					data['apiMessage'] = response.data.message;
					if (response.data.result) {
						data['token'] = response.data.result.token;
						data['refreshToken'] = response.data.result.refresh_token;
						data['userStatus'] = response.data.result.status;
						data['prefill'] = response.data.result.do_prefill;
					}
					data['statusCode'] = response.data.status;
					data['apiResponse'] = response.data;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return {
					apiMessage: err.response.data.message,
				};
			}
		});
});

export const verifyForgotPasswordOtp = createAsyncThunk('otp/VerifyForgotPasswordOtp', async apiBody => {
	// This will be called from the forgot password otp screen
	return Api('post', forgotPasswordOtpUrl, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					data['apiMessage'] = response.data.message;
					if (response.data.result) {
						data['userStatus'] = response.data.result.status;
					}
					data['statusCode'] = response.data.status;
					data['apiResponse'] = response.data;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return {
					apiMessage: err.response.data.message,
				};
			}
		});
});

export const ResendLoginOtp = createAsyncThunk('otp/resendLoginOtp', async apiBody => {
	// This will be called from the login otp screen
	return Api('post', resendLoginOtp, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					data['apiMessage'] = response.data.message;
					data['statusCode'] = response.data.status;
					data['apiResponse'] = response.data;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return {
					apiMessage: err.response.data.message,
				};
			}
		});
});

export const ResendSignupOtp = createAsyncThunk('otp/resendSignupOtp', async apiBody => {
	// This will be called from the signup otp screen
	return Api('post', resendSignupOtp, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					data['apiMessage'] = response.data.message;
					data['statusCode'] = response.data.status;
					data['apiResponse'] = response.data;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return {
					apiMessage: err.response.data.message,
				};
			}
		});
});

export const ResendForgotPasswordOtp = createAsyncThunk('otp/resendForgotPasswordOtp', async apiBody => {
	// This will be called from the Forgot password otp screen
	return Api('post', resendForgotPasswordOtp, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					data['apiMessage'] = response.data.message;
					data['statusCode'] = response.data.status;
					data['apiResponse'] = response.data;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return {
					apiMessage: err.response.data.message,
				};
			}
		});
});

const initialState = {
	isLoading: false,
	loginOtpDetails: {
		message: '',
		loginToken: '',
		refreshToken: '',
		userStatus: '',
		statusCode: '',
		apiResult: '',
		prefill: '',
	},
	resendOtpDetails: {
		message: '',
		statusCode: '',
		apiResult: '',
	},
	signupOtpDetails: {
		message: '',
		loginToken: '',
		refreshToken: '',
		userStatus: '',
		statusCode: '',
		apiResult: '',
		prefill: '',
	},
	forgotPasswordOtpDetails: {
		message: '',
		loginToken: '',
		userStatus: '',
		statusCode: '',
		apiResult: '',
	},
};
const OtpSlice = createSlice({
	name: 'Otp',
	initialState,
	reducers: {
		resetOtpState: (state, action) => {
			state.isLoading = false;
			state.loginOtpDetails = {
				message: '',
				loginToken: '',
				refreshToken: '',
				userStatus: '',
				statusCode: '',
				apiResult: '',
				prefill: '',
			};
			state.signupOtpDetails = {
				message: '',
				loginToken: '',
				refreshToken: '',
				userStatus: '',
				statusCode: '',
				apiResult: '',
				prefill: '',
			};
			state.forgotPasswordOtpDetails = {
				message: '',
				loginToken: '',
				userStatus: '',
				statusCode: '',
				apiResult: '',
			};
			state.resendOtpDetails = {
				message: '',
				statusCode: '',
				apiResult: '',
			};
		},
	},
	extraReducers: builder => {
		// Verify Login OTP Api Hanlders
		builder.addCase(verifyLoginOtp.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(verifyLoginOtp.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.loginOtpDetails.apiResult = payload.apiResponse;
				state.loginOtpDetails.message = payload.apiMessage;
				state.loginOtpDetails.loginToken = payload.token;
				state.loginOtpDetails.refreshToken = payload.refreshToken;
				state.loginOtpDetails.userStatus = payload.userStatus;
				state.loginOtpDetails.statusCode = payload.statusCode;
				state.loginOtpDetails.prefill = payload.prefill;
			}
		});
		builder.addCase(verifyLoginOtp.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.loginOtpDetails.message = payload && payload.apiMessage;
		});
		// Resend Login OTP Api Handlers
		builder.addCase(ResendLoginOtp.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(ResendLoginOtp.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.resendOtpDetails.apiResult = payload.apiResponse;
				state.resendOtpDetails.message = payload.apiMessage;
				state.resendOtpDetails.userStatus = payload.userStatus;
				state.resendOtpDetails.statusCode = payload.statusCode;
			}
		});
		builder.addCase(ResendLoginOtp.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.resendOtpDetails.message = payload && payload.apiMessage;
		});
		// Verify Sigup OTP Api Handlers
		builder.addCase(verifySignupOtp.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(verifySignupOtp.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.signupOtpDetails.apiResult = payload.apiResponse;
				state.signupOtpDetails.message = payload.apiMessage;
				state.signupOtpDetails.loginToken = payload.token;
				state.signupOtpDetails.refreshToken = payload.refreshToken;
				state.signupOtpDetails.userStatus = payload.userStatus;
				state.signupOtpDetails.statusCode = payload.statusCode;
				state.signupOtpDetails.prefill = payload.prefill;
			}
		});
		builder.addCase(verifySignupOtp.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.signupOtpDetails.message = payload && payload.apiMessage;
		});
		// Resend Signup OTP Api Handlers
		builder.addCase(ResendSignupOtp.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(ResendSignupOtp.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.resendOtpDetails.apiResult = payload.apiResponse;
				state.resendOtpDetails.message = payload.apiMessage;
				state.resendOtpDetails.userStatus = payload.userStatus;
				state.resendOtpDetails.statusCode = payload.statusCode;
			}
		});
		builder.addCase(ResendSignupOtp.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.resendOtpDetails.message = payload && payload.apiMessage;
		});
		// Verify forgot password OTP Api Handlers
		builder.addCase(verifyForgotPasswordOtp.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(verifyForgotPasswordOtp.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.forgotPasswordOtpDetails.apiResult = payload.apiResponse;
				state.forgotPasswordOtpDetails.message = payload.apiMessage;
				state.forgotPasswordOtpDetails.loginToken = payload.token;
				state.forgotPasswordOtpDetails.userStatus = payload.userStatus;
				state.forgotPasswordOtpDetails.statusCode = payload.statusCode;
			}
		});
		builder.addCase(verifyForgotPasswordOtp.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.forgotPasswordOtpDetails.message = payload && payload.apiMessage;
		});
		// Resend Forgot password OTP Api Handlers
		builder.addCase(ResendForgotPasswordOtp.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(ResendForgotPasswordOtp.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.resendOtpDetails.apiResult = payload.apiResponse;
				state.resendOtpDetails.message = payload.apiMessage;
				state.resendOtpDetails.userStatus = payload.userStatus;
				state.resendOtpDetails.statusCode = payload.statusCode;
			}
		});
		builder.addCase(ResendForgotPasswordOtp.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.resendOtpDetails.message = payload && payload.apiMessage;
		});
	},
});

export const { resetOtpState } = OtpSlice.actions;
export default OtpSlice.reducer;
