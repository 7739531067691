import React, { useState, useEffect } from 'react';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomHeader from '../../Components/Layout/CustomHeader';
import AddBank from '../../Assets/Icons/AddBankIcon.svg';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import ProductHeading from '../Product/ProductHeading';
import SelectBankSkeleton from './SelectBankSkeleton';
import CustomLoader from '../../Components/Loader/CustomLoader';

// MUI Comps
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';

// Styles
import Styles from './SelectBank.module.scss';

// img
import BankImg from '../../Assets/Icons/BankIcon.svg';
import ArrowRight from '../../Assets/Icons/ArrowRightBank.svg';

// icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// LocalstorageValues
import { GetSavedApplicationId, SaveLoanId, GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

// RTK Query
import {
	useGetAllBanksQuery,
	useSetBankPrimaryMutation,
	useCreateLoanMutation,
	useLazyGetSanctionLetterQuery,
	useUpdateDemoUserStatusMutation,
} from '../../Services/apiSlice';

// Enum
import { ROUTE, USER_STATUS, BANK_STATUS } from '../../Utils/Enum';

function SelectBank(props) {
	const { theme, navigate, handle_android_back_button, check_internet_connection, show_toast } = props;

	const { textColor, primary, done } = props.theme.palette;

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const [selectBank, setSelectBank] = useState('');
	const [primaryModalOpen, setPrimaryModalOpen] = useState(false);
	const [selectedPrimaryAcc, setSelectedPrimaryAcc] = useState('');
	const [bankAccountId, setBankAccountId] = useState('');

	const appIsOnProd = process.env.NODE_ENV === 'production';

	// RTK related hooks
	const { data: userBankList, isSuccess, isFetching } = useGetAllBanksQuery('is_primary');
	const [generatedSanctionLetter, results] = useLazyGetSanctionLetterQuery();
	const [setBankAsPrimary, { isLoading: settingBankPrimary }] = useSetBankPrimaryMutation();
	const [createLoan, { isLoading: creatingLoan }] = useCreateLoanMutation();
	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();

	const hasUserAddedBanks = userBankList?.results?.banks_list?.length > 0;

	const numberOfSuccessBanks = userBankList?.results?.banks_list?.filter(
		bank => bank.status === BANK_STATUS.BANK_VERIFICATION_SUCCESS,
	);

	const handleBankSelection = (idx, bankAccId, isPrimary) => {
		setSelectBank(idx);
		setBankAccountId(bankAccId);
	};

	const handleAndroidBackButton = () => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.BASIC_INFO_PENDING,
						}).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('closeApp');
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			navigate('/' + ROUTE.PRODUCT, true);
		}
	};

	const disableContinueButton = selectBank !== null && selectBank.toString();

	const handleMakePrimaryAccount = bankId => {
		return () => {
			if (hasUserAddedBanks) {
				const selectedBankAccNumAndName = userBankList?.results?.banks_list.filter(
					({ bank_account_id }) => bank_account_id === bankId,
				);

				if (selectedBankAccNumAndName !== null) {
					setSelectedPrimaryAcc(selectedBankAccNumAndName[0]);
					setPrimaryModalOpen(true);
				}
			}
		};
	};

	const handleModalOK = () => {
		if (check_internet_connection()) {
			if (selectedPrimaryAcc.bank_account_id) {
				(async function makePrimaryAcc() {
					try {
						const response = await setBankAsPrimary({ id: selectedPrimaryAcc.bank_account_id }).unwrap();
						if (response?.status === 200) {
							if (response?.results?.success_description) {
								show_toast(response?.results?.success_description, 'success');
								setPrimaryModalOpen(false);
							}
						} else {
							show_toast(response?.message, 'error');
						}
					} catch {
						show_toast('Some exception occured while making this bank as Primary');
					}
				})();
			} else {
				show_toast('No bank account ID found');
			}
		}
	};

	const handleModalCancel = () => setPrimaryModalOpen(false);

	const handleSelectBankContinue = () => {
		const applicationId = GetSavedApplicationId();
		if (isDemoEnabled ? true : applicationId && bankAccountId) {
			let bankAccId = '';

			if (isDemoEnabled && appIsOnProd) {
				bankAccId = '100255';
			} else if (isDemoEnabled && !appIsOnProd) {
				bankAccId = '100262';
			} else {
				bankAccId = bankAccountId;
			}

			const reqBody = {
				application_id: applicationId,
				bank_acc_id: bankAccId,
			};

			if (check_internet_connection()) {
				(async function addLoan() {
					try {
						const response = await createLoan(reqBody).unwrap();

						if (response?.status === 200) {
							if (response?.result?.loan_id) {
								SaveLoanId(response?.result?.loan_id);
								try {
									const sanctionLetterResponse = await generatedSanctionLetter(bankAccId).unwrap();

									if (sanctionLetterResponse?.status === 200) {
										const presignedURL = sanctionLetterResponse?.result?.pre_signed_url;

										if (presignedURL) {
											navigate('/' + ROUTE.SANCTION_LETTER, true, { presignedURL, bankAccId });
										}
									} else {
										show_toast(sanctionLetterResponse?.message, 'error');
									}
								} catch {
									console.error('Exception occured while generating sanction');
								}
							}
						} else {
							show_toast(response?.message, 'error');
						}
					} catch (err) {
						show_toast(err?.data?.message);
					}
				})();
			}
		} else {
			console.error('Unable to make API call. Because, application ID or bankAccountID is missing!');
		}
	};

	const movetoAddBankAccountScreen = () =>
		navigate('/' + ROUTE.ADD_BANK, true, { makePrimary: numberOfSuccessBanks?.length });

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		localStorage.removeItem('_isBankAdded');
	}, []);

	return (
		<React.Fragment>
			{/* Set primary modal */}
			{updatingUsrStatus && <CustomLoader />}

			<Dialog
				fullScreen
				open={primaryModalOpen}
				// paperProps helps here to make modal bg trasparent.
				PaperProps={{
					sx: { backgroundColor: 'rgba(0,0,0,0.5)', width: 'auto', justifyContent: 'center' },
				}}>
				<Grid className={Styles.mainContainer}>
					{settingBankPrimary && <CustomLoader />}

					<Grid className={Styles.modalHeader}>
						<Grid>
							<Typography fontWeight='600' textAlign='center' variant='body1' color={textColor.darkBlack}>
								Are you sure you want to make this bank account as primary?
							</Typography>
						</Grid>
					</Grid>

					<Grid className={Styles.modalBody}>
						<Grid container>
							<Grid item>
								<Grid display='flex' alignItems='center'>
									<img src={BankImg} alt='bank-logo' className={Styles.bankLogo} />
									<Typography marginLeft='15px' fontSize='14px' fontWeight='600'>
										{selectedPrimaryAcc.selected_bank_name} - {selectedPrimaryAcc.acc_num}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid className={Styles.modalFooter} container>
						<Grid item xs={6}>
							<Grid
								onClick={handleModalCancel}
								color={textColor.grey}
								fontSize='14px'
								fontWeight='600'
								textAlign='center'>
								Cancel
							</Grid>
						</Grid>
						<Grid item xs={6} onClick={handleModalOK}>
							<Grid color={primary.main} textAlign='center' fontSize='14px' fontWeight='600'>
								Set as Primary
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Dialog>

			<CustomHeader
				header={hasUserAddedBanks ? 'Select Bank Account' : 'Add Bank Account'}
				handleBackButton={handleAndroidBackButton}>
				{isFetching || !isSuccess ? (
					<SelectBankSkeleton />
				) : (
					<React.Fragment>
						{/* Show this if user newly adding a bank */}

						{hasUserAddedBanks ? (
							<React.Fragment>
								<ProductHeading />

								{creatingLoan || results?.isFetching ? <CustomLoader /> : null}

								<Grid marginTop='44px'>
									<Typography variant='body1' fontSize='12px'>
										Saved Bank Accounts
									</Typography>
								</Grid>

								{/* List of bank account */}
								<Grid height='700px' overflow='auto'>
									{userBankList?.results?.banks_list?.map((bank, idx) => {
										const { selected_bank_name, acc_num, status, message, bank_account_id, is_primary } =
											bank;

										return (
											<Grid container marginTop='28px' key={bank_account_id}>
												<Grid item width='100%'>
													<Grid container justifyContent='space-between' alignItems='center'>
														<Grid item>
															<Grid display='flex' alignItems='center'>
																<img src={BankImg} alt='bank-logo' className={Styles.bankLogo} />
																<Grid
																	display='flex'
																	alignItems='flex-start'
																	flexDirection='column'
																	marginLeft='15px'>
																	<Typography variant='body1' fontSize='14px' fontWeight='500' width='250px'>
																		{`${selected_bank_name} - ${acc_num}`}
																	</Typography>

																	<Typography
																		marginTop='3px'
																		variant='body1'
																		fontSize='11px'
																		fontWeight='500'>
																		{status !== BANK_STATUS.BANK_VERIFICATION_SUCCESS ? (
																			<Grid container alignItems='center'>
																				<Grid
																					item
																					style={{
																						color:
																							status === BANK_STATUS.BANK_VERIFICATION_FAILED ||
																							status === BANK_STATUS.PENNY_DROP_VERIFICATION_FAILED
																								? '#D15757'
																								: '',
																					}}>
																					{message}
																				</Grid>
																			</Grid>
																		) : status === BANK_STATUS.BANK_VERIFICATION_SUCCESS && !is_primary ? (
																			<span
																				onClick={handleMakePrimaryAccount(bank_account_id)}
																				style={{ color: primary.main }}>
																				Make Primary Account {'>'}
																			</span>
																		) : (
																			<span style={{ color: done.main }}>Primary Account</span>
																		)}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>

														{status === BANK_STATUS.BANK_VERIFICATION_SUCCESS &&
														is_primary &&
														isDemoEnabled ? (
															<Grid item>
																<CheckCircleIcon variant='validCheck' fontSize='20px' />
															</Grid>
														) : null}

														{status === BANK_STATUS.BANK_VERIFICATION_SUCCESS && !isDemoEnabled ? (
															<Grid item>
																{selectBank === idx ? (
																	<CheckCircleIcon variant='validCheck' fontSize='20px' />
																) : (
																	<EmptyCircle onClick={() => handleBankSelection(idx, bank_account_id)} />
																)}
															</Grid>
														) : null}
													</Grid>
												</Grid>
											</Grid>
										);
									})}
								</Grid>

								{/* Add bank account  default button */}
								{!isDemoEnabled && (
									<Grid container marginTop='28px'>
										<Grid item width='100%'>
											<Grid container justifyContent='space-between' alignItems='center'>
												<Grid item>
													<Grid display='flex' alignItems='center'>
														<img src={BankImg} alt='bank-logo' className={Styles.bankLogo} />
														<Typography
															marginLeft='21px'
															variant='body1'
															fontSize='14px'
															fontWeight='500'
															onClick={movetoAddBankAccountScreen}>
															Add Bank Account
														</Typography>
													</Grid>
												</Grid>
												<Grid item onClick={movetoAddBankAccountScreen}>
													<img src={ArrowRight} alt='bank-logo' />
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								)}

								<Grid className={Styles.continueBtnContainer}>
									<CustomButton
										onClick={handleSelectBankContinue}
										disabled={isDemoEnabled ? false : !disableContinueButton}
										buttonText='Continue'
									/>
								</Grid>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Grid container direction='column' className={Styles.mainBody}>
									<Grid className={Styles.firstChildDiv}>
										<Grid>
											<img src={AddBank} alt='add-bank-logo' />
										</Grid>
										<Typography color={theme.palette.textColor.grey} className={Styles.text}>
											Please add bank account details. Ensure account belongs to you.
										</Typography>
									</Grid>
									<Grid>
										<CustomButton
											variant='contained'
											buttonText='Add Bank Account'
											clickHandler={movetoAddBankAccountScreen}
										/>
									</Grid>
								</Grid>
							</React.Fragment>
						)}
					</React.Fragment>
				)}
			</CustomHeader>
		</React.Fragment>
	);
}

const EmptyCircle = ({ onClick }) => {
	return <Grid onClick={onClick} className={Styles.emptyCircle}></Grid>;
};

export default HocWrapper(SelectBank);
