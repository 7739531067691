import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Registration from '../../Components/Layout/Registration';
import CustomNumberInput from '../../Components/HtmlElements/CustomNumberInput';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import { verifySignupOtp, ResendSignupOtp, resetOtpState } from './OtpSlice';
import CustomLoader from '../../Components/Loader/CustomLoader';
import { SaveToken, GetSavedDemoStatus, SaveRefreshToken } from '../../Utils/LocalStorageValues';
// Styling files
import Styles from './Otp.module.scss';

let interval, timer;
const RegistrationOtp = props => {
	const {
		theme,
		show_toast,
		navigate,
		get_previous_props,
		check_internet_connection,
		handle_android_back_button,
	} = props;

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const { darkBlack, grey } = theme.palette.textColor;
	const { errorColor } = theme.palette.error.main;
	const dispatch = useDispatch();
	const [otp, setOtp] = useState();
	const [otpError, setOtpError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showResendButton, setShowResendButton] = useState(false);
	const [resendOtpError, setResendOtpError] = useState(false);
	const previousScreenState = get_previous_props();
	const isLoading = useSelector(state => state.userOtpDetails.isLoading);
	const signupOtpDetails = useSelector(state => state.userOtpDetails.signupOtpDetails);
	const resendOtpDetails = useSelector(state => state.userOtpDetails.resendOtpDetails);

	const otpHandler = event => {
		const val = event.target.value && event.target.value.split('-').join('');
		if (val.length <= 6) {
			setOtp(val);
			if (otpError) {
				setOtpError(false);
				setErrorMessage('');
			}
		}
	};

	const handleResendOTP = () => {
		setShowResendButton(false);
		if (previousScreenState) {
			if (check_internet_connection()) {
				dispatch(
					ResendSignupOtp({
						mobile_number: previousScreenState.mobileNumber,
					}),
				);
			}
		}
	};

	const clearState = () => {
		// Here we are clearing the user details from the store
		dispatch(resetOtpState());
	};

	const validate = () => {
		if (otp) {
			if (otp.length === 6) {
				if (previousScreenState) {
					if (check_internet_connection()) {
						const { mobileNumber, otp_signup_id, whatsapp_consent, is_mobile_no_linked_with_aadhar } =
							previousScreenState;
						dispatch(
							verifySignupOtp({
								mobile_number: mobileNumber,
								mobile_otp: otp,
								otp_signup_id: otp_signup_id,
								whatsapp_consent: whatsapp_consent,
								is_mobile_no_linked_with_aadhar: is_mobile_no_linked_with_aadhar,
							}),
						);
					}
				}
			} else {
				setOtpError(true);
				setErrorMessage('*Please enter a valid OTP');
			}
		} else {
			setOtpError(true);
			setErrorMessage('*Please enter the OTP');
		}
	};

	const runTimer = () => {
		let element = document.getElementById('timer');
		timer = 30;
		interval = setInterval(() => {
			if (timer <= 0) {
				clearInterval(interval);
				setShowResendButton(true);
			} else {
				timer = timer - 1;
				element.textContent = timer >= 10 ? `00 : ${timer}s` : `00 : 0${timer}s`;
			}
		}, 1000);
	};

	const handleAndroidBackButton = () => {
		handle_android_back_button('closeApp');
	};

	const changeNumberHandler = () => {
		navigate('/mobile_verification', true, previousScreenState);
	};

	const validateSignupDetails = () => {
		if (signupOtpDetails) {
			if (signupOtpDetails.statusCode === 200) {
				if (signupOtpDetails.loginToken) {
					SaveToken(signupOtpDetails.loginToken);
					SaveRefreshToken(signupOtpDetails.refreshToken);
					if (window.Android) {
						window.Android.syncInfo();
						window.Android.storeAccessToken(signupOtpDetails.loginToken, signupOtpDetails.refreshToken);
						window.Android.updateFCMToken();
						window.Android.storePrefillData(+signupOtpDetails.prefill);
						window.Android.updateBuildConfigToServer();
					}
					navigate('/home', true);
				}
			} else {
				signupOtpDetails.message && show_toast(signupOtpDetails.message, 'error');
			}
		}
	};

	const validateResendOtpDetails = () => {
		if (resendOtpDetails) {
			if (resendOtpDetails.statusCode === 200) {
				runTimer();
				setOtp('');
				resendOtpDetails.message && show_toast('OTP Sent Successfully', 'info');
			} else {
				resendOtpDetails.message && show_toast(resendOtpDetails.message, 'error');
				setResendOtpError(true);
			}
		}
	};

	useEffect(() => {
		runTimer();
		show_toast('OTP Sent Successfully');
	}, []);

	useEffect(() => {
		if (otp && otp.length === 6) {
			validate();
		}
	}, [otp]);

	useEffect(() => {
		validateSignupDetails();
	}, [signupOtpDetails]);

	useEffect(() => {
		validateResendOtpDetails();
	}, [resendOtpDetails]);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			clearState();
			clearInterval(interval);
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<>
			{isLoading && <CustomLoader />}
			<CssBaseline />
			<Registration heading='OTP Verification' subheading='Verify via OTP'>
				<Grid className={Styles.numberText}>
					<Typography color={darkBlack} className={Styles.otpText} variant='body1'>
						Enter 6-digit code we have sent to <br />
						+91 {previousScreenState && previousScreenState.mobileNumber}.{' '}
						{isDemoEnabled ? null : <strong onClick={changeNumberHandler}>Change Number?</strong>}
					</Typography>
				</Grid>
				<Grid className={Styles.otpInputGrid}>
					<CustomNumberInput
						id='otp'
						name='otp'
						label='OTP'
						value={otp}
						onChangeHandler={otpHandler}
						maskFormat={'000-000'}
					/>
					{otpError && (
						<Typography className={Styles.errorText} color={errorColor}>
							<em>{errorMessage}</em>
						</Typography>
					)}
					{!showResendButton && (
						<Typography id='timer' className={Styles.timerText} color={grey}>
							{resendOtpError ? '' : '00 : 30s'}
						</Typography>
					)}
					{/* show re-send OTP */}
					{showResendButton && (
						<Grid className={Styles.resendOtpGrid}>
							<Typography className={Styles.resendText} color={grey}>
								Did not receive OTP yet?
							</Typography>
							<CustomButton
								id='resendOtp'
								clickHandler={handleResendOTP}
								buttonText='Resend OTP'
								fullWidth={false}
								size='small'
							/>
						</Grid>
					)}
				</Grid>
			</Registration>
		</>
	);
};

export default HocWrapper(RegistrationOtp);
