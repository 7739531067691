import RoutesList from './Routing/Routes';
import { Theme } from './Assets/CustomStyles/MaterialTheme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';

function App() {
	return (
		<div className='App'>
			<CssBaseline />
			<ThemeProvider theme={Theme}>
				<RoutesList />
			</ThemeProvider>
		</div>
	);
}

export default App;
