import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import React, { useEffect } from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomCard from '../../Components/Layout/CustomCard';
import CustomRadio from '../../Components/HtmlElements/CustomRadio';
import CustomInput from '../../Components/HtmlElements/CustomInput';
import CustomNumberInput from '../../Components/HtmlElements/CustomNumberInput';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomLoader from '../../Components/Loader/CustomLoader';
import { useFormik } from 'formik';

import { useLocation } from 'react-router-dom';

// Formik Schema
import { referenceInfoSchema } from '../../FormikSchema/ReferenceContactInfo';

// Stylesheet
import Styles from './ReferenceContact.module.scss';

// RTK Query
import {
	useGetRefContactRadioButtonsQuery as getRadioButtons,
	useSaveRefContactMutation,
	useUpdateDemoUserStatusMutation,
} from '../../Services/apiSlice';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

import { validateUserInput } from '../../Utils/utilities';

// Enum
import { ROUTE, USER_STATUS } from '../../Utils/Enum';

const isFormFieldIsEmpty = obj => Object.values(obj).every(formVal => formVal !== '');

function ReferenceContactFamily(props) {
	const { navigate, check_internet_connection, show_toast, handle_android_back_button } = props;
	const { textColor, primary, background, error } = props.theme.palette;

	const location = useLocation();

	const isDemoEnabled = GetSavedDemoStatus();

	const { data: radioButtons, isLoading } = getRadioButtons();
	const [saveRefContact, { isLoading: savingData }] = useSaveRefContactMutation();
	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();

	const editedRefContactId = location?.state?.famRefState?.edited_reference_contact_id;

	const IsSingle = radioButtons?.result?.filter(
		({ type, value }) => type === 'FAMILY' && (value === '1' || value === '2'),
	);

	const IsMarried = radioButtons?.result?.filter(
		({ type, value }) => type === 'FAMILY' && (value === '5' || value === '3'),
	);
	const dataForSingle = IsSingle?.map(i => ({
		label: i.name,
		value: i.value,
	}));

	const dataForMarried = IsMarried?.map(i => ({
		label: i.name,
		value: i.value,
	}));

	const formik = useFormik({
		initialValues: {
			marital_status: 'single',
			parents: '',
			spouse: '',
			reference_contact_name: '',
			mobile_number: '',
		},
		validationSchema: isDemoEnabled === 'true' ? null : referenceInfoSchema,
		onSubmit: async formValues => {
			let reqBody = {
				reference_type_id: formValues.marital_status === 'single' ? formValues.parents : formValues.spouse,
				edited_reference_contact_id: editedRefContactId ? editedRefContactId : null,
				contact_name: formValues.reference_contact_name,
				mobile_number: formValues.mobile_number.split('-').join(''),
				marital_status: formValues.marital_status,
			};

			if (check_internet_connection()) {
				try {
					const response = await saveRefContact(reqBody).unwrap();
					if (response?.status === 200) {
						if (isDemoEnabled === 'true') {
							navigate('/' + ROUTE.REFERENCE_CONTACT_FRIEND, true);
						} else {
							navigate('/' + ROUTE.REFERENCE_FAMILY_OTP, true, { mobNum: formik.values.mobile_number });
						}
					} else {
						show_toast(response?.message, 'error');
					}
				} catch {
					show_toast('An exception occured while saving the data', 'error');
				}
			}
		},
	});
	const { reference_contact_name, mobile_number } = formik.errors;
	const { marital_status, parents, spouse } = formik.values;

	const handleAndroidBackButton = () => {
		if (isDemoEnabled === 'true') {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.BASIC_INFO_PENDING,
						}).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('closeApp');
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			navigate('/' + ROUTE.HOME, true);
		}
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		if (location?.state) {
			if (location?.state.famRefState) {
				let { marital_status, value, name, mobile_num } = location?.state?.famRefState;
				if (marital_status === 'single') {
					formik.setFieldValue('parents', value);
				} else {
					formik.setFieldValue('spouse', value);
				}
				formik.setFieldValue('marital_status', marital_status);
				formik.setFieldValue('reference_contact_name', name);
				formik.setFieldValue('mobile_number', mobile_num);
			} else {
				formik.setFieldValue('reference_contact_name', '');
				formik.setFieldValue('mobile_number', '');

				if (marital_status === 'married') {
					// setting defaultChecked to spouse when marital status changed
					formik.setFieldValue('spouse', dataForMarried?.[0]?.value);
					formik.setFieldValue('parents', '');
				} else {
					// else, resetting state to make reference name and mobile input disabled
					formik.setFieldValue('spouse', '');
				}
			}
		} else {
			if (marital_status === 'married') {
				// setting defaultChecked to spouse when marital status changed
				formik.setFieldValue('spouse', dataForMarried?.[0]?.value);
				formik.setFieldValue('parents', '');
			} else {
				// else, resetting state to make reference name and mobile input disabled
				formik.setFieldValue('spouse', '');
			}
		}
		return () => {
			let clonedState = { ...location.state };
			if (formik.values.marital_status && (formik.values.parents || formik.values.spouse)) {
				delete clonedState.famRefState;
				location.state = clonedState;
			}
		};
	}, [marital_status, parents]);

	let disableSubmitButton;
	let updatedFormState = { ...formik.values };

	if (formik.values.marital_status === 'single') {
		delete updatedFormState.spouse;
		let allFormIsFilled = isFormFieldIsEmpty(updatedFormState);
		disableSubmitButton = !allFormIsFilled || !formik.isValid || formik.values.mobile_number.length !== 10;
	} else {
		delete updatedFormState.parents;
		let allFormIsFilled = isFormFieldIsEmpty(updatedFormState);

		disableSubmitButton = !allFormIsFilled || !formik.isValid || formik.values.mobile_number.length !== 10;
	}

	// prefill data for demoApp
	useEffect(() => {
		if (isDemoEnabled === 'true') {
			if (formik.values.parents !== '2' && formik.values.marital_status !== 'married') {
				formik.setFieldValue('parents', '1');
				formik.setFieldValue('reference_contact_name', 'Father Test');
				formik.setFieldValue('mobile_number', '7853966737');
			}
		}
	}, [marital_status, parents]);

	return (
		<React.Fragment>
			{isLoading || savingData || updatingUsrStatus ? <CustomLoader /> : null}

			<Grid container className={Styles.ParentMainContainer}>
				<FormControl fullWidth component='form' onSubmit={formik.handleSubmit}>
					<Grid container className={Styles.heading} backgroundColor={primary.main}>
						<Grid marginLeft='15px'>
							<Typography fontSize='22px' color={textColor.white}>
								Reference Contact Detail
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						direction='column'
						className={Styles.MainBody}
						backgroundColor={background.secondary}>
						<Grid>
							<CustomCard>
								<Grid>
									<Typography className={Styles.SubheadingText}>Marital Status</Typography>
									<CustomRadio
										{...formik.getFieldProps('marital_status')}
										data={[
											{ label: 'Single', value: 'single' },
											{ label: 'Married', value: 'married' },
										]}
									/>
								</Grid>
							</CustomCard>
						</Grid>
						<Grid>
							<CustomCard>
								<Grid container flexDirection='column' rowGap='20px'>
									<Grid>
										<Typography className={Styles.ReferenceRadioText}>Select Reference Contact</Typography>
										{formik.values.marital_status === 'single' ? (
											<CustomRadio {...formik.getFieldProps('parents')} data={dataForSingle} />
										) : (
											<CustomRadio {...formik.getFieldProps('spouse')} data={dataForMarried} />
										)}
									</Grid>
									<Grid>
										<CustomInput
											label='Enter Reference Name'
											error={reference_contact_name ? true : false}
											helperText={reference_contact_name}
											disabled={parents || spouse ? false : true}
											onChangeHandler={e =>
												validateUserInput(e.target.value, {
													inputName: 'reference_contact_name',
													setterFun: formik.setFieldValue,
													validate: 'user-name',
												})
											}
											value={formik.values.reference_contact_name}
										/>
									</Grid>

									<Grid container className={Styles.MobileNumberFieldContainer}>
										<Grid className={Styles.MobileNumberAddon} color={textColor.darkBlack}>
											+91
										</Grid>
										<Grid className={Styles.InputNumberField}>
											<CustomNumberInput
												label='Enter Reference Mobile Number'
												maskFormat={'000-000-0000'}
												error={mobile_number ? true : false}
												disabled={parents || spouse ? false : true}
												value={formik.values.mobile_number}
												onChange={e => {
													let userInput = e.target.value;
													formik.setFieldValue('mobile_number', userInput && userInput.split('-').join(''));
												}}
											/>
										</Grid>
									</Grid>
									{mobile_number && (
										<Typography className={Styles.errorText} color={error.main}>
											<em>*Please enter valid mobile number</em>
										</Typography>
									)}
								</Grid>
							</CustomCard>
						</Grid>
					</Grid>
					<Grid container className={Styles.Footer} backgroundColor={background.secondary}>
						<CustomButton
							type='submit'
							buttonText='Save and Continue'
							disabled={isDemoEnabled === 'true' ? false : disableSubmitButton}
						/>
					</Grid>
				</FormControl>
			</Grid>
		</React.Fragment>
	);
}

export default HocWrapper(ReferenceContactFamily);
