import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CustomHeader from '../../Components/Layout/CustomHeader';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import Api from '../../Utils/Api';
import CustomLoader from '../../Components/Loader/CustomLoader';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import LoanRepaymentSuccess from './LoanRepaymentSuccess';
import LoanRepaymentFail from './LoanRepaymentFail';
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';
import callRefreshToken from '../../Utils/RefreshToken.js';
// Styles
import Styles from './LoanRepayment.module.scss';

// Enum
import { ROUTE, LOAN_STATUS } from '../../Utils/Enum';

function LoanRepaymentDetails(props) {
	const {
		theme,
		show_toast,
		get_previous_props,
		handle_android_back_button,
		go_back,
		navigate,
		check_internet_connection,
	} = props;
	const [loanDetails, setLoanDetails] = useState(null);
	const [paymentGatewayList, setPaymentGatewayList] = useState(null);
	const [totalRepaymentAmount, setTotalRepaymentAmount] = useState(null);
	const [showSuccessPopup, setShowSuccessPopup] = useState(false);
	const [showFailPopup, setShowFailPopup] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	useEffect(() => {
		callPaymentOptionsApi();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		window.addEventListener('loanRepaymentStatus', handlePaymentStatusFromAndroid);
		return () => {
			window.removeEventListener('loanRepaymentStatus', handlePaymentStatusFromAndroid);
		};
	});

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
		// eslint-disable-next-line
	}, []);

	const callPaymentOptionsApi = () => {
		const loanId = get_previous_props();
		if (loanId) {
			const apiBody = {
				loan_id: loanId,
				asp_type: 'payment',
				fore_close_loan: 0,
				coupon_application_id: 0,
				recall_loan: 0,
			};
			if (check_internet_connection) {
				setIsLoading(true);
				Api('post', `borrower_apk/repayments/options`, apiBody, 'thirdParty')
					.then(async res => {
						if (res?.data?.status === 401) {
							const token = await callRefreshToken();
							if (token) callPaymentOptionsApi();
						} else {
							if (res?.data?.results) {
								if (res.data.results.payment_gateway_list) {
									setPaymentGatewayList(res.data.results.payment_gateway_list);
								}
								if (res.data.results.loan_details) {
									setLoanDetails(res.data.results.loan_details);
								}
								setTotalRepaymentAmount(res.data.results.repayment_amount);
							} else {
								show_toast('No data found!', 'error');
							}
							setIsLoading(false);
						}
					})
					.catch(err => {
						setIsLoading(false);
						show_toast('Failed to load!', 'error');
					});
			}
		}
	};

	const callPaymentInitiateApi = apiBody => {
		if (check_internet_connection) {
			setIsLoading(true);
			Api('post', `borrower_apk/repayments/initiate`, apiBody, 'thirdParty')
				.then(async res => {
					if (res?.data?.status === 401) {
						const token = await callRefreshToken();
						if (token) callPaymentOptionsApi();
					} else {
						if (res?.data?.results?.repayment_transaction?.properties) {
							const {
								rp_currency,
								rp_amount,
								rp_order_id,
								buyer_email,
								rp_key,
								loan_id,
								repayment_transaction_id,
								loan_schedule_id,
							} = res.data.results.repayment_transaction.properties;
							localStorage.setItem('_rpTransId', repayment_transaction_id);
							localStorage.setItem('_loanScheduleId', loan_schedule_id);
							if (isDemoEnabled) {
								return callRazorpayCallBack(null);
							} else {
								if (window.Android) {
									window.Android.launchRazorpay(
										'Loan Repayment for',
										rp_currency,
										rp_amount,
										rp_order_id,
										buyer_email,
										rp_key,
										loan_id && loan_id.toString(),
										'https://peoplefront-public-assets.s3.ap-south-1.amazonaws.com/demo_user/peoplefront-feature-icon.png',
									);
								}
							}
						} else {
							show_toast('No data found!', 'error');
						}
						setIsLoading(false);
					}
				})
				.catch(err => {
					setIsLoading(false);
					show_toast('Failed to load!', 'error');
				});
		}
	};

	const callRazorpayCallBack = repaymentId => {
		const loanId = get_previous_props();
		const apiBody = {
			loan_id: loanId,
			repayment_transaction_id: localStorage.getItem('_rpTransId'),
			rp_payment_id: repaymentId,
			loan_schedule_id: localStorage.getItem('_loanScheduleId'),
		};
		if (check_internet_connection) {
			setIsLoading(true);
			Api('post', `borrower_apk/repayments/razorpay/callback`, apiBody, 'thirdParty')
				.then(async res => {
					if (res?.data?.status === 401) {
						const token = await callRefreshToken();
						if (token) callPaymentOptionsApi();
					} else {
						if (res?.data?.results?.message) {
							if (res.data.results.message === LOAN_STATUS.REPAYMENT_TRANSACTION_SUCCESS) {
								if (res.data.results.is_emi_closed === 1) {
									navigate('/' + ROUTE.LOAN_CLOSED, true, get_previous_props());
								} else {
									setShowSuccessPopup(true);
								}
							} else if (res.data.results.message === LOAN_STATUS.REPAYMENT_TRANSACTION_FAIL) {
								setShowFailPopup(true);
							} else {
								return;
							}
						} else {
							show_toast('No data found!', 'error');
						}
						setIsLoading(false);
					}
				})
				.catch(err => {
					setIsLoading(false);
					show_toast('Failed to load!', 'error');
				});
		}
	};

	const paynow = () => {
		const loanId = get_previous_props();
		if (loanId) {
			const data = {
				loan_id: loanId,
				pg_code: 'RP',
				request_type: 'APK',
			};
			return callPaymentInitiateApi(data);
		}
	};

	const handlePaymentStatusFromAndroid = event => {
		if (event?.detail?.paymentId) {
			callRazorpayCallBack(event.detail.paymentId);
		}
	};

	const handleModalClose = (event, reason) => {
		if (reason === 'backdropClick') {
			return;
		} else {
			setShowSuccessPopup(false);
			go_back();
		}
	};

	const handleAndroidBackButton = () => handle_android_back_button('goBack');

	return isLoading ? (
		<CustomLoader />
	) : (
		<CustomHeader header='Loan Repayment' handleBackButton={go_back}>
			{showSuccessPopup && (
				<LoanRepaymentSuccess
					handleModalClose={handleModalClose}
					showPopup={showSuccessPopup}
					amount={totalRepaymentAmount}
				/>
			)}
			{showFailPopup && <LoanRepaymentFail handleModalClose={handleModalClose} showPopup={showFailPopup} />}
			<Grid container direction='column' className={Styles.paymentOptionsMainContainer}>
				<Grid
					container
					direction='column'
					className={Styles.repaymentDetails}
					backgroundColor={theme.palette.background.secondary}>
					<Grid className={Styles.mainHeadingGrid}>
						<Typography className={Styles.heading} color={theme.palette.textColor.darkBlack} fontWeight='600'>
							Loan Repayment
						</Typography>
					</Grid>
					<Divider />
					{loanDetails && loanDetails.length > 0 && (
						<Grid container direction='column' className={Styles.repaymentDetailList}>
							{loanDetails.map(el => {
								if (el.title !== 'Repayment Amount') {
									return (
										<Grid container direction='row' my='4px' justifyContent='space-between'>
											<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.grey}>
												{el.title}
											</Typography>
											<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.darkBlack}>
												{el.description}
											</Typography>
										</Grid>
									);
								}
							})}
							<Grid mt='8px' mb='12px'>
								<Divider light={false} />
							</Grid>
							<Grid container direction='row' mb='8px' justifyContent='space-between'>
								<Typography
									variant='body1'
									fontSize='12px'
									fontWeight='700'
									color={theme.palette.textColor.grey}>
									Repayment Amount
								</Typography>
								<Typography
									variant='body1'
									fontSize='12px'
									fontWeight='700'
									color={theme.palette.textColor.darkBlack}>
									{`₹ ${totalRepaymentAmount}`}
								</Typography>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid container direction='column' mb='32px'>
					<Grid>
						<CustomButton buttonText='Pay Via Payment Gateway' clickHandler={paynow} />
						<Typography
							variant='body1'
							fontSize='12px'
							mt={'4px'}
							color={theme.palette.textColor.darkBlack}
							className={Styles.text}>
							[<b>Convenience fee may charged</b> by payment gateways]
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</CustomHeader>
	);
}

export default HocWrapper(LoanRepaymentDetails);
