import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import React, { useState, useEffect } from 'react';
import CustomCheckbox from '../../Components/HtmlElements/CustomCheckbox';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomLoader from '../../Components/Loader/CustomLoader';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
// svg
import SuccessTick from '../../Assets/Icons/SuccessTick.svg';
// Styles
import Styles from './UpgradeAmount.module.scss';

// utils
import { localeCurrencyFormat } from '../../Utils/utilities';

// RTK
import { useUpdateNewProductMutation } from '../../Services/apiSlice';

// localStorageValues
import { SaveProductId } from '../../Utils/LocalStorageValues';

function UpgradeAmount(props) {
	const [acceptTerms, setAcceptTerms] = useState(true);

	const {
		theme,
		navigate,
		get_previous_props,
		check_internet_connection,
		show_toast,
		handle_android_back_button,
	} = props;

	const [submitNewProduct, { isLoading: submittingNewProduct }] = useUpdateNewProductMutation();

	const updatedProductData = get_previous_props()?.updatedProductData;

	const oldAmount = updatedProductData?.old_amount;
	const upgradedAmount = updatedProductData?.new_amount;
	const isProductUpgraded = updatedProductData?.is_product_upgraded === 1;
	const oldProductTypeId = updatedProductData?.old_product_type_id;
	const newProductTypeId = updatedProductData?.new_product_type_id;

	const amountDescription = `Based on your credit profile reassessment and financial behavior, we can increase your loan
	amount from ${localeCurrencyFormat(oldAmount && oldAmount, 'en-US', {
		style: 'currency',
		currency: 'INR',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	})} to ${localeCurrencyFormat(upgradedAmount && upgradedAmount, 'en-US', {
		style: 'currency',
		currency: 'INR',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	})}.`;

	const termsAndConditionsHandler = e => setAcceptTerms(e.target.checked);

	const handleUserChoice = e => {
		const userSelectedChoice = e.target.name;

		if (isProductUpgraded !== null && userSelectedChoice && (newProductTypeId || oldProductTypeId)) {
			(async function updateUserChoice() {
				const reqBody = {
					screen_type: isProductUpgraded ? 'loan_upgrade_popup' : 'loan_degrade_popup',
					product_type_id: userSelectedChoice === 'APPROVED' ? newProductTypeId : oldProductTypeId,
					consent: userSelectedChoice,
				};

				try {
					const response = await submitNewProduct(reqBody).unwrap();

					if (response?.status === 200) {
						if (response?.result?.product_type_id) {
							SaveProductId(response?.result?.product_type_id);
							if (check_internet_connection()) {
								navigate('/purpose_of_loan', true);
							}
						}
					} else {
						show_toast(response?.message, 'error');
					}
				} catch {
					show_toast('something went wrong! Try again.');
				}
			})();
		} else {
			console.error('some params missing to make api call');
		}
	};

	const handleAndroidBackButton = () => handle_android_back_button('goBack');

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<React.Fragment>
			{submittingNewProduct && <CustomLoader />}

			<Grid
				container
				direction='column'
				className={Styles.mainContainer}
				backgroundColor={theme.palette.background.secondary}>
				<Grid
					container
					direction='column'
					backgroundColor={theme.palette.background.primary}
					className={Styles.card}>
					<Grid container direction='column' rowGap='10px' className={Styles.cardHeader}>
						<img src={SuccessTick} alt='success-tick' />
						<Typography variant='body1' className={Styles.headerText}>
							Congratulations!
						</Typography>
					</Grid>
					<Grid>
						<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					</Grid>
					<Grid container direction='column' className={Styles.cardBody}>
						<Grid>
							<Typography
								variant='body1'
								fontSize='14px'
								fontWeight='600'
								color={theme.palette.textColor.darkBlack}>
								Loan Amount Upgraded
							</Typography>
							<Typography pt='10px' variant='body1' fontSize='14px' color={theme.palette.textColor.grey}>
								{amountDescription}
							</Typography>
						</Grid>
						<Grid className={Styles.LoanAmount}>
							<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.darkBlack}>
								Get loan upto
							</Typography>
							<Typography
								variant='body1'
								fontSize='24px'
								fontWeight='600'
								color={theme.palette.textColor.green}>
								{localeCurrencyFormat(upgradedAmount && upgradedAmount, 'en-US', {
									style: 'currency',
									currency: 'INR',
									minimumFractionDigits: 0,
									maximumFractionDigits: 0,
								})}
							</Typography>
						</Grid>
						<Grid container direction='row' className={Styles.permissionConsent}>
							<CustomCheckbox
								checked={acceptTerms}
								onChangeHanlder={termsAndConditionsHandler}
								className={Styles.customCheckbox}
							/>
							<Typography variant='body1' fontSize='12px' className={Styles.text}>
								I provide my consent to increase my loan amount.
							</Typography>
						</Grid>

						<Grid container direction='column' rowGap='18px'>
							<CustomButton
								name='APPROVED'
								buttonText='Agree to Continue'
								disabled={!acceptTerms}
								clickHandler={handleUserChoice}
							/>
							<CustomButton
								name='REJECTED'
								buttonText='Keep My Current Limit'
								disabled={acceptTerms}
								clickHandler={handleUserChoice}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default HocWrapper(UpgradeAmount);
