import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { DropDown } from '../../Components/HtmlElements';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomHeader from '../../Components/Layout/CustomHeader';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import ProductHeading from '../Product/ProductHeading';

// styles
import Styles from './PurposeOfLoan.module.scss';

import {
	useGetPurposeOfLoanDropdowndataQuery,
	useSubmitPurposeOfLoanMutation,
	useGetRefContactStatusQuery,
	useGetPartialLoandetailsQuery,
	useUpdateDemoUserStatusMutation,
} from '../../Services/apiSlice';

import CustomLoader from '../../Components/Loader/CustomLoader';
import { useFormik } from 'formik';
import FormControl from '@mui/material/FormControl';

// utils
import { getUserMobileDetailsFromAndroid } from '../../Utils/AndroidHelper';

// localStorage Values
import { GetSavedProductId, SaveApplicationId, GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

// locale
import { localeCurrencyFormat } from '../../Utils/utilities';

// Enum
import { ROUTE, USER_STATUS, REF_CONTACT_STATUS } from '../../Utils/Enum';

function PurposeOfLoan(props) {
	const { theme, navigate, handle_android_back_button, check_internet_connection, show_toast } = props;

	const [skipAPI, setSkipAPI] = useState(true);
	const [loanAddApiCount, setLoanAddApiCount] = useState(0);

	const savedProdId = GetSavedProductId();

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	const {
		data: refContactStatus,
		isFetching,
		isError: hasRefContactStatusAPIFailed,
		refetch: refetchRefStatus,
	} = useGetRefContactStatusQuery(undefined, { skip: skipAPI });

	const { data, isLoading } = useGetPurposeOfLoanDropdowndataQuery();
	const { data: partialProductData } = useGetPartialLoandetailsQuery(savedProdId && savedProdId);
	const [submitLoanPurpose, { isLoading: isSubmitting }] = useSubmitPurposeOfLoanMutation();
	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();

	const productDetailsData = partialProductData?.result?.productDetails;
	const productTypeId = partialProductData?.result?.product_type_id;

	const currentRefStatus = refContactStatus?.result?.reference_contact_status;
	const dropdownValues = data?.result?.purpose_of_loan;

	const formik = useFormik({
		initialValues: {
			purposeOfLoanDropdown: '',
		},
	});
	let disableSubmitButton = formik.values.purposeOfLoanDropdown === 0;

	const handleAndroidBackButton = () => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.BASIC_INFO_PENDING,
						}).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('closeApp');
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			handle_android_back_button('goBack');
		}
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
			setLoanAddApiCount(0);
		};
	}, []);

	useEffect(() => {
		if (isDemoEnabled) {
			formik.setFieldValue('purposeOfLoanDropdown', 1);
		} else {
			formik.setFieldValue('purposeOfLoanDropdown', 0);
		}
	}, []);

	useEffect(() => {
		if (isDemoEnabled && !skipAPI) {
			navigate('/' + ROUTE.SELECT_BANK, true);
		} else {
			if (hasRefContactStatusAPIFailed) {
				show_toast('Exception occured while fetching reference status');
			} else {
				if (currentRefStatus === REF_CONTACT_STATUS.FAMILY_REFERENCE_OTP_VERIFICATION_PENDING) {
					navigate('/' + ROUTE.REFERENCE_FAMILY_OTP, true, { disableSkipAndEditButton: true });
				} else if (currentRefStatus === REF_CONTACT_STATUS.FRIEND_REFERENCE_OTP_VERIFICATION_PENDING) {
					navigate('/' + ROUTE.REFERENCE_FRIEND_OTP, true, { disableSkipAndEditButton: true });
				} else if (currentRefStatus === REF_CONTACT_STATUS.ADD_BANK_PENDING) {
					navigate('/' + ROUTE.SELECT_BANK, true);
				} else if (currentRefStatus === REF_CONTACT_STATUS.VKYC_PENDING) {
					if (window.Android) {
						if (
							window.Android.checkMicroPermissionEnabled() &&
							window.Android.checkCameraPermissionEnabled()
						) {
							window.Android.navigateToAndroidToReact('vkyc_pending', 'stage4');
						} else {
							navigate('/' + ROUTE.PERMISSION_FAIL, true);
						}
					}
				} else if (currentRefStatus === REF_CONTACT_STATUS.LOAN_ELIGIBILITY_FAILED_SCREEN) {
					navigate('/' + ROUTE.PAN_CHECK_FAIL, true);
				} else if (currentRefStatus === REF_CONTACT_STATUS.VKYC_WAITING) {
					navigate('/' + ROUTE.VKYC_INPROGRESS, true);
				} else if (currentRefStatus === REF_CONTACT_STATUS.KYC_MANUAL_SCREEN) {
					navigate('/' + ROUTE.KYC_MANUAL_VERIFICATION, true);
				}
			}
		}
	}, [refContactStatus]);

	const submitPurposeOfLoan = async () => {
		setLoanAddApiCount(prevState => prevState + 1);

		let mobileDetails;
		try {
			mobileDetails = await getUserMobileDetailsFromAndroid();
		} catch (err) {
			mobileDetails = err;
		}
		const lat = localStorage.getItem('_latitude');
		const long = localStorage.getItem('_longitude');

		if (productTypeId !== null) {
			if (check_internet_connection()) {
				(async function submitPurpose() {
					const reqBody = {
						coupon_application_id: '',
						product_type_id: productTypeId,
						latitude: lat,
						longitude: long,
						wifi_status: mobileDetails?.wifiStatus,
						wifi_name: mobileDetails?.wifiName,
						ip_address: mobileDetails ? mobileDetails.ipAddress : '42.111.160.45',
						purpose_id: formik.values.purposeOfLoanDropdown,
					};
					try {
						// application add API
						const response = await submitLoanPurpose(reqBody).unwrap();

						if (response?.status === 200) {
							let applicationId = response?.result?.application_id;

							// loanAddApiCount variable keeps track of number of times user tries to submit purpose of loan
							// this variable is used to refetch refcontact API in case it is failed at 1st call.
							// setting setSkiAPI to false everytime will not make the api call again n again.
							if (applicationId && loanAddApiCount === 0) {
								// saving application ID and fetching refstatus API by setting setSkipAPI state to false
								SaveApplicationId(applicationId);
								setSkipAPI(false);
							} else {
								if (applicationId) {
									try {
										const response = await refetchRefStatus().unwrap();

										if (response?.status !== 200) {
											show_toast(response?.message, 'error');
										}
									} catch {
										show_toast('Something went wrong. Please try again', 'error');
									}
								} else {
									show_toast('application Id is missing');
								}
							}
						} else {
							show_toast(response?.message, 'error');
						}
					} catch (err) {
						show_toast(err?.data?.message);
					}
				})();
			}
		}
	};

	return (
		<>
			{isLoading || isSubmitting || isFetching || updatingUsrStatus ? <CustomLoader /> : null}

			<CustomHeader header='Purpose of Loan' handleBackButton={handleAndroidBackButton}>
				<FormControl className={Styles.mainContainer}>
					<Grid container direction='column' rowGap='22px'>
						<ProductHeading />

						<Grid>
							<DropDown
								sx={{
									pt: 1,
									'& .MuiNativeSelect-select': { pt: '8.5px' },
								}}
								label='What is the purpose of this loan ?'
								{...formik.getFieldProps('purposeOfLoanDropdown')}>
								{dropdownValues &&
									dropdownValues.map(item => (
										<MenuItem key={item.purpose_id} value={item.purpose_id} disabled={item.purpose_id === 0}>
											{item.purpose_name}
										</MenuItem>
									))}
							</DropDown>
						</Grid>

						<Grid
							container
							direction='column'
							className={Styles.loanDetails}
							backgroundColor={theme.palette.background.secondary}>
							<Grid>
								<Typography color={theme.palette.textColor.darkBlack} fontWeight='600'>
									Loan Details
								</Typography>
							</Grid>
							<Grid paddingTop='10px'>
								<Divider />
							</Grid>
							<Grid container direction='column' rowGap='8px' className={Styles.loanDetailListContainer}>
								<Grid container direction='row' justifyContent='space-between'>
									<Typography variant='body1' fontSize='10px' color={theme.palette.textColor.grey}>
										Lifetime Registration Fee
									</Typography>

									<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.darkBlack}>
										₹ {productDetailsData?.ltrf_amt}
									</Typography>
								</Grid>

								<Grid container direction='row' justifyContent='space-between'>
									<Typography variant='body1' fontSize='10px' color={theme.palette.textColor.grey}>
										Processing Fee
									</Typography>

									<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.darkBlack}>
										₹ {productDetailsData?.processing_fee}
									</Typography>
								</Grid>

								<Grid container direction='row' justifyContent='space-between'>
									<Typography variant='body1' fontSize='10px' color={theme.palette.textColor.grey}>
										Loan Agreement Fee
									</Typography>

									<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.darkBlack}>
										₹ {productDetailsData?.agreement_fee}
									</Typography>
								</Grid>
								<Grid container direction='row' justifyContent='space-between'>
									<Typography variant='body1' fontSize='10px' color={theme.palette.textColor.grey}>
										Interest @{productDetailsData?.monthly_interest_rate_pct}% monthly
									</Typography>

									<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.darkBlack}>
										₹ {productDetailsData?.interest_amount}
									</Typography>
								</Grid>
								<Grid container direction='row' justifyContent='space-between'>
									<Typography variant='body1' fontSize='10px' color={theme.palette.textColor.grey}>
										GST Amount
									</Typography>

									<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.darkBlack}>
										₹ {productDetailsData?.gst_amount}
									</Typography>
								</Grid>
								<Grid paddingTop='6px'>
									<Divider />
								</Grid>
							</Grid>
							<Grid container direction='row' justifyContent='space-between' pt='6px'>
								<Typography
									variant='body1'
									fontSize='12px'
									fontWeight='600'
									color={theme.palette.textColor.grey}>
									Disbursal Amount
								</Typography>

								<Typography
									variant='body1'
									fontSize='12px'
									fontWeight='500'
									color={theme.palette.textColor.darkBlack}>
									{localeCurrencyFormat(productDetailsData?.disbursal_amount, 'en-US', {
										style: 'currency',
										currency: 'INR',
										minimumFractionDigits: 0,
										maximumFractionDigits: 0,
									})}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid>
						{productTypeId && (
							<CustomButton
								clickHandler={submitPurposeOfLoan}
								buttonText='Continue'
								disabled={isDemoEnabled ? false : disableSubmitButton}
							/>
						)}
					</Grid>
				</FormControl>
			</CustomHeader>
		</>
	);
}

export default HocWrapper(PurposeOfLoan);
