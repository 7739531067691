import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../Utils/Api';

const verifyEmailApiUrl =
	'borrower_apk/forgot_password/otp/send?v=1000&latitude=12.960680890828371&longitude=77.63665560632944';

export const verifyEmail = createAsyncThunk('ForgotPassword/verifyEmailApi', async apiBody => {
	return Api('post', verifyEmailApiUrl, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data && response.data.status !== 200) {
					return {
						apiMessage: response.data.message,
						userStatus: response.data.result && response.data.result.status,
						statusCode: response.data.status,
						apiResponse: response.data,
					};
				} else {
					if (response.data.result) {
						return {
							apiMessage: response.data.message,
							statusCode: response.data.status,
							otpId: response.data.result.email_otp_id && response.data.result.email_otp_id,
							apiResponse: response.data,
							is_demo_user: response?.data?.result?.is_demo_user,
						};
					}
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return {
					apiMessage: err.response.data.message,
				};
			}
		});
});

const initialState = {
	isLoading: false,
	verifyEmailDetails: {
		message: '',
		emailOtpId: '',
		statusCode: '',
		apiResult: '',
		is_demo_user: '',
	},
};

const forgotPasswordSlice = createSlice({
	name: 'forgotPassword',
	initialState,
	reducers: {
		resetForgotPasswordState: state => {
			state.isLoading = false;
			state.verifyEmailDetails = {
				message: '',
				emailOtpId: '',
				statusCode: '',
				apiResult: '',
				is_demo_user: '',
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(verifyEmail.pending, state => {
			state.isLoading = true;
		});
		builder.addCase(verifyEmail.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload) {
				state.verifyEmailDetails.message = payload.apiMessage;
				state.verifyEmailDetails.emailOtpId = payload.otpId;
				state.verifyEmailDetails.statusCode = payload.statusCode;
				state.verifyEmailDetails.apiResult = payload.apiResponse;
				state.verifyEmailDetails.is_demo_user = payload.is_demo_user;
			}
		});
		builder.addCase(verifyEmail.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.verifyEmailDetails.message = payload && payload.apiMessage;
		});
	},
});

export const { resetForgotPasswordState } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
