import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import SuccessIcon from '../../Assets/Icons/SuccessTickIcon.svg';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// Styles
import Styles from './CreatePasswordSuccess.module.scss';

function CreatePasswordSuccess(props) {
	const { theme, navigate, handle_android_back_button } = props;

	const moveToMobileVrificationScreen = () => navigate('/mobile_verification', true);

	const handleAndroidBackButton = () => handle_android_back_button('closeApp');

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<Grid
			container
			direction='column'
			backgroundColor={theme.palette.primary.main}
			className={Styles.ParentContainer}>
			<Grid className={Styles.FirstChildContainer}>
				<img src={SuccessIcon} alt='success-img' />
				<Typography variant='h6' color={theme.palette.textColor.white} className={Styles.Textheader}>
					Password Created Successfully
				</Typography>
			</Grid>
			<Grid className={Styles.SecondChildContainer} backgroundColor={theme.palette.background.primary}>
				<Typography
					variant='body1'
					color={theme.palette.textColor.grey}
					className={Styles.SuccessMessageText}>
					You have successfully created a new password. Keep your account safe and secure.
				</Typography>
				<CustomButton buttonText='Continue' clickHandler={moveToMobileVrificationScreen} />
			</Grid>
		</Grid>
	);
}

export default HocWrapper(CreatePasswordSuccess);
