import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Slider from 'react-slick';
import DeepClone from '../../Utils/DeepClone';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomLoader from '../../Components/Loader/CustomLoader';
import callRefreshToken from '../../Utils/RefreshToken';
import { GetSavedProfiePic, GetSavedDemoStatus } from '../../Utils/LocalStorageValues';
import Api from '../../Utils/Api';
import { downloadDocUsingAndroid } from '../../Utils/AndroidHelper';
// Styling Files
import Styles from './LoanRepayment.module.scss';
// Icons
import P2PLogo from '../../Assets/Icons/P2pLogo.svg';
import ProfileAvatar from '../../Assets/Images/AccountIcon.png';
import NotificationIcon from '../../Assets/Icons/NotificationIcon.svg';
import CarouselImg from '../../Assets/Images/product-carousel-img.svg';
import documentIcon from '../../Assets/Icons/LoanDocsIcon.svg';
import downloadIcon from '../../Assets/Icons/DownloadIcon.svg';
// RTK Query
import { useGetProductsQuery } from '../../Services/apiSlice';

// Enum
import { ROUTE, LOAN_STATUS } from '../../Utils/Enum';

const settings = {
	infinite: true,
	dots: false,
	slidesToShow: 1,
	mobileFirst: true,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
};
function RepaymentHomeScreen(props) {
	const { navigate, handle_android_back_button, check_internet_connection, show_toast } = props;

	const profilePic = GetSavedProfiePic();
	const [isLoading, setIsLoading] = useState(false);
	const [Selected, setSelected] = useState({
		currentEmi: true,
		emiSchedule: false,
	});
	const [loanDetails, setLoanDetails] = useState({
		currentEmiCount: null,
		totalEmiCount: null,
		currentEmiData: null,
	});
	const [loanSchedules, setLoanSchedules] = useState(null);
	const [documents, setDocuments] = useState({
		sanctionLetter: null,
		agreementLetter: null,
	});
	const [profilePicUrl, setProfilePicUrl] = useState(null);
	// RTK Query:
	const {
		data: userLoanDetails,
		isSuccess,
		error: productQueryError,
		refetch: refetchProducts,
	} = useGetProductsQuery();
	const productApiStatus = productQueryError?.data?.status;
	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	useEffect(() => {
		if (isDemoEnabled) {
			localStorage.setItem(
				'profilePicUrl',
				'https://peoplefront-public-assets.s3.ap-south-1.amazonaws.com/demo_user/selfieFD_crop_1686216542998.jpg',
			);
		}
	}, []);

	useEffect(() => {
		if (userLoanDetails) {
			if (userLoanDetails.result?.context === 'activeloan') {
				if (userLoanDetails.result.schedule_list.length > 0) {
					setProfilePicUrl(userLoanDetails.result?.borrower_image_url);
					setLoanSchedules(userLoanDetails.result.schedule_list);
				}
			} else if (userLoanDetails.result?.context === 'product') {
				navigate('/' + ROUTE.PRODUCT, true);
			} else {
				show_toast('Failed to load the data!', 'error');
			}
		}
		// eslint-disable-next-line
	}, [userLoanDetails]);

	useEffect(() => {
		if (loanSchedules) {
			getUserDetailsFromApiData();
		}
		// eslint-disable-next-line
	}, [loanSchedules]);

	useEffect(() => {
		clearLocalData();
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
		// eslint-disable-next-line
	}, []);

	// refreshToken if expired
	useEffect(() => {
		if (productApiStatus === 401) {
			(async function refreshToken() {
				try {
					const response = await callRefreshToken();
					if (response) {
						if (check_internet_connection()) {
							refetchProducts();
						}
					}
				} catch {}
			})();
		}
		// eslint-disable-next-line
	}, [productApiStatus]);

	useEffect(() => {
		if (loanDetails.currentEmiData?.loan_id) {
			loadLoanDocuments(loanDetails.currentEmiData?.loan_id);
		}
		// eslint-disable-next-line
	}, [loanDetails]);

	const loadLoanDocuments = loanId => {
		if (check_internet_connection) {
			setIsLoading(true);
			Api(
				'post',
				`repayments/generate/presigned_url`,
				{
					loan_id: loanId,
				},
				'thirdParty',
			)
				.then(res => {
					if (res?.data?.results) {
						let prevState = { ...documents };
						prevState.agreementLetter = res.data.results.loan_agreement_url;
						prevState.sanctionLetter = res.data.results.loan_sanction_url;
						setDocuments(prevState);
					} else {
						show_toast('No data found!', 'error');
					}
					setIsLoading(false);
				})
				.catch(err => {
					setIsLoading(false);
					show_toast('Failed to load!', 'error');
				});
		}
	};

	const clearLocalData = () => {
		// Removing unused data from localstorage
		localStorage.removeItem('_rpTransId');
		localStorage.removeItem('_loanScheduleId');
	};

	const handleAndroidBackButton = () => handle_android_back_button('closeApp');

	const getUserDetailsFromApiData = () => {
		const previousState = DeepClone(loanDetails);
		previousState.totalEmiCount = loanSchedules.length;
		let payedCount = 1;
		for (let i = 0; i <= loanSchedules.length - 1; i++) {
			if (loanSchedules[i].properties.status_code === LOAN_STATUS.REPAYMENT_TRANSACTION_SUCCESS) {
				payedCount += 1;
			} else {
				previousState.currentEmiData = loanSchedules[i].properties;
				break;
			}
		}
		previousState.currentEmiCount = payedCount;
		setLoanDetails(previousState);
	};

	const changeSelect = target => {
		const prevState = DeepClone(Selected);
		Object.keys(prevState).map(el => {
			if (el === target) {
				return (prevState[el] = true);
			} else {
				return (prevState[el] = false);
			}
		});
		setSelected(prevState);
	};

	const downloadDoc = target => {
		downloadDocUsingAndroid(
			target === 'sanctionLetter'
				? documents.sanctionLetter
				: target === 'agreementLetter'
				? documents.agreementLetter
				: '',
		);
	};

	const proccedToPay = () => {
		if (loanDetails.currentEmiData.loan_id) {
			navigate('/' + ROUTE.LOAN_REPAYMENT_DETAILS, false, loanDetails.currentEmiData.loan_id);
		}
	};

	return isSuccess || isLoading ? (
		<div className={Styles.RepayHomeMainContainer}>
			<Grid container justifyContent='space-between' alignItems='center' padding='18px 18px 8px 18px'>
				<Grid item>
					<img
						src={isDemoEnabled ? profilePic : profilePicUrl ? profilePicUrl : ProfileAvatar}
						alt='profile'
						className={Styles.profilePic}
					/>
				</Grid>
				<Grid item>
					<img src={P2PLogo} alt='logo' />
				</Grid>
				<Grid item>
					<img src={NotificationIcon} alt='notification' />
				</Grid>
			</Grid>
			<Grid item marginBottom='15px'>
				<Slider {...settings}>
					<img alt='slide1' src={CarouselImg} />
					<img alt='slide2' src={CarouselImg} />
					<img alt='slide3' src={CarouselImg} />
				</Slider>
			</Grid>
			<Grid className={Styles.paymentDetailsMainGrid}>
				<Grid className={Styles.paymentDetailsSubGrid}>
					<Grid className={Styles.paymentEmiDetailsMainGrid}>
						<Grid
							className={Selected.currentEmi && `${Styles.active}`}
							onClick={() => changeSelect('currentEmi')}>
							<Typography>Current EMI</Typography>
						</Grid>
						<Grid
							className={Selected.emiSchedule && `${Styles.active}`}
							onClick={() => changeSelect('emiSchedule')}>
							<Typography>EMI Schedule</Typography>
						</Grid>
					</Grid>
					{Selected.currentEmi && loanDetails.currentEmiData && (
						<>
							<Grid className={Styles.paymentScheduleMainGrid}>
								<Grid className={Styles.emiAmountGrid}>
									<Typography className={Styles.emiCountText}>
										<span>EMI {loanDetails.currentEmiCount}</span> / {loanDetails.totalEmiCount}
									</Typography>
									<Typography
										className={
											Styles.emiAmountText
										}>{`₹${loanDetails.currentEmiData.expected_total_amt}`}</Typography>
								</Grid>
								<Grid className={Styles.emiDateGrid}>
									<Typography className={Styles.dueText}>Due Date</Typography>
									<Typography
										className={Styles.dateText}>{`${loanDetails.currentEmiData.due_date}`}</Typography>
								</Grid>
							</Grid>
							<Grid className={Styles.emiDueDaysMainGrid}>
								<Grid
									className={
										loanDetails.currentEmiData.due_days > 0 ? Styles.overDueDaysGrid : Styles.emiDueDaysGrid
									}>
									<Typography>
										{loanDetails.currentEmiData.due_days > 0
											? `Overdue by ${loanDetails.currentEmiData.due_days} days`
											: loanDetails.currentEmiData.due_days === 0
											? `Due Today`
											: `Due in ${
													loanDetails.currentEmiData.due_days && Math.abs(loanDetails.currentEmiData.due_days)
											  } days`}
									</Typography>
								</Grid>
							</Grid>
							<Grid className={Styles.payNowButtonGrid}>
								<CustomButton
									className={Styles.payNowButton}
									fullWidth={true}
									buttonText={`Pay Now ₹${loanDetails.currentEmiData.expected_total_amt}`}
									size={'large'}
									clickHandler={proccedToPay}
								/>
							</Grid>
							{documents.sanctionLetter && documents.agreementLetter && (
								<Grid className={Styles.documentsMainGrid}>
									<Typography className={Styles.headingText}>Loan Documents</Typography>
									{documents.sanctionLetter && (
										<Grid className={Styles.documentsList} onClick={() => downloadDoc('sanctionLetter')}>
											<Grid className={Styles.nameGrid}>
												<img src={documentIcon} alt='' />
												<Typography>Loan Sanction Letter</Typography>
											</Grid>
											<Grid className={Styles.downloadGrid}>
												<Typography>Download</Typography>
												<img src={downloadIcon} alt='' />
											</Grid>
										</Grid>
									)}
									{documents.agreementLetter && (
										<Grid className={Styles.documentsList} onClick={() => downloadDoc('agreementLetter')}>
											<Grid className={Styles.nameGrid}>
												<img src={documentIcon} alt='' />
												<Typography>Loan Agreement</Typography>
											</Grid>
											<Grid className={Styles.downloadGrid}>
												<Typography>Download</Typography>
												<img src={downloadIcon} alt='' />
											</Grid>
										</Grid>
									)}
								</Grid>
							)}
						</>
					)}
					{Selected.emiSchedule && loanSchedules && (
						<Grid mx={'18px'} my={'22px'}>
							{loanSchedules.map((el, index) => {
								if (el.properties) {
									return (
										<Grid className={Styles.emiSchedulesMainGrid}>
											<Grid className={Styles.emiDetailGrid}>
												<Grid className={Styles.emiAmountGrid}>
													<Typography className={Styles.heading}>EMI {index + 1}</Typography>
													<Typography
														className={Styles.content}>{`₹${el.properties.expected_total_amt}`}</Typography>
												</Grid>
												<Grid className={Styles.emiDateGrid}>
													<Typography className={Styles.heading}>Due date</Typography>
													<Typography className={Styles.content}>{el.properties.due_date}</Typography>
												</Grid>
												<Grid
													className={
														el.properties.status_code === LOAN_STATUS.REPAYMENT_TRANSACTION_SUCCESS
															? Styles.emiStatusPaidGrid
															: el.properties.status_code === LOAN_STATUS.REPAYMENT_PENDING &&
															  el.properties.due_days > 0
															? Styles.emiStatusOverDueGrid
															: el.properties.status_code === LOAN_STATUS.REPAYMENT_PENDING &&
															  el.properties.due_days <= 0
															? Styles.emiStatusDueTextGrid
															: ''
													}>
													<Typography className={Styles.heading}>
														{el.properties.status_code === LOAN_STATUS.REPAYMENT_TRANSACTION_SUCCESS
															? 'Paid'
															: el.properties.status_code === LOAN_STATUS.REPAYMENT_PENDING &&
															  el.properties.due_days === 0
															? 'Due Today'
															: el.properties.status_code === LOAN_STATUS.REPAYMENT_PENDING &&
															  el.properties.due_days > 0
															? 'Overdue by'
															: 'Due in'}
													</Typography>
													{el.properties.status_code !== LOAN_STATUS.REPAYMENT_TRANSACTION_SUCCESS && (
														<Typography className={Styles.content}>
															{el.properties.due_days > 0
																? `${el.properties.due_days} days`
																: el.properties.due_days === 0
																? ''
																: `${Math.abs(el.properties.due_days)} days`}{' '}
														</Typography>
													)}
												</Grid>
											</Grid>
										</Grid>
									);
								}
							})}
						</Grid>
					)}
				</Grid>
			</Grid>
		</div>
	) : (
		<CustomLoader />
	);
}

export default HocWrapper(RepaymentHomeScreen);
