import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/ReactToastify.min.css';

const SubscribeToast = OriginalComponent => {
	const NewComponent = props => {
		const showToast = (toastMessage, toastType) => {
			// Toast types : "success", "info", "warning", "error"
			toast.clearWaitingQueue();
			toast(toastMessage, {
				type: toastType,
				position: 'top-center',
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				pauseOnFocusLoss: false,
				closeButton: false,
				transition: Zoom,
				className: 'ToastDiv',
				theme: 'dark',
			});
		};

		const checkInternetConnection = () => {
			let userOnlineStatus = navigator.onLine ? 'online' : 'offline';
			if (userOnlineStatus === 'online') {
				return true;
			} else {
				showToast('No Internet connection, Please check your internet connection and try again.', 'error');
				clearToastQueue();
			}
		};

		const clearToastQueue = () => {
			return toast.clearWaitingQueue();
		};

		return (
			<>
				<ToastContainer limit={1} autoClose={3000} />
				<OriginalComponent
					{...props}
					check_internet_connection={checkInternetConnection}
					show_toast={showToast}
				/>
			</>
		);
	};
	return NewComponent;
};

export default SubscribeToast;
