import React from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import rejectImage from '../../Assets/Icons/RejectIcon.svg';
import calender from '../../Assets/Icons/CalendarCheckIcon.svg';
import CustomButton from '../../Components/HtmlElements/CustomButton';

// Styles
import Styles from './NoService.module.scss';

const NoService = props => {
	const { theme, navigate } = props;

	const moveUserToHomeScreen = () => {
		navigate('/home', true);
	};
	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={rejectImage} alt='exclamation-icon' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>Apology for Inconvenience</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography className={Styles.CardBodyText} color={theme.palette.textColor.darkBlack}>
							we are unable to approve your loan at this point of time.
						</Typography>

						<Grid className={Styles.CardBodyLastGrid}>
							<Grid>
								<img src={calender} alt='calender-check' />
							</Grid>
							<Typography>Please try again after 90 days.</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid className={Styles.NavigationButton}>
					<CustomButton clickHandler={moveUserToHomeScreen} buttonText='Go to Home Screen' />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default HocWrapper(NoService);
