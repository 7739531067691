import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomButton from '../../Components/HtmlElements/CustomButton';
// Icons and Images:
import PaymentSuccess from '../../Assets/Icons/PaymentSuccessIcon.svg';
// Styling Files:
import Styles from './LoanRepayment.module.scss';

function LoanRepaymentSuccess(props) {
	const { theme, handleModalClose, showPopup, amount } = props;

	return (
		<div>
			<Modal
				open={showPopup}
				onClose={handleModalClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				disableScrollLock={false}
				hideBackdrop={false}>
				<Grid className={Styles.paymentSuccessMainDiv}>
					<Grid className={Styles.paymentSuccessSubDiv}>
						<Grid className={Styles.successHeader}>
							<img src={PaymentSuccess} alt='success' />
							<Typography color={theme.palette.textColor.green} className={Styles.thanksText}>
								Thank You!
							</Typography>
							<Typography className={Styles.noteText} color={theme.palette.textColor.darkBlack}>
								We have received your payment of <strong>₹{amount}</strong>
							</Typography>
						</Grid>
						<Grid className={Styles.CardBody}>
							<CustomButton buttonText='Okay' clickHandler={handleModalClose} />
						</Grid>
					</Grid>
				</Grid>
			</Modal>
		</div>
	);
}

export default HocWrapper(LoanRepaymentSuccess);
