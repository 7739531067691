import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './RootReducer';
import { middlewareConfig } from './Middlewares';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { whiteListedReducers } from './RootReducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: whiteListedReducers,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	// middleware to work RTK properly. It also enable caching.
	middleware: middlewareConfig,
});
