export const USER_STATUS = Object.freeze({
	LOGIN: 'login',
	OTP_VERIFICATION_PENDING: 'otp_verification_pending',
	MOBILE_VERIFICATION_PENDING: 'mobile_verification_pending',
	PASSWORD_UPDATE_PENDING: 'password_update_pending',
	PASSWORD_RESET_PENDING: 'password_reset_pending',
	BASIC_INFO_PENDING: 'basic_info_pending',
	ON_HOLD: 'on_hold',
	PAN_CHECK_PENDING: 'pan_check_pending',
	PAN_CHECK_FAILED: 'pan_check_failed',
	ELIGIBILITY_CHECK_PENDING: 'eligibility_check_pending',
	ELIGIBILITY_CHECK_INPROGRESS: 'eligibility_check_inprogress',
	ELIGIBILITY_CHECK_MANUAL: 'eligibility_check_manual',
	ELIGIBILITY_CHECK_FAILED: 'eligibility_check_failed',
	LOAN_ELIGIBILITY_PENDING: 'loan_eligibility_pending',
	LOAN_ELIGIBILITY_INPROGRESS: 'loan_eligibility_inprogress',
	LOAN_ELIGIBILITY_SUCCESS: 'loan_eligibility_success',
	LOAN_ELIGIBILITY_FAILED: 'loan_eligibility_failed',
	KYC_VERIFICATION_PENDING: 'kyc_verification_pending',
	KYC_VERIFICATION_INPROGRESS: 'kyc_verification_inprogress',
	KYC_VERIFICATION_MANUAL: 'kyc_verification_manual',
	KYC_VERIFICATION_FAILED: 'kyc_verification_failed',
	SELFIE_UPLOAD_PENDING: 'selfie_upload_pending',
	SELFIE_VERIFICATION_PENDING: 'selfie_verification_pending',
	SELFIE_CHECK_INPROGRESS: 'selfie_check_inprogress',
	SELFIE_VERIFICATION_FAILED: 'selfie_verification_failed',
	SELFIE_VERIFICATION_INPROGRESS: 'selfie_verification_inprogress',
	SELFIE_VERIFICATION_MANUAL: 'selfie_verification_manual',
	INCOME_INFO_PENDING: 'income_info_pending',
	REFERENCE_CONTACT_INFO_PENDING: 'reference_contact_info_pending',
	ADDRESS_INFO_PENDING: 'address_info_pending',
	VKYC_PENDING: 'vkyc_pending',
	VKYC_AUDIT_PENDING: 'vkyc_audit_pending',
});
