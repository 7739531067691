import React, { memo } from 'react';
import SubscribeNavigation from './SubscribeNavigation';
import SubscribeTheme from './SubscribeTheme';
import SubscribeToast from './SubscribeToast';

function HocWrapper(OriginalComponent) {
	const EnhancedComponent = memo(SubscribeToast(SubscribeTheme(SubscribeNavigation(OriginalComponent))));
	const NewComponent = props => {
		return <EnhancedComponent {...props} />;
	};
	return NewComponent;
}

export default HocWrapper;
