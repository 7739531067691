import { createTheme } from '@mui/material/styles';
import { Theme } from '../../Assets/CustomStyles/MaterialTheme';

export const CustomTheme = createTheme({
	// Should be used only for Permissions component
	...Theme,
	components: {
		...Theme.components,
		MuiCheckbox: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.id === 'permissionsConsent' && {
						padding: '0px 4px 0px 0px',
						alignItems: 'flex-start',
					}),
				}),
			},
		},
	},
});
