import * as React from 'react';
import { IMaskInput } from 'react-imask';
import TextField from '@mui/material/TextField';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask={props.mask}
			definitions={{
				'#': /[1-9]/,
			}}
			inputRef={ref}
			onAccept={value => onChange({ target: { name: props.name, value } })}
			inputMode='numeric'
		/>
	);
});

export default function CustomNumberInput(props) {
	const {
		withIcon,
		fullWidth,
		disabled,
		label,
		error,
		required,
		id,
		name,
		onChangeHandler,
		placeholder,
		type,
		value,
		Icon,
		IconName,
		autoComplete,
		shrink,
		maxLength,
		maskFormat,
		...rest
	} = props;
	return (
		<TextField
			id={id ? id : ''}
			label={label ? label : 'No Label'}
			placeholder={placeholder ? placeholder : ''}
			name={name ? name : ''}
			type={type ? type : 'text'}
			size='small'
			variant='standard'
			fullWidth={fullWidth ? fullWidth : true}
			disabled={disabled ? disabled : false}
			error={error}
			required={required}
			value={value ? value : ''}
			onChange={onChangeHandler ? onChangeHandler : null}
			autoCorrect='false'
			autoSave='false'
			inputProps={{
				autoComplete: 'off',
				maxLength: maxLength ? maxLength : 1000,
			}}
			InputProps={{
				inputComponent: TextMaskCustom,
				inputProps: { mask: maskFormat },
			}}
			InputLabelProps={
				shrink && {
					shrink: shrink ? shrink : false,
				}
			}
			{...rest}
		/>
	);
}
