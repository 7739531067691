export const permissionsData = {
	mainHeading:
		'For the purpose of credit risk assessment, providing suitable loan products and to facilitate quicker loan disbursal, we require the following information from you.',
	installedApplications:
		'Our app collects and transmits to PeopleFront servers, metadata information which includes the application name, package name, installed time, updated time, version name and version code of each installed application on your device. This data may be collected even when the app is closed or not in use. This helps us to assess your credit worthiness and provide you with customized or pre-approved loan products.',
	location:
		'Our app collects and transmits to PeopleFront servers, the information about the approximate location of your device to provide serviceability of your loan application, to reduce the risk associated with your loan application. This also helps us to verify the address, make a better credit risk decision and faster KYC process.',
	personalInfo:
		'Our app collects and transmits to PeopleFront servers, user account data which includes email address, user public profile information like name, photo, ASID depending on the platform used by you to log-in to the app. This information is required as a part of registration process to access services offered by us and it is also used to auto-populate relevant fields in the course of the interface of our app. Our app also collects mobile number for verification through OTP to check the active SIM status on the device, uniquely identify you and prevent frauds and unauthorised access.',
	camera:
		'We require camera access so that you can easily scan or capture required KYC documents and save time by allowing us to auto-fill relevant data or to initiate an audio/video call for KYC purpose, which may be stored for future verification purpose. This ensures that you are provided with best user experience while using the application.',
	microPhone:
		'We require microphone permission to initiate two-way audio communication as a part of KYC journey. This may be stored for future verification purposes.',
};
