import React, { useState, useEffect, useLayoutEffect } from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomHeader from '../../Components/Layout/CustomHeader';
import CustomCheckbox from '../../Components/HtmlElements/CustomCheckbox';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomLoader from '../../Components/Loader/CustomLoader';
import { Input } from '../../Components/HtmlElements';
import { CustomTheme } from './ComponentStyles';

// MUI related imports
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material';

// MUI-icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// styles
import Styles from './AddBank.module.scss';

// formik
import { useFormik } from 'formik';
import { addBankInfo } from '../../FormikSchema/AddBankInfo';

// RTK realted imports
import { useGetBanksByIfscOrBranchQuery, useAddBankMutation } from '../../Services/apiSlice';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

// utilities
import { validateUserInput } from '../../Utils/utilities';
import { acceptAlphabetsAndNumbersNoSpace } from '../../Utils/Regex';

// Enum
import { ROUTE } from '../../Utils/Enum';

function AddBank(props) {
	const { theme, navigate, check_internet_connection, show_toast, get_previous_props } = props;

	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [fetchBankData, setFetchBankData] = useState(true);

	const isDemoEnabled = GetSavedDemoStatus() === 'true';
	// make primary account checkbox default checked if user adding bank first time
	// or if the bank verification failed.

	// doBankPrimary returns length of success banks
	const doBankPrimary = get_previous_props()?.makePrimary;

	const formik = useFormik({
		initialValues: {
			bankAccountNumber: '',
			confirmBankaccountNumber: '',
			ifscCode: '',
			isPrimary: true,
		},
		validationSchema: addBankInfo,
	});

	const {
		data: bankData,
		isFetching: bankDataIsFetching,
		isSuccess: getBankApiIsSuccess,
	} = useGetBanksByIfscOrBranchQuery(
		{ userEnteredIfsc: formik.values.ifscCode, branchName: 'IFSC_CODE' },
		{
			skip: fetchBankData,
		},
	);

	const [addBank, { isLoading: addingBank }] = useAddBankMutation();

	const bankDetailsByIFSC = bankData?.results[0];
	const userProvidedValidIFSC = getBankApiIsSuccess && bankData?.status === 200;

	const { bankAccountNumber, confirmBankaccountNumber } = formik.values;

	const isBankAccNumMismatched = bankAccountNumber !== confirmBankaccountNumber;

	const handleConfirmModalShow = e => {
		e.preventDefault();
		if (isBankAccNumMismatched) {
			show_toast('Confirm bank account should be same as your bank account number');
		} else {
			setIsConfirmModalOpen(true);
		}
	};
	const handleConfirmModalCancel = () => setIsConfirmModalOpen(false);

	const handleIFSCValidation = e => {
		let userInput = e.target.value;

		setFetchBankData(true);

		if (acceptAlphabetsAndNumbersNoSpace.test(userInput)) {
			formik.setFieldValue('ifscCode', userInput && userInput.toUpperCase());
			const hasUserEnteredValidIFSC = formik.values.ifscCode.length === 10;

			if (hasUserEnteredValidIFSC) {
				if (check_internet_connection()) {
					setFetchBankData(false);
				}
			}
		}
	};

	const handleAddBankSubmission = () => {
		if (check_internet_connection() && bankDetailsByIFSC !== null) {
			(async function AddBank() {
				const { ifsc_code, bank_name, branch } = bankDetailsByIFSC;

				const requestBody = {
					bank_account_number: formik.values.confirmBankaccountNumber,
					ifsc_code: ifsc_code,
					selected_bank_name: bank_name,
					selected_branch_name: branch,
					is_primary: formik.values.isPrimary,
				};

				try {
					const response = await addBank(requestBody).unwrap();

					if (response?.status === 200) {
						navigate('/' + ROUTE.BANK_VERIFICATION_INPROGRESS, true);
					} else {
						show_toast(response?.message, 'Error');
						setIsConfirmModalOpen(false);
					}
				} catch {
					show_toast('Exception occured while adding bank');
				}
			})();
		}
	};

	const handleAndroidBackButton = () => navigate('/' + ROUTE.SELECT_BANK, true);

	useLayoutEffect(() => {
		if (doBankPrimary === 0) {
			formik.setFieldValue('isPrimary', true);
		} else {
			formik.setFieldValue('isPrimary', false);
		}
	}, []);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		if (isDemoEnabled) {
			formik.setFieldValue('bankAccountNumber', 50100310193962);
			formik.setFieldValue('confirmBankaccountNumber', 50100310193962);
			formik.setFieldValue('ifscCode', 'HDFC002406');
			formik.setFieldValue('isPrimary', true);

			setFetchBankData(false);
		}
	}, []);

	useEffect(() => {
		// this flag is used in HandleFcm component to differentiate api call
		localStorage.setItem('_isBankAdded', true);
	}, []);

	const makeContinueBtnEnable = () => {
		let formikData = { ...formik.values };
		delete formikData.isPrimary;

		let allRequiredDataArefilled = Object.values(formikData).every(data => data !== '');
		return userProvidedValidIFSC && allRequiredDataArefilled;
	};

	return (
		<CustomHeader handleBackButton={handleAndroidBackButton}>
			<FormControl component='form' className={Styles.firstChildMainDiv}>
				<Grid>
					<Modal open={isConfirmModalOpen} className={Styles.modal}>
						<Grid container direction='column' className={Styles.mainContainer}>
							{addingBank && <CustomLoader />}
							<Grid className={Styles.modalHeader}>
								<Grid className={Styles.confirmDetails}>
									<Grid marginLeft='5px'>
										<Typography
											variant='body1'
											fontSize='14px'
											fontWeight='bold'
											color={theme.palette.textColor.darkBlack}>
											Confirm Account Details to Proceed.
										</Typography>
									</Grid>
								</Grid>
								<Grid marginTop='10px'>
									<Typography variant='body1' fontSize='14px' color={theme.palette.textColor.grey}>
										We will verify your account by making a One Rupee transaction
									</Typography>
								</Grid>
							</Grid>

							<Grid className={Styles.modalBody}>
								<Grid>
									<Grid container direction='row'>
										<Grid item xs={5}>
											<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.greyShade2}>
												Bank Account
											</Typography>
										</Grid>
										<Grid item xs={7}>
											<Typography>{formik.values.bankAccountNumber}</Typography>
										</Grid>
									</Grid>
									<Divider className={Styles.divider} />
									<Grid container direction='row'>
										<Grid item xs={5}>
											<Typography variant='body1' fontSize='12px' color={theme.palette.textColor.greyShade2}>
												IFSC Code
											</Typography>
										</Grid>
										<Grid item xs={7}>
											<Typography>{formik.values.ifscCode}</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>

							<Grid className={Styles.modalFooter} container>
								<Grid xs={6}>
									<CustomButton
										clickHandler={handleConfirmModalCancel}
										buttonText='Edit'
										variant='text'
										sx={{ color: theme.palette.textColor.grey, fontWeight: '600' }}
									/>
								</Grid>
								<Grid xs={6}>
									<CustomButton
										clickHandler={handleAddBankSubmission}
										buttonText='Submit'
										variant='text'
										sx={{ fontWeight: '600' }}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Modal>

					<Grid className={Styles.heading}>
						<Typography fontWeight='600' variant='h6'>
							Please Add Your Bank Account
						</Typography>
						<Typography color={theme.palette.textColor.grey}>
							Ensure the bank account belongs to you.
						</Typography>
					</Grid>
					<Grid className={Styles.inputMainGrid}>
						<Grid>
							<Grid container direction='column' rowGap='25px'>
								<Grid>
									<Grid className={Styles.inputStyle}>
										<Input
											mode='numeric'
											id='bankAccNum'
											label='Bank account number'
											placeholder='Enter Bank Account Number'
											value={formik.values.bankAccountNumber}
											onChangeHandler={e =>
												validateUserInput(e.target.value, {
													inputName: 'bankAccountNumber',
													setterFun: formik.setFieldValue,
													validate: 'only-numeric',
												})
											}
											onCopy={e => {
												e.preventDefault();
												return false;
											}}
											onPaste={e => {
												e.preventDefault();
												return false;
											}}
										/>
									</Grid>
									<Grid className={Styles.inputStyle}>
										<Input
											mode='numeric'
											type='password'
											id='confirmBankAccNum'
											label='Confirm bank account number'
											placeholder='Confirm Bank Account Number'
											value={formik.values.confirmBankaccountNumber}
											onChangeHandler={e =>
												validateUserInput(e.target.value, {
													inputName: 'confirmBankaccountNumber',
													setterFun: formik.setFieldValue,
													validate: 'only-numeric',
												})
											}
											onCopy={e => {
												e.preventDefault();
												return false;
											}}
											onPaste={e => {
												e.preventDefault();
												return false;
											}}
										/>
									</Grid>

									<Grid container alignItems='center' textAlign='end'>
										<Grid item xs={12} position='relative'>
											<Input
												onCopy={e => {
													e.preventDefault();
													return false;
												}}
												onPaste={e => {
													e.preventDefault();
													return false;
												}}
												label='IFSC Code'
												placeholder='EX. ABCD000148H'
												value={formik.values.ifscCode}
												maxLength='11'
												onChange={handleIFSCValidation}
												InputProps={{
													endAdornment: (
														<InputAdornment position='start'>
															{userProvidedValidIFSC && (
																<CheckCircleIcon variant='validCheck' fontSize='20px' />
															)}

															{bankDataIsFetching && <CircularProgress size={15} />}
														</InputAdornment>
													),
												}}
											/>
										</Grid>
									</Grid>

									{/* Show this only IF user has provided valid IFSC code */}
									{userProvidedValidIFSC ? (
										<Typography
											marginTop='8px'
											variant='body1'
											fontWeight='500'
											fontSize='12px'
											color={theme.palette.textColor.darkBlack}>
											<span>*{bankDetailsByIFSC.bank_name},</span>
											<span style={{ marginLeft: 4 }}>{bankDetailsByIFSC.branch}</span>
										</Typography>
									) : null}
								</Grid>

								<Grid container direction='row' className={Styles.PermissionConsent}>
									<Grid>
										<ThemeProvider theme={CustomTheme}>
											<CustomCheckbox
												{...formik.getFieldProps('confirmBankaccountNumber')}
												id='primaryAccountConsent'
												className={Styles.CustomCheckbox}
												checked={formik.values.isPrimary}
												onChangeHanlder={() => formik.setFieldValue('isPrimary', !formik.values.isPrimary)}
											/>
										</ThemeProvider>
									</Grid>
									<Grid>
										<Typography variant='body1' fontSize='14px' color={theme.palette.textColor.darkBlack}>
											Make Primary account
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid paddingTop='5px'>
					<CustomButton
						type='submit'
						buttonText='Continue'
						disabled={isDemoEnabled ? false : !makeContinueBtnEnable()}
						clickHandler={handleConfirmModalShow}
					/>
				</Grid>
			</FormControl>
		</CustomHeader>
	);
}

export default HocWrapper(AddBank);
