export const getUserMobileDetailsFromAndroid = async () => {
	return new Promise((resolve, reject) => {
		let wifiStatus, wifiName, ipAddress;
		if (window.Android) {
			window.Android.checkWifiConnected();
			window.Android.wifeName();
			window.Android.saveIpAddress();
			setTimeout(() => {
				wifiStatus = localStorage.getItem('wifiStatus');
				wifiName = localStorage.getItem('wifiName');
				ipAddress = localStorage.getItem('ipAddress');
				if (wifiStatus && wifiName && ipAddress) {
					resolve({
						wifiStatus,
						wifiName,
						ipAddress,
					});
				} else {
					reject('No data found');
				}
			}, 1000);
		} else {
			reject('No Android connection');
		}
	});
};

export const getProfilePicFromAndroid = () => {
	if (localStorage.getItem('profilePicUrl')) {
		return localStorage.getItem('profilePicUrl');
	} else {
		let profilPic;
		if (window.Android) {
			profilPic = window.Android.profilePic();
			if (profilPic) {
				localStorage.setItem('profilePicUrl', profilPic);
				return profilPic;
			} else {
				return null;
			}
		}
	}
};

export const getVersionCode = () => {
	if (localStorage.getItem('_versionCode')) {
		return localStorage.getItem('_versionCode');
	} else {
		if (window.Android) {
			let ver = window.Android.getVersionCode();
			if (ver) {
				localStorage.setItem('_versionCode', ver);
				return ver;
			} else {
				return null;
			}
		}
	}
};

export const downloadDocUsingAndroid = url => {
	if (window.Android) {
		window.Android.loadWebViewDocument(url);
	}
};
