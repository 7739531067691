import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import Styles from './Registration.module.scss';

function Registration(props) {
	const { theme, heading, subheading, children, passwordTheme, ...rest } = props;
	return (
		<Grid
			container
			item
			xs={12}
			backgroundColor={theme.palette.primary.main}
			className={passwordTheme ? Styles.onPasswordTheme : Styles.MainContainer}>
			<Grid className={Styles.firstSubDiv} xs={12} item>
				<Typography variant='h5' className={Styles.welcomeText} color={theme.palette.textColor.white}>
					{heading}
				</Typography>
				<Typography variant='body1' fontSize={14} color={theme.palette.textColor.greyShade1}>
					{subheading}
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				backgroundColor={theme.palette.background.primary}
				className={Styles.secondSubDiv}
				{...rest}>
				{children}
			</Grid>
		</Grid>
	);
}

export default HocWrapper(Registration);
