import React from 'react';
import Checkbox from '@mui/material/Checkbox';

function CustomCheckbox(props) {
	const { checked, disabled, onChangeHanlder, defaultChecked, id, className } = props;
	return (
		<Checkbox
			id={id ? id : ''}
			className={className ? className : ''}
			size='medium'
			checked={checked ? checked : false}
			onChange={onChangeHanlder}
			disabled={disabled ? disabled : false}
		/>
	);
}

export default CustomCheckbox;
