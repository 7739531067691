import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
// Icons
import SuccessIcon from '../../Assets/Icons/SuccessTickIcon.svg';
import CustomButton from '../../Components/HtmlElements/CustomButton';

function ResetSuccess(props) {
	const { theme, handle_android_back_button, go_back, get_previous_props } = props;
	const loanId = get_previous_props()

	const goBack = () => {
		go_back();
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	});

	const handleAndroidBackButton = () => {
		handle_android_back_button('goBack');
	};

	return (
		<Grid
			container
			display='flex'
			flexDirection='column'
			backgroundColor={theme.palette.primary.main}
			justifyContent='space-between'
			height='100vh'>
			<Grid
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
				width='100%'
				margin='auto'>
				<img src={SuccessIcon} alt='' />
				<Typography variant='h6' marginTop='18px' color={theme.palette.textColor.white}>
					Thank you!
				</Typography>
				<Typography variant='h6' color={theme.palette.textColor.white} marginTop='8px' textAlign='center'>
					Loan closed successfully
				</Typography>
			</Grid>
			<Grid
				width='100%'
				padding='22px'
				justifyContent='center'
				alignItems='center'
				backgroundColor={theme.palette.background.primary}>
				<Typography
					variant='body1'
					color={theme.palette.textColor.grey}
					fontSize={12}
					fontWeight={500}
					marginBottom='38px'
					marginTop='12px'
					textAlign='center'>
					We have received payments towards all the EMI’s for <strong>loan ID - {loanId}</strong>
				</Typography>
				<CustomButton buttonText='Continue' clickHandler={goBack} />
			</Grid>
		</Grid>
	);
}

export default HocWrapper(ResetSuccess);
