import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import { getUserStatus } from '../HomeScreen/HomeScreenSlice';
// svg
import eligibilityTimer from '../../Assets/Icons/EligibilityTimerIcon.svg';

// Styles
import Styles from './LoanDisbursalInprogress.module.scss';

// redux
import { useDispatch, useSelector } from 'react-redux';

// RTK Query
import { useUpdateDemoUserStatusMutation } from '../../Services/apiSlice';

// localstorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';
import CustomLoader from '../../Components/Loader/CustomLoader';

// Enum
import { ROUTE, USER_STATUS, LOAN_STATUS } from '../../Utils/Enum';

function LoanDisbursalInprogress(props) {
	const { theme, handle_android_back_button, show_toast, check_internet_connection, navigate } = props;
	const dispatch = useDispatch();

	const homeApiData = useSelector(state => state.homeScreenDetails);

	const isDemoEnabled = GetSavedDemoStatus() === 'true';

	// this values is from persisted reducer
	const disbursalAmount = useSelector(state => state?.localState?.disbursal_amt);

	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();

	const handleAndroidBackButton = () => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.LOAN_ELIGIBILITY_SUCCESS,
						}).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('closeApp');
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			handle_android_back_button('closeApp');
		}
	};

	useEffect(() => {
		if (isDemoEnabled) {
			if (check_internet_connection()) {
				setTimeout(() => {
					dispatch(getUserStatus());
				}, 3000);
			}
		}
	}, []);

	useEffect(() => {
		if (isDemoEnabled) {
			if (homeApiData?.loanStatus === LOAN_STATUS.DISBURSAL_COMPLETED) {
				navigate('/' + ROUTE.REPAYMENT_HOME, true);
			}
		}
	}, [homeApiData]);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<React.Fragment>
			{updatingUsrStatus && <CustomLoader />}

			<Grid
				className={Styles.MainContainerDiv}
				container
				direction='column'
				backgroundColor={theme.palette.background.secondary}>
				<Grid className={Styles.CardHeight}>
					<Grid>
						<Grid className={Styles.CardHeader}>
							<Grid>
								<img src={eligibilityTimer} alt='eligibility-timer' />
							</Grid>
							<Typography className={Styles.CardHeaderText}>Loan Disbursal In Progress…</Typography>
						</Grid>
						<Divider sx={{ backgroundColor: '#F2F2F2' }} />
						<Grid className={Styles.CardBody}>
							<Typography className={Styles.CardBodyText} color={theme.palette.textColor.grey}>
								We have transferred your loan amount of ₹{disbursalAmount && disbursalAmount}.
								<Typography paddingTop='20px' className={Styles.CardBodyText}>
									It should be reflect in your bank account in next 48 hours.
								</Typography>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default HocWrapper(LoanDisbursalInprogress);
