import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import rejectImage from '../../Assets/Icons/RejectIcon.svg';
import calender from '../../Assets/Icons/CalendarCheckIcon.svg';
import CustomButton from '../../Components/HtmlElements/CustomButton';

// slice

import { getUserStatus, getPanName } from '../HomeScreen/HomeScreenSlice';

// redux
import { useDispatch, useSelector } from 'react-redux';

// Enum
import { ROUTE } from '../../Utils/Enum';

// Stylesheet
import Styles from './EligibilityCheck.module.scss';

function PanCheckFail(props) {
	const { theme, navigate, handle_android_back_button, check_internet_connection } = props;

	const dispatch = useDispatch();
	const panName = useSelector(getPanName);

	const moveUserToHomeScreen = () => navigate('/' + ROUTE.HOME, true);

	const handleAndroidBackButton = () => handle_android_back_button('closeApp');

	useEffect(() => {
		if (check_internet_connection()) {
			dispatch(getUserStatus());
		}
	}, []);

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);

		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={rejectImage} alt='exclamation-icon' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>Sorry! {panName && panName}</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography className={Styles.CardBodyText} color={theme.palette.textColor.darkBlack}>
							At present, your profile does not meet our eligibility criteria.
						</Typography>

						<Grid className={Styles.CardBodyLastGrid}>
							<Grid>
								<img src={calender} alt='calender-check' />
							</Grid>
							<Typography>Please try again after 90 days.</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid className={Styles.NavigationButton}>
					<CustomButton clickHandler={moveUserToHomeScreen} buttonText='Go to Home Screen' />
				</Grid>
			</Grid>
		</Grid>
	);
}

export default HocWrapper(PanCheckFail);
