import React from 'react';
import Button from '@mui/material/Button';
import CreateSvgIcon from '../../Utils/CreateSvgIcon';

function CustomButton(props) {
	const {
		disabled,
		fullWidth,
		startIcon,
		startIconName,
		endIcon,
		endIconName,
		clickHandler,
		variant,
		disableElevation,
		id,
		buttonText,
		className,
		size,
		...rest
	} = props;
	const StartIcon = startIcon ? CreateSvgIcon(startIcon, startIconName) : null;
	const EndIcon = endIcon ? CreateSvgIcon(endIcon, endIconName) : null;
	return (
		<Button
			id={id ? id : ''}
			className={className ? className : ''}
			variant={variant ? variant : 'contained'}
			disabled={disabled ? disabled : false}
			disableElevation={disableElevation ? disableElevation : false}
			fullWidth={fullWidth === false ? fullWidth : true}
			size={size ? size : 'medium'}
			startIcon={StartIcon && <StartIcon />}
			endIcon={EndIcon && <EndIcon />}
			onClick={clickHandler ? clickHandler : null}
			{...rest}>
			{buttonText ? buttonText : 'Button'}
		</Button>
	);
}

export default CustomButton;
