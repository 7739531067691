import * as Yup from 'yup';
import { acceptAlphabetsAndSpace, panNumber, onlyDigits } from '../Utils/Regex';

export const basicInfoSchema = Yup.object({
	Name: Yup.string()
		.test({
			skipAbsent: true,
			test(value, ctx) {
				const splittedValue = value.split(' ');
				const result = splittedValue
					.map(val => val.replace(' ', ''))
					.filter(item => item !== '')
					.join('');
				if (result.trim().length < 3) {
					return ctx.createError({ message: 'Minimum 3 letters required' });
				}
				return true;
			},
		})
		.max(50, 'Maximum 50 characters allowed')
		.matches(acceptAlphabetsAndSpace, 'Only alphabets are allowed'),
	'PAN Number': Yup.string()
		.matches(panNumber, 'Enter a valid PAN Number')
		.min(10, 'Minimum 10-digit required')
		.max(10, 'Maximum 10-digit is allowed'),
	'Last 4-digit Aadhaar': Yup.string()
		.min(4, 'Minimum 4-digit required')
		.max(4, 'Only 4-digit is required')
		.matches(onlyDigits, 'Only digits allowed'),
	'Current Address Pincode': Yup.string()
		.min(6, 'Minimum 6-digit required')
		.max(6, 'Maximum 6-digit allowed')
		.matches(onlyDigits, 'Only digits allowed'),
	'Permanent Address Pincode': Yup.string()
		.min(6, 'Minimum 6-digit required')
		.max(6, 'Maximum 6-digit allowed')
		.matches(onlyDigits, 'Only digits allowed'),
});
