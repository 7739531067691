import { createTheme } from '@mui/material/styles';
import { Theme } from '../../Assets/CustomStyles/MaterialTheme';

export const CustomTheme = createTheme({
	...Theme,
	components: {
		MuiCircularProgress: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.id === 'circularProgress'
						? {
								color: '#fff',
								opacity: 1,
						  }
						: ownerState.id === 'pdfProgress'
						? { color: '#696969', opacity: 1 }
						: ''),
				}),
			},
		},
	},
});
