import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import Registration from '../../Components/Layout/Registration';
import HocWrapper from '../../Utils/Hoc/HocWrapper';

// Styles
import Styles from './ReferenceOtp.module.scss';
import CustomNumberInput from '../../Components/HtmlElements/CustomNumberInput';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomLoader from '../../Components/Loader/CustomLoader';

// RTK Query
import {
	useGetRefContactStatusQuery,
	useSendRefContactOTPMutation,
	useValidateRefContactOTPMutation,
	useResendRefContactOTPMutation,
	useEditReferenceMutation,
} from '../../Services/apiSlice';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

// Enum
import { ROUTE, USER_STATUS, REF_CONTACT_STATUS } from '../../Utils/Enum';

let interval, timer;

function FamilyReferenceOtp(props) {
	const { theme, navigate, show_toast, check_internet_connection, get_previous_props } = props;
	const { darkBlack, grey } = theme.palette.textColor;

	const isDemoEnabled = GetSavedDemoStatus();

	const [otp, setOtp] = useState();
	const [otpError, setOtpError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showResendButton, setShowResendButton] = useState(false);
	const [resendOtpError, setResendOtpError] = useState(false);

	const {
		data: refContactStatus,
		refetch: refetchReferenceStatus,
		isFetching,
	} = useGetRefContactStatusQuery();
	const [sendRefContactOTP, { data: sendOTPResponse }] = useSendRefContactOTPMutation();
	const [validateRefContactOTP, { isLoading: validatingOTP }] = useValidateRefContactOTPMutation();
	const [resendRefContactOTP] = useResendRefContactOTPMutation();
	const [editReference, { isLoading: isEditing }] = useEditReferenceMutation();

	const currentRefStatus = refContactStatus?.result?.reference_contact_status;
	const referenceContactId = refContactStatus?.result?.reference_contact_id;
	const referenceOTPID = sendOTPResponse?.result?.reference_otp_id;
	const userMobileNumber = refContactStatus?.result?.mobile_number;

	// prevState
	const prevState = get_previous_props();
	const disableSkipAndEditBtn = prevState?.disableSkipAndEditButton;
	const mobileNum = prevState?.mobNum;

	const otpHandler = event => {
		const val = event.target.value && event.target.value.split('-').join('');

		if (val.length <= 6) {
			setOtp(val);
			if (otpError) {
				setOtpError(false);
				setErrorMessage('');
			}
		}
	};

	const handleResendOTP = () => {
		setShowResendButton(false);

		if (check_internet_connection()) {
			(async function resendOTP() {
				try {
					const response = await resendRefContactOTP({
						reference_contact_id: referenceContactId,
					}).unwrap();

					if (response?.status === 200) {
						runTimer();
						setOtp('');
						show_toast(response?.message, 'info');
					} else {
						show_toast(response?.message, 'error');
						setResendOtpError(true);
					}
				} catch {
					show_toast('Some exception occured while resending OTP!');
				}
			})();
		}
	};

	const validate = () => {
		if (otp) {
			if (otp.length === 6) {
				if (isDemoEnabled === 'true') {
					navigate('/' + ROUTE.REFERENCE_CONTACT_FRIEND, true);
				} else {
					if (check_internet_connection()) {
						(async function validateOTP() {
							try {
								const response = await validateRefContactOTP({
									reference_otp_id: referenceOTPID,
									reference_contact_id: referenceContactId,
									otp: otp,
									context: 'family_validate',
								}).unwrap();

								if (response?.status === 200) {
									try {
										const refStatus = await refetchReferenceStatus().unwrap();
										const currentRefStatus = refStatus?.result?.reference_contact_status;
										const hideSkipAndEditBtn =
											refStatus?.result?.user_status !== USER_STATUS.LOAN_ELIGIBILITY_PENDING;

										if (currentRefStatus === REF_CONTACT_STATUS.FRIEND_REFERENCE_PENDING) {
											navigate('/' + ROUTE.REFERENCE_CONTACT_FRIEND, true);
										} else if (
											currentRefStatus === REF_CONTACT_STATUS.FRIEND_REFERENCE_OTP_VERIFICATION_PENDING
										) {
											navigate('/' + ROUTE.REFERENCE_FRIEND_OTP, true, {
												disableSkipAndEditButton: hideSkipAndEditBtn,
											});
										} else if (currentRefStatus === REF_CONTACT_STATUS.ADD_BANK_PENDING) {
											navigate('/' + ROUTE.SELECT_BANK, true);
										} else if (currentRefStatus === REF_CONTACT_STATUS.VKYC_PENDING) {
											if (window.Android) {
												if (
													window.Android.checkMicroPermissionEnabled() &&
													window.Android.checkCameraPermissionEnabled()
												) {
													window.Android.navigateToAndroidToReact('vkyc_pending', 'stage4');
												} else {
													navigate('/' + ROUTE.PERMISSION_FAIL, true);
												}
											}
										} else if (currentRefStatus === REF_CONTACT_STATUS.LOAN_ELIGIBILITY_FAILED_SCREEN) {
											navigate('/' + ROUTE.PAN_CHECK_FAIL, true);
										} else if (currentRefStatus === REF_CONTACT_STATUS.VKYC_WAITING) {
											navigate('/' + ROUTE.VKYC_INPROGRESS, true);
										} else if (currentRefStatus === REF_CONTACT_STATUS.KYC_MANUAL_SCREEN) {
											navigate('/' + ROUTE.KYC_MANUAL_VERIFICATION, true);
										}
									} catch {
										show_toast('Exception occured while fetching reference status');
									}
								} else {
									show_toast(response?.message, 'error');
								}
							} catch {
								show_toast('Exception occured while OTP validation');
							}
						})();
					}
				}
			} else {
				setOtpError(true);
				setErrorMessage('*Please enter a valid OTP');
			}
		} else {
			setOtpError(true);
			setErrorMessage('*Please enter the OTP');
		}
	};

	const runTimer = () => {
		let element = document.getElementById('timer');
		timer = 30;
		interval = setInterval(() => {
			if (timer <= 0) {
				clearInterval(interval);
				setShowResendButton(true);
			} else {
				timer = timer - 1;
				element.textContent = timer >= 10 ? `00 : ${timer}s` : `00 : 0${timer}s`;
			}
		}, 1000);
	};

	const handleSkipVerification = () => {
		if (check_internet_connection()) {
			(async function skipVerify() {
				try {
					const response = await validateRefContactOTP({
						reference_contact_id: referenceContactId,
						context: 'family_skip',
					}).unwrap();

					if (response?.status === 200) {
						navigate('/' + ROUTE.REFERENCE_CONTACT_FRIEND, true);
					} else {
						show_toast(response?.message);
					}
				} catch {
					show_toast('Some exception occurred while skipping !');
				}
			})();
		}
	};

	const handleGoBack = () => navigate('/' + ROUTE.HOME, true);

	const handleEdit = async () => {
		if (referenceContactId) {
			try {
				const response = await editReference({ reference_contact_id: referenceContactId }).unwrap();

				if (response?.status === 200) {
					if (response?.result) {
						navigate('/' + ROUTE.REFERENCE_CONTACT_FAMILY, true, { famRefState: response?.result });
					}
				} else {
					show_toast(response?.message, 'error');
				}
			} catch {
				show_toast('something went wrong while editing reference contact', 'error');
			}
		}
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleGoBack);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleGoBack);
		};
	}, []);

	useEffect(() => {
		refetchReferenceStatus();

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (otp && otp.length === 6) {
			validate();
		}
	}, [otp]);

	useEffect(() => {
		if (refContactStatus?.status === 200) {
			if (check_internet_connection()) {
				if (
					referenceContactId &&
					currentRefStatus === REF_CONTACT_STATUS.FAMILY_REFERENCE_OTP_VERIFICATION_PENDING
				) {
					(async function sendOTP() {
						try {
							const response = await sendRefContactOTP({ reference_contact_id: referenceContactId }).unwrap();
							if (response?.status === 200) {
								runTimer();
								show_toast(response?.message);
							} else {
								show_toast(response?.message);
							}
						} catch {
							show_toast('Some exception occured while sending OTP!');
						}
					})();
				}
			}
		}
	}, [refContactStatus]);

	return (
		<React.Fragment>
			{validatingOTP || isEditing || isFetching ? <CustomLoader /> : null}

			<Registration
				heading='OTP Verification'
				subheading='Verify via OTP'
				minHeight='500px'
				justifyContent='space-between'>
				<Grid>
					<Grid className={Styles.numberText}>
						<Typography className={Styles.Heading} color={darkBlack}>
							Verify your family’s contact detail
						</Typography>

						{isDemoEnabled === 'true' ? (
							<Typography color={grey} className={Styles.otpText} variant='body1'>
								Enter 6-digit code we have sent to <br />
								+91 {mobileNum}{' '}
							</Typography>
						) : (
							<Typography color={grey} className={Styles.otpText} variant='body1'>
								Enter 6-digit code we have sent to <br />
								+91 {userMobileNumber}{' '}
								<strong onClick={handleEdit}>{disableSkipAndEditBtn ? null : 'Edit?'}</strong>
							</Typography>
						)}
					</Grid>
					<Grid className={Styles.OtpInputGrid}>
						<CustomNumberInput
							id='otp'
							name='otp'
							label='OTP'
							value={otp}
							onChangeHandler={otpHandler}
							maskFormat={'000-000'}
						/>

						{otpError && (
							<Typography className={Styles.errorText}>
								<em>{errorMessage}</em>
							</Typography>
						)}

						{!showResendButton && (
							<Typography id='timer' className={Styles.timerText} color={grey}>
								{resendOtpError || isDemoEnabled === 'true' ? '' : '00 : 30s'}
							</Typography>
						)}

						{/* show re-send OTP */}
						{showResendButton && (
							<Grid className={Styles.resendOtpGrid}>
								<Typography className={Styles.resendText} color={grey}>
									Did not receive OTP yet?
								</Typography>
								<CustomButton
									id='resendOtp'
									clickHandler={handleResendOTP}
									buttonText='Resend OTP'
									fullWidth={false}
									size='small'
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid className={Styles.SkipVerificationGrid}>
					{isDemoEnabled === 'true' ? (
						''
					) : (
						<Typography
							component='div'
							onClick={handleSkipVerification}
							className={Styles.SkipVerificationText}>
							{disableSkipAndEditBtn ? null : 'Skip verification'}
						</Typography>
					)}
				</Grid>
			</Registration>
		</React.Fragment>
	);
}

export default HocWrapper(FamilyReferenceOtp);
