import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../Utils/Api';

const userStatusApi = `borrower_apk/home/screen/status?trg_ru=0&v=1000&latitude=12.960680890828371&longitude=77.63665560632944`;

export const getUserStatus = createAsyncThunk('userStausApi', async () => {
	return Api('get', userStatusApi)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					if (response.data.result) {
						data['userStatus'] = response.data.result.user_status;
						data['loanStatus'] = response.data.result.loan_status;
						data['pan_name'] = response.data.result.pan_name;
						data['prefill'] = response.data.result.do_prefill;
						data['is_demo_user'] = response.data.result.is_demo_user;
						data['no_service'] = response.data.result.no_service;
						data['no_service_description'] = response.data.result.no_service_description;
						data['is_vkyc_enabled'] = response.data.result.is_vkyc_enabled;
					}
					data['statusCode'] = response.data.result.statusCode;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return err.response;
			}
		});
});

const initialState = {
	isLoading: false,
	message: '',
	userStatus: '',
	loanStatus: '',
	statusCode: '',
	pan_name: '',
	prefill: '',
	is_demo_user: '',
	no_service: '',
	no_service_description: '',
	error: '',
	is_vkyc_enabled: '',
};
export const homeScreenSlice = createSlice({
	name: 'IncomeInfo',
	initialState,
	reducers: {
		resetHomeScreenState: state => {
			state.isLoading = false;
			state.message = '';
			state.userStatus = '';
			state.loanStatus = '';
			state.statusCode = '';
			state.pan_name = '';
			state.prefill = '';
			state.is_demo_user = '';
			state.no_service = '';
			state.no_service_description = '';
			state.error = '';
			state.is_vkyc_enabled = '';
		},
	},
	extraReducers: builder => {
		builder.addCase(getUserStatus.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(getUserStatus.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload?.status === 401) {
				state.error = payload?.data?.status;
			} else {
				if (payload) {
					state.userStatus = payload.userStatus;
					state.loanStatus = payload.loanStatus;
					state.statusCode = payload.statusCode;
					state.pan_name = payload.pan_name;
					state.prefill = payload.prefill;
					state.is_demo_user = payload.is_demo_user;
					state.no_service = payload.no_service;
					state.no_service_description = payload.no_service_description;
					state.is_vkyc_enabled = payload.is_vkyc_enabled;
				}
			}
		});
		builder.addCase(getUserStatus.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.message = payload && payload.apiMessage;
		});
	},
});

export const { resetHomeScreenState } = homeScreenSlice.actions;

export const getPanName = state => state?.homeScreenDetails?.pan_name;
export const getPrefillVal = state => state?.homeScreenDetails?.prefill;

export default homeScreenSlice.reducer;
