import { getVersionCode } from './AndroidHelper';
import Api from './Api';
import { SaveToken, SaveRefreshToken } from './LocalStorageValues';

const versionCode = getVersionCode();
const lat = localStorage.getItem('_latitude');
const long = localStorage.getItem('_longitude');
const version = versionCode ? versionCode : '1000';
const url = `borrower_apk/auth/refresh_token?v=${version}&latitude=${
	lat ? lat : 12.960680890828371
}&longitude=${long ? long : 77.63665560632944}`;

const callRefreshToken = async () => {
	return Api('refreshToken', url, {})
		.then(res => {
			if (res?.data?.status === 200) {
				if (res.data.result) {
					if (res.data.result.refresh_token && res.data.result.token) {
						SaveToken(res.data.result.token);
						SaveRefreshToken(res.data.result.refresh_token);
						if (window.Android) {
							window.Android.storeAccessToken(res.data.result.token, res.data.result.refresh_token);
						}
						return res.data.result.token;
					} else {
						return 'Missing token!';
					}
				} else {
					return 'No data found!';
				}
			} else {
				return 'Api failed to load!';
			}
		})
		.catch(err => console.log(err));
};

export default callRefreshToken;
