import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomButton from '../../Components/HtmlElements/CustomButton';
// Icons and Images:
import paymentFailIcon from '../../Assets/Icons/PaymentFailIcon.svg';
// Styling Files:
import Styles from './LoanRepayment.module.scss';

function LoanRepaymentFail(props) {
	const { theme, handleModalClose, showPopup } = props;

	return (
		<div>
			<Modal
				open={showPopup}
				onClose={handleModalClose}
				disableScrollLock={false}
				hideBackdrop={false}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Grid className={Styles.paymentSuccessMainDiv}>
					<Grid className={Styles.paymentSuccessSubDiv}>
						<Grid className={Styles.successHeader}>
							<img src={paymentFailIcon} alt='fail' />
							<Typography color={theme.palette.error.main} className={Styles.thanksText}>
								Payment Failed!
							</Typography>
							<Typography className={Styles.noteText} color={theme.palette.textColor.darkBlack}>
								Please try again
							</Typography>
						</Grid>
						<Grid className={Styles.CardBody}>
							<CustomButton buttonText='Okay' clickHandler={handleModalClose} />
						</Grid>
					</Grid>
				</Grid>
			</Modal>
		</div>
	);
}

export default HocWrapper(LoanRepaymentFail);
