import * as Yup from 'yup';
import { acceptAlphabetsAndSpace, ifscCode, onlyDigits } from '../Utils/Regex';

export const addBankInfo = Yup.object({
	bankAccountNumber: Yup.string().matches(onlyDigits, 'Only digits allowed'),
	confirmBankaccountNumber: Yup.string().matches(onlyDigits, 'Only digits allowed'),
	ifscCode: Yup.string().matches(ifscCode, 'IFSC should be 4 letters, followed by 7 letters or digits'),

	bankName: Yup.string()
		.matches(acceptAlphabetsAndSpace, 'Enter valid branch name ')
		.min(3, 'Minimum 3 characters required')
		.max(50, 'Maximum 50 characters is allowed'),
	branchName: Yup.string()
		.matches(acceptAlphabetsAndSpace, 'Enter valid branch name ')
		.min(3, 'Minimum 3 characters required')
		.max(50, 'Maximum 50 characters is allowed'),
});
