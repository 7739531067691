import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomCard from '../../Components/Layout/CustomCard';
import CustomRadio from '../../Components/HtmlElements/CustomRadio';
import CustomInput from '../../Components/HtmlElements/CustomInput';
import CustomNumberInput from '../../Components/HtmlElements/CustomNumberInput';
import CustomButton from '../../Components/HtmlElements/CustomButton';
import CustomLoader from '../../Components/Loader/CustomLoader';
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';

import { useLocation } from 'react-router-dom';

// Formik Schema
import { referenceInfoSchema } from '../../FormikSchema/ReferenceContactInfo';

// RTK Query
import { useSaveRefContactMutation, useUpdateDemoUserStatusMutation } from '../../Services/apiSlice';

// Android methods
import { getProfilePicFromAndroid } from '../../Utils/AndroidHelper';

// Stylesheet
import Styles from './ReferenceContact.module.scss';

// localStorageValues
import { GetSavedDemoStatus } from '../../Utils/LocalStorageValues';

import { validateUserInput } from '../../Utils/utilities';

// Enum
import { ROUTE, USER_STATUS } from '../../Utils/Enum';

const isFormFieldIsEmpty = obj => Object.values(obj).every(formVal => formVal !== '');

function ReferenceContactFriends(props) {
	const { navigate, check_internet_connection, show_toast, handle_android_back_button } = props;
	const { textColor, primary, background, error } = props.theme.palette;

	const location = useLocation();

	const isDemoEnabled = GetSavedDemoStatus();

	const [saveRefContact, { isLoading: savingData }] = useSaveRefContactMutation();
	const [updateUsrStatus, { isLoading: updatingUsrStatus }] = useUpdateDemoUserStatusMutation();

	const editedRefContactId = location?.state?.friendRefState?.edited_reference_contact_id;

	const formik = useFormik({
		initialValues: {
			friend: '4',
			reference_contact_name: '',
			mobile_number: '',
		},
		validationSchema: isDemoEnabled === 'true' ? null : referenceInfoSchema,
		onSubmit: async formVals => {
			const reqBody = {
				reference_type_id: formVals.friend,
				edited_reference_contact_id: editedRefContactId ? editedRefContactId : null,
				contact_name: formVals.reference_contact_name,
				mobile_number: formVals.mobile_number.split('-').join(''),
			};

			if (check_internet_connection()) {
				try {
					const response = await saveRefContact(reqBody).unwrap();
					if (response?.status === 200) {
						if (isDemoEnabled === 'true') {
							getProfilePicFromAndroid();
							if (check_internet_connection()) {
								(async function updateUserStatus() {
									try {
										const response = await updateUsrStatus({
											user_status: USER_STATUS.LOAN_ELIGIBILITY_SUCCESS,
										}).unwrap();

										if (response?.status === 200) {
											navigate('/' + ROUTE.PRODUCT, true);
										} else {
											show_toast('Something went wrong!');
										}
									} catch {
										show_toast('Some exception occured!');
									}
								})();
							}
						} else {
							getProfilePicFromAndroid();
							navigate('/' + ROUTE.REFERENCE_FRIEND_OTP, true, { mobNum: formik.values.mobile_number });
						}
					} else {
						show_toast(response?.message, 'error');
					}
				} catch {
					show_toast('An exception occured while saving the data', 'error');
				}
			}
		},
	});
	const { reference_contact_name, mobile_number } = formik.errors;

	const handleAndroidBackButton = () => {
		if (isDemoEnabled === 'true') {
			if (check_internet_connection()) {
				(async function updateUserStatus() {
					try {
						const response = await updateUsrStatus({
							user_status: USER_STATUS.BASIC_INFO_PENDING,
						}).unwrap();

						if (response?.status === 200) {
							handle_android_back_button('closeApp');
						} else {
							show_toast('Something went wrong!');
						}
					} catch {
						show_toast('Some exception occured!');
					}
				})();
			}
		} else {
			navigate('/' + ROUTE.HOME, true);
		}
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);
		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		if (location?.state) {
			if (location?.state.friendRefState) {
				let { name, mobile_num } = location?.state?.friendRefState;

				formik.setFieldValue('reference_contact_name', name);
				formik.setFieldValue('mobile_number', mobile_num);
			}
		}
		return () => {
			let clonedState = { ...location.state };
			delete clonedState.friendRefState;
			location.state = clonedState;
		};
	}, []);

	// prefill data for demoApp
	useEffect(() => {
		if (isDemoEnabled === 'true') {
			formik.setFieldValue('parents', '1');
			formik.setFieldValue('reference_contact_name', 'Friend Test');
			formik.setFieldValue('mobile_number', '8667456916');
		}
	}, []);

	let disableSubmitButton;
	let updatedFormState = { ...formik.values };

	let allFormIsFilled = isFormFieldIsEmpty(updatedFormState);
	disableSubmitButton = !allFormIsFilled || !formik.isValid || formik.values.mobile_number.length !== 12;

	return (
		<React.Fragment>
			{savingData || updatingUsrStatus ? <CustomLoader /> : ''}

			<Grid container className={Styles.ParentMainContainer}>
				<FormControl fullWidth component='form' onSubmit={formik.handleSubmit}>
					<Grid container className={Styles.heading} backgroundColor={primary.main}>
						<Grid marginLeft='15px'>
							<Typography fontSize='22px' color={textColor.white}>
								Reference Contact Detail
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						direction='column'
						className={Styles.MainBody}
						backgroundColor={background.secondary}>
						<Grid>
							<CustomCard style={{ textAlign: 'left' }}>
								<Grid container flexDirection='column' rowGap='20px'>
									<Grid>
										<Typography className={Styles.SubheadingText}>Select 2nd Reference Contact</Typography>
										<CustomRadio
											{...formik.getFieldProps('friend')}
											data={[{ label: 'Friend', value: '4' }]}
										/>
									</Grid>
									<Grid>
										<CustomInput
											error={reference_contact_name ? true : false}
											helperText={reference_contact_name}
											label='Enter Friend’s Name'
											onChangeHandler={e =>
												validateUserInput(e.target.value, {
													inputName: 'reference_contact_name',
													setterFun: formik.setFieldValue,
													validate: 'user-name',
												})
											}
											value={formik.values.reference_contact_name}
										/>
									</Grid>

									<Grid container className={Styles.MobileNumberFieldContainer}>
										<Grid className={Styles.MobileNumberAddon} color={textColor.darkBlack}>
											+91
										</Grid>
										<Grid className={Styles.InputNumberField}>
											<CustomNumberInput
												label='Enter Friend’s Mobile Number'
												maskFormat={'000-000-0000'}
												error={mobile_number ? true : false}
												{...formik.getFieldProps('mobile_number')}
											/>
										</Grid>
									</Grid>
									{mobile_number && (
										<Typography className={Styles.errorText} color={error.main}>
											<em>*Please enter valid mobile number</em>
										</Typography>
									)}
								</Grid>
							</CustomCard>
						</Grid>
					</Grid>
					<Grid container className={Styles.Footer} backgroundColor={background.secondary}>
						<CustomButton
							type='submit'
							buttonText='Save and Continue'
							disabled={isDemoEnabled === 'true' ? false : disableSubmitButton}
						/>
					</Grid>
				</FormControl>
			</Grid>
		</React.Fragment>
	);
}

export default HocWrapper(ReferenceContactFriends);
