import React from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

const CustomDropDown = ({ children, onChange, value, id, labelId, label, className, name, ...rest }) => {
	let numOfChilds = React.Children.count(children);

	return (
		<FormControl variant='standard' sx={{ minWidth: '100%' }} className={className}>
			<InputLabel id={labelId}>{label}</InputLabel>

			<Select
				MenuProps={{
					PaperProps: {
						sx: {
							overflow: 'auto',
						},
					},
				}}
				sx={rest.sx}
				name={name}
				labelId={labelId}
				id={id}
				value={value}
				onChange={onChange}
				label={label}>
				{numOfChilds && numOfChilds > 0 ? (
					children
				) : (
					<MenuItem key='f-1' disabled>
						No data available
					</MenuItem>
				)}
			</Select>
		</FormControl>
	);
};

export default CustomDropDown;

/** ----- Usage Info -----*/
/**
 * Import <MenuItem /> comp from MUI and use it as a child to <CustomDropDown />
 *  <CustomDropDown {...props}>
 *      <MenuItem value={someValue}>Select</MenuItem>
 *  </CustomDropDown>
 */
