import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../Utils/Api';

const prefillApi = `borrower_apk/income_info/?v=1000&latitude=12.960680890828371&longitude=77.63665560632944`;
const sendIncomeInfoApi = `borrower_apk/income_info/?v=1000&latitude=12.960680890828371&longitude=77.63665560632944`;
const getDropdownListForIncome = `borrower/getDropdownList/?type=sourceOfIncome`;
const getDropdownListForProfession = `borrower/getDropdownList/?type=profession`;

export const getSourceOfIncomeList = createAsyncThunk('IncomeInfo/getIncomeDropDownData', async () => {
	return Api('get', getDropdownListForIncome)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					if (response.data.result) {
						data['incomeList'] = response.data.result.length > 0 ? response.data.result : [];
					}
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return err.response.data.message;
			}
		});
});

export const getProfessionList = createAsyncThunk('IncomeInfo/getProfessionData', async () => {
	return Api('get', getDropdownListForProfession)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					if (response.data.result) {
						data['professionList'] = response.data.result.length > 0 ? response.data.result : [];
					}
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return err.response.data.message;
			}
		});
});

export const GetPrefillValues = createAsyncThunk('IncomeInfo/prefillData', async () => {
	return Api('get', prefillApi)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					if (response.data.result) {
						data['sourceOfIncome'] = response.data.result.source_of_income_code;
						data['profession'] = response.data.result.profession_code;
					}
					data['statusCode'] = response.data.status;
					data['apiResponse'] = response.data;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return err.response;
			}
		});
});

export const storeIncomeInfo = createAsyncThunk('IncomeInfo/StoreIncomeInfo', async apiBody => {
	return Api('post', sendIncomeInfoApi, apiBody)
		.then(response => {
			if (response && response.status && response.status === 200) {
				if (response.data) {
					let data = {};
					if (response.data.result) {
						data['userStatus'] = response.data.result.status;
					}
					data['statusCode'] = response.data.status;
					return data;
				}
			}
		})
		.catch(err => {
			if (err && err.response && err.response.data && err.response.data.message) {
				return err.response;
			}
		});
});

const initialState = {
	isLoading: false,
	prefillData: {
		sourceOfIncome: '',
		profession: '',
		apiResult: '',
		statusCode: '',
		error: '',
	},
	incomeInfoData: {
		statusCode: '',
		userStatus: '',
		error: '',
	},
	dropDownData: {
		incomeList: [],
		professionList: [],
	},
};
export const incomeInfoSlice = createSlice({
	name: 'IncomeInfo',
	initialState,
	reducers: {
		resetIncomeInfoState: state => {
			state.isLoading = false;
			state.prefillData = {
				sourceOfIncome: '',
				profession: '',
				apiResult: '',
				statusCode: '',
			};
			state.incomeInfoData = {
				statusCode: '',
				userStatus: '',
				error: '',
			};
			state.dropDownData = {
				incomeList: [],
				professionList: [],
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(GetPrefillValues.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(GetPrefillValues.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload?.status === 401) {
				state.prefillData.error = payload?.data?.status;
			} else {
				if (payload) {
					state.prefillData.apiResult = payload.apiResponse;
					state.prefillData.sourceOfIncome = payload.sourceOfIncome;
					state.prefillData.profession = payload.profession;
					state.prefillData.statusCode = payload.statusCode;
				}
			}
		});
		builder.addCase(GetPrefillValues.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.prefillData.message = payload && payload.apiMessage;
		});
		builder.addCase(storeIncomeInfo.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(storeIncomeInfo.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload?.status === 401) {
				state.incomeInfoData.error = payload?.data?.status;
			} else {
				if (payload) {
					state.incomeInfoData.userStatus = payload.userStatus;
					state.incomeInfoData.statusCode = payload.statusCode;
				}
			}
		});
		builder.addCase(storeIncomeInfo.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.incomeInfoData.message = payload && payload.apiMessage;
		});
		builder.addCase(getSourceOfIncomeList.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(getSourceOfIncomeList.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.dropDownData.incomeList = payload && payload.incomeList;
		});
		builder.addCase(getSourceOfIncomeList.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.dropDownData.message = payload && payload.apiMessage;
		});
		builder.addCase(getProfessionList.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(getProfessionList.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.dropDownData.professionList = payload && payload.professionList;
		});
		builder.addCase(getProfessionList.rejected, (state, { payload }) => {
			state.isLoading = false;
			state.dropDownData.message = payload && payload.apiMessage;
		});
	},
});

export const { resetIncomeInfoState } = incomeInfoSlice.actions;
export default incomeInfoSlice.reducer;
