import { useNavigate, useLocation } from 'react-router-dom';

const SubscribeNavigation = OriginalComponent => {
	const NewComponent = props => {
		const navigate = useNavigate();
		const location = useLocation();

		const Navigate = (path, replaceRoute, previousProps) => {
			navigate(path, {
				replace: replaceRoute ? replaceRoute : false,
				state: previousProps,
			});
		};

		const getPreviousProps = () => {
			return location.state;
		};

		const getPathName = () => {
			return location.pathname;
		};

		const goBack = () => {
			return navigate(-1);
		};

		const handleAndroidBackButton = target => {
			//Targtets:  goBack, closeApp, closeAndriodActivity.
			if (target === 'goBack') {
				return navigate(-1);
			}
			if (target === 'closeApp') {
				if (window.Android) {
					return window.Android.finishAffinity();
				}
			}
			if (target === 'closeAndriodActivity') {
				if (window.Android) {
					return window.Android.finish();
				}
			}
		};

		return (
			<OriginalComponent
				{...props}
				navigate={Navigate}
				get_previous_props={getPreviousProps}
				get_path_name={getPathName}
				handle_android_back_button={handleAndroidBackButton}
				go_back={goBack}
			/>
		);
	};
	return NewComponent;
};

export default SubscribeNavigation;
