import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React from 'react';
import HocWrapper from '../../Utils/Hoc/HocWrapper';
import CustomButton from '../../Components/HtmlElements/CustomButton';

// svg
import EligibilityTimer from '../../Assets/Icons/EligibilityTimerIcon.svg';

// Stylesheet
import Styles from './VKYCInprogress.module.scss';

let interval, timer;

function VKYCInprogress(props) {
	const { theme, handle_android_back_button } = props;
	const [showRefreshButton, setShowRefreshButton] = useState(false);

	const handleAndroidBackButton = () => handle_android_back_button('closeApp');

	const handleRefreshButton = () => {
		setShowRefreshButton(false);
		runTimer();
		if (window.Android) {
			window.Android.checkForVKYC();
		}
	};

	const runTimer = () => {
		let element = document.getElementById('timer');
		timer = 30;
		interval = setInterval(() => {
			if (timer <= 0) {
				clearInterval(interval);
				setShowRefreshButton(true);
			} else {
				timer = timer - 1;
				element.textContent = '';
			}
		}, 1000);
	};

	useEffect(() => {
		window.addEventListener('HandleNativeBackPress', handleAndroidBackButton);

		return () => {
			window.removeEventListener('HandleNativeBackPress', handleAndroidBackButton);
		};
	}, []);

	useEffect(() => {
		runTimer();
	}, []);

	return (
		<Grid
			className={Styles.MainContainerDiv}
			container
			direction='column'
			backgroundColor={theme.palette.background.secondary}>
			<Grid className={Styles.CardHeight}>
				<Grid>
					<Grid className={Styles.CardHeader}>
						<Grid>
							<img src={EligibilityTimer} alt='exclamation-icon' />
						</Grid>
						<Typography className={Styles.CardHeaderText}>VKYC check in progress</Typography>
					</Grid>
					<Divider sx={{ backgroundColor: '#F2F2F2' }} />
					<Grid className={Styles.CardBody}>
						<Typography className={Styles.CardBodyText} color={theme.palette.textColor.darkBlack}>
							We are verifying your details. This should not take more than 5 minute.
						</Typography>
					</Grid>
				</Grid>
				<Typography id='timer'></Typography>
				<Grid className={Styles.NavigationButton}>
					{showRefreshButton && <CustomButton clickHandler={handleRefreshButton} buttonText='Refresh' />}
				</Grid>
			</Grid>
		</Grid>
	);
}

export default HocWrapper(VKYCInprogress);
